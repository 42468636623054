import { css, cx } from 'emotion'
import * as React from 'react'

import { colors } from '../../styles'
import { noop } from '../../utils'
import ActionButton, { ActionButtonProps } from '../ActionButton'
import ModalHeader from './ModalHeader'

export enum ModalSize {
  Short = 'short',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Long = 'long',
  Thin = 'thin',
  Wide = 'wide',
}

type ModalActionButtonProps = Omit<ActionButtonProps, 'action'> & {
  action?: (closeModal: () => void) => void
}

export type ModalProps = {
  children: React.ReactNode
  className?: string
  classNameHeaderContainer?: string
  classNameBodyContainer?: string
  closeModal?: () => void
  primaryButtonProps?: ModalActionButtonProps
  renderFooterLeft?: () => JSX.Element
  renderFooterRight?: () => JSX.Element
  size?: ModalSize
  secondaryButtonProps?: ModalActionButtonProps
  tertiaryButtonProps?: ModalActionButtonProps
  title: string
  tw?: boolean
  twBodyContainer?: string
}

const getDimensionsForSize = (size: ModalSize) => {
  switch (size) {
    case ModalSize.Short:
      return {
        height: '100%',
        width: 480,
      }
    case ModalSize.Small:
      return {
        height: 275,
        width: 480,
      }
    case ModalSize.Large:
      return {
        height: 442,
        width: 580,
      }
    case ModalSize.Long:
      return {
        height: '100%',
        minHeight: 342,
        width: 580,
      }
    case ModalSize.Thin:
      return {
        height: '100%',
        width: 340,
      }
    case ModalSize.Wide:
      return {
        height: '100%',
        width: 680,
      }
    case ModalSize.Medium:
    default:
      return {
        height: 342,
        width: 480,
      }
  }
}

const getDimensionsForSizeTw = (size: ModalSize) => {
  switch (size) {
    case ModalSize.Short:
      return 'h-full w-[480px]'
    case ModalSize.Small:
      return 'h-[275px] w-[480px]'
    case ModalSize.Large:
      return 'h-[442px] w-[580px]'
    case ModalSize.Long:
      return 'h-full min-h-[342px] w-[580px]'
    case ModalSize.Thin:
      return 'h-full w-[340px]'
    case ModalSize.Wide:
      return 'h-full w-[680px]'
    case ModalSize.Medium:
    default:
      return 'h-[342px] w-[480px]'
  }
}

const twstyles = {
  leftButtonContainer: 'mt-0 mr-0 mb-5 ml-5',
  modalBody: 'flex flex-col grow-1 p-5 relative',
  modalContent: (size: ModalSize) =>
    `bg-white rounded flex flex-col relative ${getDimensionsForSizeTw(size)}`,
  modalFooter: 'flex items-center flex shrink-0 justify-between',
  rightButtonContainer: 'flex mt-0 mr-5 mb-5 ml-0 [&_>_button:first-of-type]:mr-[10px]',
}

const styles = {
  modalBody: css({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 20,
    position: 'relative',
  }),
  modalContent: (size: ModalSize) =>
    css({
      background: colors.white,
      borderRadius: 4,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      ...getDimensionsForSize(size),
    }),
}

const createModalAction = (closeModal: () => void, props: ModalActionButtonProps) =>
  props.action ? () => props.action!(closeModal) : noop

class Modal extends React.Component<ModalProps> {
  closeOnEscape = (event: KeyboardEvent) => {
    const { closeModal } = this.props
    if (event.key === 'Escape' && typeof closeModal === 'function') {
      closeModal()
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.closeOnEscape, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.closeOnEscape, false)
  }

  render() {
    const {
      children,
      className,
      classNameHeaderContainer,
      classNameBodyContainer,
      closeModal,
      renderFooterLeft,
      renderFooterRight,
      size = ModalSize.Medium,
      title,
      primaryButtonProps,
      secondaryButtonProps,
      tertiaryButtonProps,
      twBodyContainer,
      tw = false,
    } = this.props

    return (
      <div
        className={
          tw
            ? `${twstyles.modalContent(size)} ${className}`
            : css(styles.modalContent(size), className)
        }
      >
        <ModalHeader {...{ closeModal, title, classNameHeaderContainer }} />
        <div className={cx(styles.modalBody, classNameBodyContainer, twBodyContainer)}>
          {children}
        </div>
        <div className={twstyles.modalFooter}>
          <div className={twstyles.leftButtonContainer}>
            {tertiaryButtonProps && (
              <ActionButton
                tertiary
                {...tertiaryButtonProps}
                action={createModalAction(closeModal ?? noop, tertiaryButtonProps)}
              />
            )}
            {renderFooterLeft?.()}
          </div>
          <div className={twstyles.rightButtonContainer}>
            {secondaryButtonProps && (
              <ActionButton
                secondary
                {...secondaryButtonProps}
                action={createModalAction(closeModal ?? noop, secondaryButtonProps)}
                tw={tw ? true : false}
              />
            )}
            {primaryButtonProps && (
              <ActionButton
                primary
                {...primaryButtonProps}
                action={createModalAction(closeModal ?? noop, primaryButtonProps)}
                tw={tw ? true : false}
              />
            )}
            {renderFooterRight?.()}
          </div>
        </div>
      </div>
    )
  }
}

export default Modal
