import { takeLatest } from 'redux-saga/effects'

import sessionActions from '../../session/actions'
import { SetAction } from '../../session/types'
import getNameFromPayload from '../utils/getNameFromPayload'

type IntercomApi = (verb: string, opts: Record<string, unknown>) => void

declare global {
  interface Window {
    Intercom?: IntercomApi
  }
}

export function addIntercom({ meta, payload }: SetAction) {
  const intercom = window?.Intercom

  if (intercom) {
    intercom('update', {
      company: {
        emailDomain: payload!.username.split('@').pop(),
        id: meta!.groupId,
      },
      created_at: meta!.createdAt,
      email: payload!.username,
      entity_id: meta!.entityId,
      entity_type: payload!.entity,
      logrocketURL: `https://app.logrocket.com/${process.env.LOGROCKET_ID}/sessions?u=${
        meta!.uuid
      }`,
      name: getNameFromPayload(payload!),
      user_hash: meta!.userHash,
      user_id: meta!.uuid,
    })
  }
}

function* watchAddIntercom() {
  yield takeLatest(sessionActions.set().type, addIntercom)
}

export default watchAddIntercom
