import { format, getYear, isValid, parse } from 'date-fns'
import Downshift, { DownshiftInterface, DownshiftProps } from 'downshift'
import { css } from 'emotion'
import { F } from 'ramda'
import React, { forwardRef, useEffect, useState } from 'react'
import { animated, useTransition } from 'react-spring'

import { usePortal } from '../hooks'
import { colors, textStyles, zIndex } from '../styles'
import ConditionalWrapper from './ConditionalWrapper'
import DatePickerCalendar from './DatePickerCalendar'
import { InputHandle } from './Input'
import TextInput from './TextInput'

type DatePickerProps<T = Date | null> = {
  accent?: 'blue' | 'green'
  date: T
  dateFormat?: string
  disabled?: boolean
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
  isDayDisabled?: (value: T) => boolean
  onChange: (value: T) => void
  portal?: { id: string; x: number; y: number }
} & Omit<DownshiftProps<T>, 'onChange'>

const styles = {
  container: css({
    position: 'relative',
  }),
  day: css(textStyles.charcoalGrayNormal12, {
    '&:disabled': {
      opacity: 0.5,
    },
    '&:hover:enabled': {
      backgroundColor: colors.charcoalGray2alpha10,
    },
    borderRadius: 4,
    height: 28,
    margin: 2,
    position: 'relative',
    width: 28,
  }),
  dayCurrent: css({
    '&:hover:enabled': {
      backgroundColor: colors.azure,
    },
    backgroundColor: colors.azure,
    border: 'none',
    color: colors.white,
    fontWeight: 'bold',
  }),
  dayNotInMonth: css({
    visibility: 'hidden',
  }),
  dayToday: css({
    border: `1px solid ${colors.greenyBlue}`,
  }),
  dropdown: css({
    backgroundColor: colors.white,
    flex: 1,
    position: 'absolute',
    zIndex: zIndex.select,
  }),
  leftRightButton: css({
    '&:disabled': {
      opacity: 0.5,
    },
    '&:hover:enabled': {
      border: `1px solid ${colors.silver2}`,
      boxShadow: `0 2px 8px 0 ${colors.charcoalGray2alpha10}`,
    },
    backgroundColor: colors.white,
    borderRadius: 4,
    fill: colors.charcoalGray,
    height: 28,
    width: 28,
  }),
  title: css({
    alignItems: 'center',
    display: 'flex',
    fontWeight: 'bold',
    justifyContent: 'space-between',
  }),
  weekday: css(textStyles.charcoalGrayBold12, {
    alignItems: 'center',
    display: 'flex',
    height: 28,
    justifyContent: 'center',
    margin: 2,
    width: 28,
  }),
  weeks: css({
    display: 'flex',
    fontSize: '80%',
  }),
}

const TypedDownshift: DownshiftInterface<Date | null> = Downshift

const DatePicker = forwardRef<InputHandle, DatePickerProps>(
  (
    {
      accent,
      date,
      dateFormat = 'MM/dd/yyyy',
      disabled = false,
      isDayDisabled = F,
      inputProps = {},
      portal,
      onChange,
      ...props
    },
    ref
  ) => {
    const [baseDate, setBaseDate] = useState(date ?? new Date())
    const [downshiftOpen, setDownshiftOpen] = useState(false)
    const show = useTransition(downshiftOpen, {
      enter: { opacity: 1 },
      from: { opacity: 0 },
      initial: { background: 'white' },
      leave: { opacity: 0 },
    })

    const target = usePortal(portal?.id ?? 'no-datepicker-portal')

    useEffect(() => {
      if (date !== null) {
        setBaseDate(date)
      }
    }, [date])

    return (
      <TypedDownshift
        initialSelectedItem={date}
        itemToString={(item) => (item ? format(item, dateFormat) : '')}
        onChange={onChange}
        stateReducer={(_, changes) => {
          if (changes.isOpen !== undefined) {
            setDownshiftOpen(changes.isOpen)
          }

          return changes
        }}
        {...props}
      >
        {({
          closeMenu,
          getInputProps,
          getMenuProps,
          isOpen,
          openMenu,
          selectItem,
          selectedItem,
        }) => (
          <div className={styles.container}>
            <TextInput
              {...getInputProps({
                accent,
                disabled,
                placeholder: 'mm/dd/yyyy',
                ref,
                ...inputProps,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                  const parsed = parse(event.target.value, 'MM/dd/yyyy', baseDate)
                  const isValidYear = (value: Date) => /^\d{4}$/.test(getYear(value).toString())

                  if (inputProps.onChange) {
                    inputProps.onChange(event)
                  }

                  if (isValid(parsed) && isValidYear(parsed) && !isDayDisabled(parsed)) {
                    selectItem(parsed)
                    setBaseDate(parsed)
                  }

                  if (event.target.value === '') {
                    selectItem(null)
                    setBaseDate(new Date())
                  }
                },
                onClick: () => openMenu(),
                onFocus: () => openMenu(),
              })}
            />
            {isOpen
              ? show(
                  (style, item, { key }) =>
                    item && (
                      <ConditionalWrapper condition={Boolean(portal)} portal={target}>
                        <animated.div
                          className={styles.dropdown}
                          key={key}
                          style={{ ...style, ...(portal ? { x: portal.x, y: portal.y } : {}) }}
                          {...getMenuProps()}
                        >
                          <DatePickerCalendar
                            {...{ dateFormat, isDayDisabled }}
                            date={selectedItem}
                            isOpen
                            onChange={(value) => {
                              selectItem(value)
                              closeMenu()
                            }}
                          />
                        </animated.div>
                      </ConditionalWrapper>
                    )
                )
              : null}
          </div>
        )}
      </TypedDownshift>
    )
  }
)

export default DatePicker
