import { Key } from '@juristat/common/types'
import { css } from 'emotion'
import { difference, isEmpty } from 'ramda'
import React from 'react'

import TagInput from '../../../components/TagInput'
import { BoundAction } from '../../../redux'
import noop from '../../../utils/noop'
import { RemoveListAction, SelectListAction, TagFilter } from '../types'

export type TagFilterInputProps = {
  addKeys?: Key[]
  dark?: boolean
  filter: TagFilter
  handleOnTagsChange?: (newTags: string[], oldTags: string[]) => void
  handleParseError?: (data: string) => void
  parseInput?: (data: string) => string
  pasteSplit?: (data: string) => string[]
  placeholder?: string
}

type TagFilterConnectedProps = TagFilterInputProps & {
  local?: string[]
  removeList: BoundAction<RemoveListAction>
  selectList: BoundAction<SelectListAction>
  selected: string[]
}

type HandleOnChangeProps = Pick<TagFilterConnectedProps, 'filter' | 'removeList' | 'selectList'>

const styles = {
  input: css({
    marginBottom: 6,
  }),
  tag: css({
    marginBottom: 4,
    marginRight: 4,
  }),
  tagsContainer: css({
    '& > div': {
      display: 'flex',
      flexWrap: 'wrap',
      maxHeight: 200,
    },
  }),
}

export const makeHandleOnTagsChange =
  ({ filter, removeList, selectList }: HandleOnChangeProps) =>
  (newTags: string[], oldTags: string[]) => {
    const valuesToRemove = difference(oldTags, newTags)
    const valuesToAdd = difference(newTags, oldTags)
    if (!isEmpty(valuesToAdd)) {
      selectList({ filter, value: valuesToAdd })
    }
    if (!isEmpty(valuesToRemove)) {
      removeList({ filter, value: valuesToRemove })
    }
  }

const defaultPasteSplit = (data: string) => {
  return data.split(/[\s,]+/).map((d: string) => d.trim())
}

export const TagFilterInput = ({
  addKeys = [Key.Comma, Key.Enter, Key.Space, Key.Tab],
  filter,
  dark = true,
  handleOnTagsChange,
  handleParseError = noop,
  local = [],
  parseInput,
  pasteSplit = defaultPasteSplit,
  placeholder,
  removeList,
  selectList,
  selected,
}: TagFilterConnectedProps) => (
  <TagInput
    addKeys={addKeys}
    dark={dark}
    classNameTagContainer={styles.tagsContainer}
    classNameInput={styles.input}
    classNameTag={styles.tag}
    handleParseError={handleParseError}
    onTagsChange={
      handleOnTagsChange
        ? handleOnTagsChange
        : makeHandleOnTagsChange({ filter, removeList, selectList })
    }
    parseInput={parseInput}
    pasteSplit={pasteSplit}
    placeholder={placeholder}
    tags={[...selected, ...local]}
  />
)

export default TagFilterInput
