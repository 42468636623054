import { css } from 'emotion'
import { contains } from 'ramda'
import React, { useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'

import ButtonGroup from '../../../components/ButtonGroup'
import actions from '../actions'
import FilterContext from '../context/filterContext'
import { CurrentOrDispositionState, Filter, PossibleFilterItem, TypeaheadFilter } from '../types'
import AvailableFilterItems from './AvailableFilterItems'
import FilterContainer, { FilterContainerProps } from './FilterContainer'
import TypeaheadFilterComponent from './TypeaheadFilter'

type TypeaheadFilterContainerProps = Omit<FilterContainerProps, 'render' | 'filter'> & {
  enabled?: boolean
  filter: TypeaheadFilter
  getLookup?: (item: WeakObject) => any
}

const buttonStyles = {
  classNameButton: css({
    width: '50%',
  }),
  classNameContainer: css({
    margin: '20px 0',
  }),
}

const defaultGetLookup = ({ id, name }: WeakObject) => id || name

const firmsAssignees = [
  Filter.AssigneeAtDisposition,
  Filter.CurrentAssignee,
  Filter.CurrentFirm,
  Filter.FirmAtDisposition,
]

const getTimingOption = (filter: Filter) => {
  switch (filter) {
    case Filter.AssigneeAtDisposition:
    case Filter.CurrentAssignee:
      return {
        current: Filter.CurrentAssignee,
        disposition: Filter.AssigneeAtDisposition,
      }
    case Filter.AssigneeAtDispositionName:
    case Filter.CurrentAssigneeName:
      return {
        current: Filter.CurrentAssigneeName,
        disposition: Filter.AssigneeAtDispositionName,
      }
    case Filter.AttorneyAtDisposition:
    case Filter.CurrentAttorney:
      return {
        current: Filter.CurrentAttorney,
        disposition: Filter.AttorneyAtDisposition,
      }
    case Filter.FirmAtDisposition:
    case Filter.CurrentFirm:
      return {
        current: Filter.CurrentFirm,
        disposition: Filter.FirmAtDisposition,
      }
    case Filter.FirmAtDispositionName:
    case Filter.CurrentFirmName:
      return {
        current: Filter.CurrentFirmName,
        disposition: Filter.FirmAtDispositionName,
      }
    default:
      return { current: null, disposition: null }
  }
}

const TypeaheadFilterContainer = ({
  enabled,
  getLookup = defaultGetLookup,
  ...props
}: TypeaheadFilterContainerProps) => {
  const dispatch = useDispatch()
  const { meta } = useContext(FilterContext)
  const { current, disposition } = getTimingOption(props.filter)
  const updateSwitch = useCallback(
    (payload: Filter | null) => {
      dispatch(actions.switch(payload as CurrentOrDispositionState, meta))
    },
    [dispatch, meta]
  )

  return (
    <FilterContainer
      {...props}
      render={({ active, available = [], selected }) => (
        <div>
          <TypeaheadFilterComponent enabled={enabled} {...props} />
          {contains(props.filter, firmsAssignees) && (
            <ButtonGroup
              {...buttonStyles}
              buttons={[
                { label: 'At Disp.', value: disposition as Filter },
                { label: 'Current', value: current as Filter },
              ]}
              update={updateSwitch}
              value={props.filter}
            />
          )}
          <AvailableFilterItems
            active={active}
            available={available as PossibleFilterItem[]}
            filter={props.filter}
            getLookup={getLookup}
            selected={selected}
          />
        </div>
      )}
    />
  )
}

export { buttonStyles, defaultGetLookup, getTimingOption }
export default TypeaheadFilterContainer
