import { css } from 'emotion'

import { colors } from '.'

const common = css({
  '&::-webkit-scrollbar': {
    height: 10,
    width: 10,
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '&:focus, &:hover': {
    visibility: 'visible',
  },
})

const dark = css(common, {
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: colors.cloudyBlueAlpha20,
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: colors.cloudyBlueAlpha50,
  },
})

const light = css(common, {
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: colors.cloudyBlueLight,
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: colors.cloudyBlue,
  },
})

export { dark, light }
