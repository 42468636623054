import { DataSource, LocalStorageKey } from '@juristat/common/types'
import { parse } from 'qs'
import { createSelector } from 'reselect'

import { AppState } from '../../redux'
import { getLocalStorage } from '../../redux/selectors'
import { getQueryString } from '../router/selectors'
import { getSearchDataSource } from '../search/selectors'
import {
  Access,
  FeatureModalState,
  PermissionsState,
  PpairUser,
  SessionState,
  Settings,
} from './types'

const makeHasPermission = (permission: keyof PermissionsState) => (state: SessionState) =>
  state.permissions[permission]

const canAccessAccountTools = makeHasPermission('accountTools')
const canAccessAlpha = makeHasPermission('alpha')
const canAccessAnalystMetrics = makeHasPermission('analystMetrics')
const canAccessApi = makeHasPermission('api')
const canAccessAssignmentSheet = makeHasPermission('assignmentSheet')
const canAccessBi = makeHasPermission('bi')
const canAccessCharts = makeHasPermission('charts')
const canAccessClientConfig = makeHasPermission('clientConfig')
const canAccessClientUsageReport = makeHasPermission('clientUsageReport')
const canAccessConfidentialData = makeHasPermission('confidentialData')
const canAccessCustomerPpairSettings = makeHasPermission('customerPpairSettings')
const canAccessDrafting = makeHasPermission('drafting')
const canAccessExaminer = (session: SessionState) =>
  session.prices.examiner === 0 || session.permissions.examiner
const canAccessExpertSearch = makeHasPermission('expertSearch')
const canAccessExpertSearchExport = makeHasPermission('expertSearchExport')
const canAccessExportAnalytics = makeHasPermission('exportAnalytics')
const canAccessFreeHealthDashboard = makeHasPermission('freeHealthDashboard')
const canAccessGroupAdmin = makeHasPermission('groupAdmin')
const canAccessGroupOwner = makeHasPermission('groupOwner')
const canAccessHumanTasks = makeHasPermission('humanTasks')
const canAccessEditOarTime = makeHasPermission('editOarTimes')
const canAccessIds = makeHasPermission('ids')
const canAccessIdsAssignmentSheet = makeHasPermission('idsAssignmentSheet')
const canAccessIdsViewer = makeHasPermission('idsViewer')
const canAccessOarBuild = makeHasPermission('oarBuild')
const canAccessOarBuildQuality = makeHasPermission('oarBuildQuality')
const canAccessOarReview = makeHasPermission('oarReview')
const canAccessOarReviewQuality = makeHasPermission('oarReviewQuality')
const canAccessOarSender = makeHasPermission('oarSender')
const canAccessOarTech = makeHasPermission('oarTech')
const canAccessOarViewer = makeHasPermission('oarViewer')
const canAccessOneOTwoReports = makeHasPermission('oneOTwoReports')
const canAccessPremiumWork = makeHasPermission('premiumWork')
const canAccessRejections = makeHasPermission('rejections')
const canAccessStaffSettings = makeHasPermission('staffSettings')
const canAccessTable = makeHasPermission('table')
const canAccessPlatformExaminerAuTcFilters = makeHasPermission('platformExaminerAuTcFilters')
const canAccessPlatformFirmAssigneeFilters = makeHasPermission('platformFirmAssigneeFilters')
const canAccessPlatformOaRceCountFilters = makeHasPermission('platformOaRceCountFilters')
const canAccessPlatformPatentFamilyComponent = makeHasPermission('platformPatentFamilyComponent')
const canAccessPlatformPremiumSorts = makeHasPermission('platformPremiumSorts')
const canAccessPlatformRegCustNumFilters = makeHasPermission('platformRegCustNumFilters')
const canAccessPlatformSavedSearchAndHistory = makeHasPermission('platformSavedSearchAndHistory')
const canAccessPlatformSearchScopes = makeHasPermission('platformSearchScopes')
const canAccessPlatformThreePaneView = makeHasPermission('platformThreePaneView')
const canAccessPersonal = makeHasPermission('personal')
const canAccessPpairUi = makeHasPermission('ppair')
const canAccessRejectionBasisFilter = makeHasPermission('rejectionBasisFilter')
const canAccessSelfAnalystMetrics = makeHasPermission('selfAnalystMetrics')
const canAccessSmartshell = makeHasPermission('smartshell')
const canAccessBiDraftingExaminer = (session: SessionState) =>
  canAccessBi(session) || canAccessDrafting(session)

const canAccessPlatform = (session: SessionState) =>
  canAccessCharts(session) &&
  canAccessPlatformExaminerAuTcFilters(session) &&
  canAccessPlatformFirmAssigneeFilters(session) &&
  canAccessPlatformOaRceCountFilters(session) &&
  canAccessPlatformPatentFamilyComponent(session) &&
  canAccessPlatformPremiumSorts(session) &&
  canAccessPlatformRegCustNumFilters(session) &&
  canAccessPlatformSavedSearchAndHistory(session) &&
  canAccessPlatformSearchScopes(session) &&
  canAccessPlatformThreePaneView(session) &&
  canAccessRejectionBasisFilter(session) &&
  canAccessRejections(session) &&
  canAccessTable(session)

const canAccessWorkflowAutomation = (session: SessionState) =>
  canAccessAssignmentSheet(session) ||
  canAccessIdsAssignmentSheet(session) ||
  canAccessIds(session) ||
  canAccessOarViewer(session)

const accessSelectorDictionary: { [key: string]: (session: SessionState) => boolean } = {
  [Access.Api]: canAccessApi,
  [Access.AccountTools]: canAccessAccountTools,
  [Access.Alpha]: canAccessAlpha,
  [Access.AnalystMetrics]: canAccessAnalystMetrics,
  [Access.ArtUnit]: canAccessBiDraftingExaminer,
  [Access.AssignmentSheet]: canAccessAssignmentSheet,
  [Access.BusinessIntelligence]: canAccessBi,
  [Access.Charts]: canAccessCharts,
  [Access.ClientConfig]: canAccessClientConfig,
  [Access.ClientUsageReport]: canAccessClientUsageReport,
  [Access.ConfidentialData]: canAccessConfidentialData,
  [Access.CustomerPpairNumberSettings]: canAccessCustomerPpairSettings,
  [Access.Drafting]: canAccessDrafting,
  [Access.Examiner]: canAccessExaminer,
  [Access.ExpertSearch]: canAccessExpertSearch,
  [Access.ExpertSearchExport]: canAccessExpertSearchExport,
  [Access.ExportAnalytics]: canAccessExportAnalytics,
  [Access.FreeHealthDashboard]: canAccessFreeHealthDashboard,
  [Access.GroupAdmin]: canAccessGroupAdmin,
  [Access.GroupOwner]: canAccessGroupOwner,
  [Access.HasSession]: (session: SessionState) => Boolean(session.username),
  [Access.HumanTasks]: canAccessHumanTasks,
  [Access.Ids]: canAccessIds,
  [Access.IdsAssignmentSheet]: canAccessIdsAssignmentSheet,
  [Access.IdsViewer]: canAccessIdsViewer,
  [Access.OarBuild]: canAccessOarBuild,
  [Access.OarBuildQuality]: canAccessOarBuildQuality,
  [Access.OarEditTimes]: canAccessEditOarTime,
  [Access.OarReview]: canAccessOarReview,
  [Access.OarReviewQuality]: canAccessOarReviewQuality,
  [Access.OarSender]: canAccessOarSender,
  [Access.OarTech]: canAccessOarTech,
  [Access.OarViewer]: canAccessOarViewer,
  [Access.OneOTwoReports]: canAccessOneOTwoReports,
  [Access.Personal]: canAccessPersonal,
  [Access.Platform]: canAccessPlatform,
  [Access.PlatformExaminerAuTcFilters]: canAccessPlatformExaminerAuTcFilters,
  [Access.PlatformFirmAssigneeFilters]: canAccessPlatformFirmAssigneeFilters,
  [Access.PlatformOaRceCountFilters]: canAccessPlatformOaRceCountFilters,
  [Access.PlatformPatentFamilyComponent]: canAccessPlatformPatentFamilyComponent,
  [Access.PlatformPremiumSorts]: canAccessPlatformPremiumSorts,
  [Access.PlatformRegCustNumFilters]: canAccessPlatformRegCustNumFilters,
  [Access.PlatformSavedSearchAndHistory]: canAccessPlatformSavedSearchAndHistory,
  [Access.PlatformSearchScopes]: canAccessPlatformSearchScopes,
  [Access.PlatformThreePaneView]: canAccessPlatformThreePaneView,
  [Access.PremiumWork]: canAccessPremiumWork,
  [Access.PrivatePair]: canAccessPpairUi,
  [Access.RejectionBasisFilter]: canAccessRejectionBasisFilter,
  [Access.Rejections]: canAccessRejections,
  [Access.SelfAnalystMetrics]: canAccessSelfAnalystMetrics,
  [Access.Smartshell]: canAccessSmartshell,
  [Access.StaffSettings]: canAccessStaffSettings,
  [Access.Table]: canAccessTable,
  [Access.UspcClass]: canAccessBiDraftingExaminer,
  [Access.WorkflowAutomation]: canAccessWorkflowAutomation,
}

export const makeGetAccess = (canAccess: Access) =>
  createSelector(getSession, (session) => accessSelectorDictionary[canAccess]?.(session) ?? false)

export const makeGetAccesses = (canAccesses: Access[]) =>
  createSelector(getSession, (session) =>
    canAccesses.map((canAccess) => accessSelectorDictionary[canAccess]?.(session) ?? false)
  )

export const getSession = (state: AppState): SessionState => state.session

export const getName = createSelector(getSession, (state) => state.name)

const getFullName = createSelector(getName, ({ first, last }) =>
  first && last ? `${first} ${last}` : ''
)

export const getDisplayName = createSelector(
  getSession,
  getFullName,
  (state: SessionState, fullName: string): string => fullName ?? state.username ?? 'Juristat User'
)

export const getFeatureModal = createSelector(
  getSession,
  (state: SessionState): FeatureModalState => state.featureModal
)

export const getGroup = createSelector(getSession, (state) => state.group)

export const getGroupId = createSelector(getGroup, (state) => state.id)

export const getPpairUser = createSelector(
  getSession,
  (state: SessionState): PpairUser => state.ppairUser
)

export const getSettings = createSelector(
  getSession,
  (state: SessionState): Settings => state.settings
)

export const hasSession = createSelector(
  getSession,
  (state: SessionState): boolean => state.username !== ''
)

export const getUsername = createSelector(getSession, (state: SessionState) => state.username)

export const getUuid = createSelector(getSession, (state: SessionState): string => state.uuid)

const getUsernameFromLocalStorage = createSelector(getLocalStorage, (localStorage) =>
  localStorage ? localStorage.getItem(LocalStorageKey.Username) : null
)

const getUsernameFromQuery = createSelector(getQueryString, (query) => {
  const { username } = parse(query, { ignoreQueryPrefix: true })
  return username ? `${username}` : undefined
})

export const getUsernameFromStateUrlOrLocalStorage = createSelector(
  getUsernameFromQuery,
  getUsername,
  getUsernameFromLocalStorage,
  (queryUsername, stateUsername, localStorageUsername) =>
    queryUsername ?? stateUsername ?? localStorageUsername
)

export const getCanQueryPpair = createSelector(
  getPpairUser,
  makeGetAccess(Access.PrivatePair),
  (ppairUser: PpairUser = PpairUser.False, hasAccess = false) =>
    ppairUser === PpairUser.True && hasAccess
)

export const getCanAccessAttorneys = createSelector(
  getCanQueryPpair,
  getSearchDataSource,
  (canQueryPpair, dataSource) => canQueryPpair && dataSource === DataSource.PrivatePair
)

export const getPermissions = createSelector(
  getSession,
  (state: SessionState): PermissionsState => state.permissions
)
