import { css } from 'emotion'
import React, { useEffect } from 'react'

import ActionButton from '../../../components/ActionButton'
import { useQueryStringParam } from '../../../hooks/useQueryStringParam'
import { colors, textStyles } from '../../../styles'
import { useDownload } from '../../api'
import { JuristatLogo, WarningStandard } from '../../icons'

const styles = {
  button: css({
    backgroundColor: colors.linkBlue,
    fontSize: 24,
    padding: '30px 90px',
  }),
  container: css({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  }),
  logo: css({
    marginBottom: 45,
    width: 657,
  }),
  mainText: css(textStyles.darkBold61, {
    marginBottom: 84,
  }),
  subText: css(textStyles.charcoalGray2Alpha50Bold24, {
    marginBottom: 109,
  }),
}

const validToDownload = (url: string) => /^https:\/\/(.*)juristat\.com(:\d+)?(\/.*)?$/i.test(url)

const DownloadFile = () => {
  const [download] = useDownload()
  const [file, filename] = useQueryStringParam(['file', 'filename'])

  useEffect(() => {
    if (validToDownload(file)) {
      download(file, filename)
    }
  }, [download, file, filename])

  if (!validToDownload(file)) {
    return (
      <div className={styles.container}>
        <div>
          <WarningStandard height={96} />
        </div>
        <div className={styles.mainText}>Download URL is missing or invalid</div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <JuristatLogo className={styles.logo} />
      <div className={styles.mainText}>Your download will begin shortly...</div>
      <div className={styles.subText}>or click below to download</div>
      <ActionButton
        action={() => download(file, filename)}
        className={styles.button}
        primary
        text="DOWNLOAD"
      />
    </div>
  )
}

export default DownloadFile
