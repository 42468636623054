import { css, cx } from 'emotion'
import { useDrag } from 'muuri-react'
import { without } from 'ramda'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import ConditionalWrapper from '../../../components/ConditionalWrapper'
import DataContainer from '../../../components/DataContainer'
import InformationPopup from '../../../components/InformationPopup'
import { useNoop, useNoopContext } from '../../../hooks/useNoop'
import { colors } from '../../../styles'
import ChartSettingsMenu from '../../charts/components/ChartSettingsMenu'
import AddToDashboardButton from '../../dashboards/components/AddToDashboardButton'
import { useDashboardItems, useIsChartsLibrary } from '../../dashboards/hooks'
import {
  DraggableGridItem,
  GridItem,
  GridItemType,
  StaticGridItem,
  useGridItems,
} from '../../grid-items'
import { DragHandle } from '../../icons'
import { useIsExaminerReport } from '../hooks'
import { ExaminerMetricGridItemProps } from './ExaminerMetricGridItem'

type ExaminerDataContainerProps = ExaminerMetricGridItemProps & {
  children: React.ReactNode
  className?: string
  informationText?: React.ReactNode
  title: string
}

const styles = {
  actions: css({
    '&:hover': {
      opacity: 0.6,
    },
    alignItems: 'center',
    display: 'flex',
    fontSize: 16,
    height: 32,
    justifyContent: 'center',
    opacity: 0.3,
    transition: 'opacity 200ms ease-in-out',
    width: 32,
  }),
  container: css({
    backgroundColor: colors.white,
  }),
  draggable: css({
    '&:hover': {
      '& h2 + svg': {
        fill: colors.charcoalGray6,
        opacity: 1,
      },
      border: '1px solid transparent',
      boxShadow: '0 1px 3px rgba(60, 64, 67, 0.3), 0 4px 8px 3px rgba(60, 64, 67, 0.15)',
    },
    transition: 'border-color 300ms ease-in-out, box-shadow 300ms ease-in-out',
  }),
  dragging: css({
    opacity: 1,
  }),
  handle: css({
    cursor: 'grab',
    fill: colors.charcoalGray2,
    opacity: 0.3,
    right: 32,
    transition: 'fill 300ms ease-in-out, opacity 300ms ease-in-out',
  }),
  header: css({
    display: 'flex',
    position: 'absolute',
    right: 30,
  }),
  help: css({
    padding: 20,
  }),
  info: css({
    marginRight: -8,
    marginTop: -12,
  }),
}

function useNoopContexts<T, U>() {
  return [useNoopContext<T>(), useNoopContext<U>()] as const
}

function useNoopDrag<T>(): T {
  const noop = useNoop<T>()

  useNoopContext()
  useState()
  useRef()
  useEffect(() => {
    noop
  }, [1, 2])

  return noop
}

const ExaminerDataContainer: React.FC<ExaminerDataContainerProps> = ({
  chart,
  children,
  className,
  exportableConfig,
  id,
  gridItem,
  informationText,
  machine,
  metric,
  style,
  ...props
}) => {
  const domRef = useRef<HTMLDivElement | null>(null)

  const { dashboard } = useParams<{ dashboard?: string }>()
  const useMaybeDashboardItems = dashboard ? useDashboardItems : useNoopContexts
  const maybeDashboardItems = useMaybeDashboardItems()

  const useMaybeDrag = dashboard ? useDrag : useNoopDrag
  const isDragging = useMaybeDrag()

  const isExaminerReport = useIsExaminerReport()
  const isChartsLibrary = useIsChartsLibrary()
  const height = style?.height ?? (isChartsLibrary ? 550 : 350)

  const draggable = props.draggable ?? (Boolean(dashboard) && !isChartsLibrary)
  const gridItemProps = {
    data: { metric, type: GridItemType.ExaminerMetric },
    itemKey: metric,
    style: {
      columns: 1 / 2,
      height,
      ...style,
    },
  }
  const useMaybeGridItems = dashboard ?? !draggable ? useNoop : useGridItems
  const maybeGridItems = useMaybeGridItems()

  const headerContent = useMemo(() => {
    if (isChartsLibrary) {
      return <AddToDashboardButton item={{ metric, type: GridItemType.ExaminerMetric }} />
    }

    if (isExaminerReport) {
      return (
        informationText && (
          <InformationPopup className={styles.info}>{informationText}</InformationPopup>
        )
      )
    }

    return (
      <div className={styles.header}>
        {exportableConfig && chart && machine && (
          <ChartSettingsMenu
            {...exportableConfig}
            className={draggable ? undefined : css({ right: 32 })}
            handleRemove={() => {
              const removeItem = without<GridItem>([
                gridItem ?? { chart, id: id ?? undefined, type: GridItemType.Chart },
              ])

              if (dashboard) {
                const [dashboardItems, updateDashboardItems] = maybeDashboardItems

                return updateDashboardItems(removeItem(dashboardItems), { autoSave: false })
              }

              const [gridItems, , , updateGridItems] = maybeGridItems

              updateGridItems(removeItem(gridItems))
            }}
            dom={domRef}
            machine={machine}
          />
        )}
        {draggable && (
          <DragHandle
            className={cx(styles.handle, 'handle')}
            height={32}
            width={32}
            title="Drag to reorder chart"
          />
        )}
      </div>
    )
  }, [
    chart,
    dashboard,
    draggable,
    exportableConfig,
    gridItem,
    id,
    informationText,
    isChartsLibrary,
    isExaminerReport,
    machine,
    maybeDashboardItems,
    maybeGridItems,
    metric,
  ])

  return (
    <ConditionalWrapper
      condition={draggable}
      wrapper={(children) => <DraggableGridItem {...{ ...gridItemProps, children }} />}
    >
      <ConditionalWrapper
        condition={Boolean(dashboard) && !draggable}
        wrapper={(children) => <StaticGridItem {...{ ...gridItemProps, children }} />}
      >
        <ConditionalWrapper
          condition={!isExaminerReport}
          wrapper={(children) => <div ref={domRef}>{children}</div>}
        >
          <DataContainer
            className={cx(className, styles.container, {
              [css({ minHeight: 350 })]: Boolean(dashboard),
              [css({ height })]: isChartsLibrary,
              [styles.draggable]: draggable,
              [styles.dragging]: isDragging,
            })}
            headerContent={headerContent}
            {...props}
          >
            {isChartsLibrary && (
              <div className={styles.help}>
                {informationText ?? 'Sorry, no help text was provided.'}
              </div>
            )}
            {children}
          </DataContainer>
        </ConditionalWrapper>
      </ConditionalWrapper>
    </ConditionalWrapper>
  )
}

export default ExaminerDataContainer
