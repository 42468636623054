import { css } from 'emotion'
import * as React from 'react'
import { Link } from 'react-router-dom'

import { colors, textStyles } from '../styles'
import Button from './Button'

type Modifiers = Partial<{
  accent: 'blue' | 'green'
  primary: boolean
  secondary: boolean
  hidden: boolean
  tertiary: boolean
}>

export type ActionButtonProps = Modifiers & {
  action?: () => void
  block?: boolean
  children?: JSX.Element
  className?: string
  href?: string
  disabled?: boolean
  hidden?: boolean
  key?: string
  text: string
  tw?: boolean
}

const primarySecondaryStyles = css(textStyles.paleGray2Bold13, {
  borderRadius: 4,
  minWidth: 95,
  padding: '7px 20px',
})

const commonStyles = css({
  '&[disabled]': {
    opacity: 0.5,
  },
  textAlign: 'center',
})

const primaryGreenStyles = css({
  backgroundColor: colors.appleGreen,
  color: colors.paleGray2,
})

const primaryBlueStyles = css({
  backgroundColor: colors.azure,
  color: colors.paleGray2,
})

const secondaryStyles = css({
  backgroundColor: colors.paleGray,
  color: colors.charcoalGray2,
})

const tertiaryStyles = css(textStyles.paleGray2Normal12, {
  color: colors.azure,
  padding: 0,
})

const hiddenStyles = css({ visibility: 'hidden' })

const getStyles = ({ accent = 'green', primary, secondary, tertiary, hidden }: Modifiers) => {
  if (primary) {
    return css(
      commonStyles,
      primarySecondaryStyles,
      accent === 'green' ? primaryGreenStyles : primaryBlueStyles,
      hidden && hiddenStyles
    )
  }
  if (secondary) {
    return css(commonStyles, primarySecondaryStyles, secondaryStyles, hidden && hiddenStyles)
  }
  if (tertiary) {
    return css(commonStyles, tertiaryStyles, hidden && hiddenStyles)
  }
}

const ActionButton = ({
  action,
  block,
  children,
  className,
  disabled,
  href,
  text,
  tw = false,
  ...modifiers
}: ActionButtonProps) =>
  href ? (
    <Link className={css(getStyles(modifiers), className)} to={href}>
      {children ?? text}
    </Link>
  ) : (
    <Button
      active={!disabled}
      className={tw ? undefined : css(getStyles(modifiers), className)}
      handleClick={action}
      tw={tw ? className : undefined}
    >
      {children ?? text}
    </Button>
  )

export { getStyles }
export default ActionButton
