import { DataSource, SearchView } from '@juristat/common/types'
import config from '@juristat/config'
import { css } from 'emotion'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import VisibleAtPathname from '../../../components/VisibleAtPathname'
import { colors, zIndex } from '../../../styles'
import {
  BI,
  Drafting,
  Examiner,
  FileGroup,
  Filters,
  Floppy,
  Icon102,
  LineChart,
  Search,
  Spy,
} from '../../icons'
import searchActions from '../../search/actions'
import { getSearchDataSource } from '../../search/selectors'
import { getCanQueryPpair, makeGetAccesses } from '../../session/selectors'
import { Access } from '../../session/types'
import actions from '../actions'
import { getDrawerState } from '../selectors'
import { DrawerState } from '../types'
import JButton from './JButton'
import NavBarIcon from './NavBarIcon'
import SettingsMenu from './SettingsMenu'

type NavBarProps = {
  className?: string
}

const styles = {
  dropdown: {
    classNameChevron: css({ display: 'none' }),
    classNameContent: css({
      '> svg': { right: 'inherit', left: '-.2em' },
      left: 'inherit',
      marginTop: '.2em',
      width: 250,
    }),
  },
  dropdownItem: css({ display: 'block', padding: '10px 20px' }),
  logo: css({
    margin: '20px 0',
  }),
  main: css({
    alignItems: 'center',
    backgroundImage: `linear-gradient(to top, ${colors.greenyBlue2} 80%, ${colors.appleGreen})`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 56,
    zIndex: zIndex.controlBar,
  }),
  topContainer: css({
    '& > :not(:first-child)': {
      marginBottom: 10,
    },
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  }),
}

const entities = [
  '/artunit',
  '/assignee-at-disp',
  '/attorney-at-disp',
  '/cpc',
  '/current-assignee',
  '/current-attorney',
  '/current-firm',
  '/firm-at-disp',
  '/examiner',
  '/techcenter',
  '/uspc',
]

const NavBar: React.FC<NavBarProps> = ({ className }) => {
  const dispatch = useDispatch()
  const setDrawerState = useCallback(
    (active: boolean, payload: DrawerState) =>
      dispatch(actions.setDrawerState(active ? DrawerState.Closed : payload)),
    [dispatch]
  )

  const drawerState = useSelector(getDrawerState)
  const dataSource = useSelector(getSearchDataSource)
  const [canAccessPlatform, canAccessWorkflowAutomation, canAccessExpertSearch] = useSelector(
    makeGetAccesses([Access.Platform, Access.WorkflowAutomation, Access.ExpertSearch])
  )
  const canQueryPrivatePair = useSelector(getCanQueryPpair)

  const noPlatformButExpertSearch = canAccessPlatform ? false : canAccessExpertSearch === true
  const isExpertSearchPath = useRouteMatch('/search/expert')

  const setReport = useCallback(
    () =>
      dispatch(
        searchActions.setReport(
          { dataSource, uid: config.emptySearchUid, view: SearchView.Table },
          { clearQueryString: true }
        )
      ),
    [dataSource, dispatch]
  )
  const setDataSource = useCallback(
    () =>
      dispatch(
        searchActions.setReport(
          {
            dataSource:
              dataSource === DataSource.PublicPair ? DataSource.PrivatePair : DataSource.PublicPair,
          },
          { clearQueryString: true }
        )
      ),
    [dataSource, dispatch]
  )

  return (
    <>
      <div className={css(styles.main, className)}>
        <div className={styles.topContainer}>
          <JButton />
          {noPlatformButExpertSearch ? null : (
            <NavBarIcon
              canAccess={Access.HasSession}
              icon={Search}
              tooltip="New Search"
              handleClick={setReport}
            />
          )}
          <NavBarIcon
            canAccess={[Access.Platform, Access.FreeHealthDashboard]}
            icon={LineChart}
            href="/dashboards"
            tooltip="Dashboards"
          />
          {canQueryPrivatePair && (
            <NavBarIcon
              active={dataSource === DataSource.PrivatePair}
              canAccess={Access.HasSession}
              icon={Spy}
              tooltip="Toggle Private Data"
              handleClick={setDataSource}
            />
          )}
          <NavBarIcon
            canAccess={Access.Drafting}
            icon={Drafting}
            href="/drafting"
            tooltip="Drafting"
          />
          <NavBarIcon
            canAccess={Access.OneOTwoReports}
            icon={Icon102}
            href="/102-forward-cite"
            tooltip="102 Report"
          />
          {!canAccessPlatform && !canAccessExpertSearch ? (
            <>
              <NavBarIcon
                canAccess={Access.Examiner}
                icon={Examiner}
                href="/examiner"
                tooltip="Examiner"
              />
              <NavBarIcon
                canAccess={Access.BusinessIntelligence}
                icon={BI}
                href="/business-intelligence"
                tooltip="Business Intelligence"
              />
            </>
          ) : null}
          {canAccessWorkflowAutomation ? (
            <NavBarIcon
              canAccess={Access.WorkflowAutomation}
              icon={FileGroup}
              href="/oars"
              tooltip="Workflow Automation"
            />
          ) : (
            <NavBarIcon
              canAccess={Access.IdsViewer}
              icon={FileGroup}
              href="/ids"
              tooltip="Workflow Automation"
            />
          )}
          {noPlatformButExpertSearch || isExpertSearchPath ? null : (
            <>
              <VisibleAtPathname
                pathname={[
                  '/dashboards/(prosecution|roi)-metrics',
                  '/dashboards/:dashboard([0-9a-f]{8})-(.+)',
                  '/home',
                  '/search',
                  '/uspto',
                  ...entities,
                ]}
                render={() => (
                  <NavBarIcon
                    canAccess={Access.HasSession}
                    active={drawerState === DrawerState.Filters}
                    icon={Filters}
                    handleClick={() =>
                      setDrawerState(drawerState === DrawerState.Filters, DrawerState.Filters)
                    }
                    tooltip="Filters"
                  />
                )}
              />
              <VisibleAtPathname
                pathname={['/home', '/search', ...entities]}
                render={() => (
                  <NavBarIcon
                    canAccess={Access.HasSession}
                    active={drawerState === DrawerState.SavedSearches}
                    icon={Floppy}
                    handleClick={() =>
                      setDrawerState(
                        drawerState === DrawerState.SavedSearches,
                        DrawerState.SavedSearches
                      )
                    }
                    tooltip="Saved Searches"
                  />
                )}
              />
            </>
          )}
        </div>
        <SettingsMenu />
      </div>
    </>
  )
}

export default NavBar
