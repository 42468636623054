import { css } from 'emotion'
import React from 'react'
import { animated, useSpring } from 'react-spring'

import { useResizeObserver } from '../hooks'
import { Lock } from '../modules/icons'
import { colors, textStyles } from '../styles'
import Chevron, { Direction } from './Chevron'

type AccordionProps = {
  children?: React.ReactNode
  className?: string
  contentClassName?: string
  handleClick: () => void
  headerClassName?: string
  label: string
  locked?: boolean
  maxHeight?: number
  onlyRenderWhenOpen?: boolean
  open?: boolean
  openClassName?: string
  renderContent?: () => JSX.Element | null
  renderStatus?: () => JSX.Element
}

const styles = {
  header: css(textStyles.paleGray2Bold12, {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'uppercase',
  }),
  rightContainer: css({
    '& svg': {
      fill: colors.paleGray2,
    },
    alignItems: 'center',
    display: 'flex',
  }),
  slideOutContainer: css({
    overflowY: 'hidden',
    position: 'relative',
  }),
}

const Accordion = ({
  children,
  className,
  contentClassName,
  handleClick,
  headerClassName,
  label,
  locked = false,
  maxHeight,
  onlyRenderWhenOpen = false,
  open = false,
  renderContent = () => null,
  renderStatus,
}: AccordionProps) => {
  const {
    ref,
    dimensions: { height },
  } = useResizeObserver<HTMLDivElement>()
  const props = useSpring({
    maxHeight: open ? maxHeight ?? height : 0,
  })

  return (
    <div className={className}>
      <h2 className={css(styles.header, headerClassName)} onClick={handleClick}>
        {label}
        <div className={styles.rightContainer}>
          {renderStatus?.()}
          {!locked ? (
            <Chevron
              direction={open ? Direction.Up : Direction.Down}
              title={`${open ? 'Close' : 'Open'} filter`}
            />
          ) : (
            <Lock height={12} width={12} />
          )}
        </div>
      </h2>
      <animated.div className={styles.slideOutContainer} style={props}>
        {(!onlyRenderWhenOpen || open) && (
          <div ref={ref}>
            <div className={contentClassName}>{children ?? renderContent()}</div>
          </div>
        )}
      </animated.div>
    </div>
  )
}

export default Accordion
