import config from '@juristat/config'
import { call, put } from 'redux-saga/effects'

import { api } from '../../api'
import actions from '../../session/actions'
import { PpairUser } from '../../session/types'

function* getPpairUser() {
  const url = `${config.ppairUrl}/health/simple`

  const options = {
    credentials: 'include',
    url,
  }

  try {
    const response = yield call(api, url, options)
    const ppairUser = response.ok ? PpairUser.True : PpairUser.False
    yield put(actions.setPpairUser(ppairUser))
  } catch (ex) {
    yield put(actions.setPpairUser(PpairUser.False))
  }

  return
}

export default getPpairUser
