import { css } from 'emotion'
import React from 'react'

import NavigationOmnisearch from '../modules/omnisearch/components/NavigationOmnisearch'
import { colors, zIndex } from '../styles'

type HeaderProps = Partial<{
  initialValue: string
}>

const styles = {
  container: css({
    borderBottom: `1px solid ${colors.silver2}`,
    height: 60,
    minHeight: 60,
    position: 'relative',
  }),
  search: css({
    left: '50%',
    position: 'absolute',
    top: 10,
    transform: 'translateX(-50%)',
    zIndex: zIndex.header,
  }),
}

const Header: React.FC<HeaderProps> = ({ initialValue }) => (
  <header className={styles.container}>
    <NavigationOmnisearch className={styles.search} initialValue={initialValue} />
  </header>
)

export default Header
