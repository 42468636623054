import { RadioOption } from '@juristat/common/types'
import { css } from 'emotion'
import * as React from 'react'

import { Check } from '../modules/icons'
import { colors, textStyles } from '../styles'

type RadioProps<T> = RadioOption<T> & {
  classNameSelected?: string
  select: (value: T) => void
  selected: boolean
}

const styles = {
  disabled: css({
    cursor: 'not-allowed',
    opacity: 0.6,
  }),
  nativeRadio: css({
    '&::before': {
      border: `1px solid ${colors.silver}`,
      borderRadius: 10,
      content: '""',
      display: 'inline-block',
      height: 20,
      width: 20,
    },
    border: '0',
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: '0',
    position: 'absolute',
    width: '1px',
  }),
  radioAndLabel: css(textStyles.charcoalGrayNormal12, {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
  }),
  styledRadioContainer: css({
    '& > svg': {
      fill: colors.linkBlue,
      height: 10,
      visibility: 'hidden',
      width: 10,
    },
    alignItems: 'center',
    backgroundColor: colors.paleGray,
    border: `1px solid ${colors.silver2}`,
    borderRadius: 10,
    cursor: 'pointer',
    display: 'flex',
    flexShrink: 0,
    height: 22,
    justifyContent: 'center',
    marginRight: 9,
    width: 22,
  }),
  styledRadioContainerChecked: css({
    '& > svg': {
      visibility: 'visible',
    },
    backgroundColor: colors.white,
    border: `1px solid ${colors.linkBlue}`,
  }),
}

const Radio = <T,>({
  classNameSelected,
  disabled = false,
  label,
  select,
  selected,
  value,
}: RadioProps<T>) => (
  <label
    className={css(
      styles.radioAndLabel,
      selected && classNameSelected,
      disabled && styles.disabled
    )}
    onClick={(evt) => {
      evt.preventDefault()

      if (disabled) {
        return
      }

      select(value)
    }}
  >
    <input className={styles.nativeRadio} readOnly type="radio" value={String(value)} />
    <div
      className={css(
        styles.styledRadioContainer,
        selected && styles.styledRadioContainerChecked,
        disabled && styles.disabled
      )}
    >
      <Check />
    </div>
    <span>{label}</span>
  </label>
)

export default Radio
