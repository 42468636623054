import makeAction from '../../../../../redux/utils/makeAction'
import {
  DynamicValueErrorAction,
  GetExaminerAllowanceRateAction,
  MoveColumnAction,
  MoveColumnsAction,
  SetAllSelectedColumnsAction,
  SetAvailableColumnsAction,
  SetDynamicValueAction,
  SetExaminerAllowanceRateFetchingAction,
} from '../types'

const dynamicValueError = makeAction<DynamicValueErrorAction>('search/table/DYNAMIC_VALUE_ERROR')
const fetchExaminerAllowanceRate = makeAction<SetExaminerAllowanceRateFetchingAction>(
  'search/table/FETCH_EXAMINER_ALLOWANCE_RATE'
)
const getExaminerAllowanceRate = makeAction<GetExaminerAllowanceRateAction>(
  'search/table/GET_EXAMINER_ALLOWANCE_RATE'
)
const moveColumn = makeAction<MoveColumnAction>('search/table/MOVE_COLUMN')
const moveColumns = makeAction<MoveColumnsAction>('search/table/MOVE_COLUMNS')
const setAllSelectedColumns = makeAction<SetAllSelectedColumnsAction>(
  'search/table/SET_ALL_SELECTED_COLUMNS'
)
const setAvailableColumns = makeAction<SetAvailableColumnsAction>(
  'search/table/SET_AVAILABLE_COLUMNS'
)
const setDynamicValue = makeAction<SetDynamicValueAction>('search/table/SET_DYNAMIC_VALUE')

const actions = {
  dynamicValueError,
  fetchExaminerAllowanceRate,
  getExaminerAllowanceRate,
  moveColumn,
  moveColumns,
  setAllSelectedColumns,
  setAvailableColumns,
  setDynamicValue,
}

export default actions
