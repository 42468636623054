import { css } from 'emotion'
import React from 'react'

import { colors, zIndex } from '../styles'
import JuristatLoader from './JuristatLoader'

const styles = {
  splash: css({
    '> svg': {
      height: '50%',
      width: '50%',
    },
    alignItems: 'center',
    backgroundColor: colors.white,
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    left: 0,
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    width: '100vw',
    zIndex: zIndex.splash,
  }),
}

const Splash = () => (
  <div className={styles.splash} title="Splash screen">
    <JuristatLoader />
  </div>
)

export default Splash
