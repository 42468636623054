import { css } from 'emotion'
import { contains, isEmpty, isNil, reject, values } from 'ramda'
import * as React from 'react'

import ButtonGroup from '../../../../components/ButtonGroup'
import { BoundAction } from '../../../../redux'
import { textStyles } from '../../../../styles'
import {
  MaybeStatCountSelection as MaybeSelection,
  SelectSomeAction,
  StatCountSelection as Selection,
  SelectionAndValue,
  StatCount,
  StatCountFilter,
} from '../../types'
import NumberInput from './NumberInput'

type StatCountFilterProps = {
  filter: StatCountFilter
  selectionAndValue: SelectionAndValue
  set: BoundAction<SelectSomeAction>
  title?: string
}

type StatCountUpdateProps = Omit<StatCountFilterProps, 'title'> & {
  empty: boolean
}

const styles = {
  buttonGroup: css({
    '& button': {
      width: '33.33%',
    },
    marginBottom: 10,
    width: '100%',
  }),
  container: css({
    '& + &': {
      marginTop: '1em',
    },
    display: 'flex',
    flexWrap: 'wrap',
  }),
  title: css(textStyles.paleGray2Semibold10, {
    flexBasis: '100%',
    marginBottom: 4,
    textTransform: 'uppercase',
  }),
}

const buttonOptions = [
  {
    label: 'At Least',
    value: Selection.AtLeast,
  },
  {
    label: 'At Most',
    value: Selection.AtMost,
  },
  {
    label: 'Exactly',
    value: Selection.Exactly,
  },
]

const getValuesOrDefaults = ({ selection, value }: SelectionAndValue) => ({
  selection: selection ?? Selection.AtLeast,
  value: value ?? '1',
})

const getFilterValueOrEmptyObject = (empty: boolean, selectionAndValue: SelectionAndValue) => {
  if (contains(null, values(selectionAndValue)) && !empty) {
    return {}
  }

  const { selection, value } = getValuesOrDefaults(selectionAndValue)
  const filterValue: StatCount =
    selection === Selection.Exactly
      ? { atLeast: Number(value), atMost: Number(value) }
      : { [selection]: Number(value) }

  return filterValue
}

const update = ({ empty, filter, set, selectionAndValue }: StatCountUpdateProps) => {
  const value = getFilterValueOrEmptyObject(empty, selectionAndValue)

  set({ filter, value })
}

const StatCountFilterComponent: React.FC<StatCountFilterProps> = ({
  filter,
  selectionAndValue,
  set,
  title,
}) => {
  const empty = isEmpty(reject(isNil, selectionAndValue))
  const callUpdate = (newValue: SelectionAndValue) =>
    update({ filter, set, empty, selectionAndValue: newValue })
  const updateSelection = (selection: MaybeSelection) =>
    callUpdate({ ...selectionAndValue, selection })
  const updateValue = (value: string | null) => callUpdate({ ...selectionAndValue, value })

  return (
    <div className={styles.container}>
      {title && <small className={styles.title}>{title}</small>}
      <ButtonGroup
        classNameContainer={styles.buttonGroup}
        buttons={buttonOptions}
        update={updateSelection}
        value={selectionAndValue.selection}
      />
      <NumberInput update={updateValue} value={selectionAndValue.value} />
    </div>
  )
}

export { StatCountFilterProps }
export default StatCountFilterComponent
