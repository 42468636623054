import { css } from 'emotion'
import * as React from 'react'

import { Close } from '../../modules/icons'
import { colors, textStyles } from '../../styles'
import Button from '../Button'
import { ModalProps } from './Modal'

type ModalHeaderProps = Pick<ModalProps, 'closeModal' | 'title' | 'classNameHeaderContainer'>

const styles = {
  headerCloseIcon: css({
    alignItems: 'center',
    display: 'flex',
    fill: colors.dark,
    height: 30,
    justifyContent: 'center',
    width: 30,
  }),
  headerContainer: css({
    alignItems: 'center',
    backgroundColor: colors.shadow,
    borderRadius: '4px 4px 0 0',
    display: 'flex',
    flexShrink: 0,
    height: 36,
    justifyContent: 'space-between',
    padding: 10,
  }),
  headerTitle: css(textStyles.darkBold12, {
    textTransform: 'uppercase',
  }),
}

const ModalHeader = ({ closeModal, title, classNameHeaderContainer }: ModalHeaderProps) => (
  <div className={css(styles.headerContainer, classNameHeaderContainer)}>
    <h1 className={styles.headerTitle}>{title}</h1>
    {closeModal ? (
      <Button className={styles.headerCloseIcon} handleClick={closeModal}>
        <Close height={12} width={12} />
      </Button>
    ) : (
      <div />
    )}
  </div>
)

export default ModalHeader
