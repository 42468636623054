import { WebAuth } from 'auth0-js'
import { createContext } from 'react'
import { EventObject, State } from 'xstate'

import { AuthProvider } from './types'

type AuthorizeAction = (payload: AuthProvider) => void
type ResetPasswordState = State<{ error: string | null }, EventObject>
type ResetPasswordAction = (email: string) => void

export const AccessTokenContext = createContext<string | undefined>(undefined)
export const Auth0ClientContext = createContext<WebAuth | undefined>(undefined)
export const AuthorizeContext = createContext<AuthorizeAction | undefined>(undefined)
export const ResetPasswordContext = createContext<
  readonly [ResetPasswordState, ResetPasswordAction] | undefined
>(undefined)
