import Downshift, { ControllerStateAndHelpers, DownshiftProps } from 'downshift'
import { css, cx } from 'emotion'
import React, { useState } from 'react'

import { useFuzzySort } from '../../hooks'
import { Close } from '../../modules/icons'
import { colors, textStyles, zIndex } from '../../styles'
import Chevron, { Direction } from '../Chevron'
import SearchTextInput from '../SearchTextInput'

type Option<T> = {
  label: string
  value: T
}

type SelectProps<T> = {
  children?: (
    props: Pick<ControllerStateAndHelpers<Option<T>>, 'getItemProps'> &
      Option<T> & {
        className: string
        index: number
      }
  ) => React.ReactNode
  className?: string
  disabled?: boolean
  handleClear?: () => void
  maxHeight?: number
  options: Array<Option<T>>
  placeholder?: string
  searchable?: boolean | string
  searchTextInputProps?: React.ComponentProps<typeof SearchTextInput>
  title?: string
  tw?: string
}

type BaseSelectProps<T> = Omit<DownshiftProps<Option<T>>, 'children'> &
  SelectProps<T> & {
    children: NonNullable<SelectProps<T>['children']>
    label: string
  }

interface DownshiftMouseEvent extends React.MouseEvent {
  nativeEvent: MouseEvent & { preventDownshiftDefault?: boolean }
}

const styles = {
  active: css({
    backgroundColor: colors.cloudyBlueAlpha20,
  }),
  arrow: css({
    fill: colors.charcoalGray,
    height: 9,
    width: 9,
  }),
  clear: css({
    '&:hover': {
      fill: colors.pastelRed,
    },
    cursor: 'pointer',
    height: 9,
    marginRight: 5,
    transition: 'fill 300ms ease-in-out',
    width: 9,
  }),
  container: css({
    position: 'relative',
  }),
  item: css(textStyles.darkNormal13, {
    cursor: 'pointer',
    justifyContent: 'flex-start',
    padding: '6px 14px',
    transition: 'background-color 300ms ease-in-out',
    width: '100%',
  }),
  label: css({
    overflow: 'hidden',
    paddingRight: 5,
    textAlign: 'left',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
  }),
  filtersAppliedLabel: css({
    paddingRight: 5,
  }),
  main: css(textStyles.charcoalGrayBold12, {
    '&:disabled': {
      cursor: 'not-allowed',
      opacity: 0.6,
    },
    alignItems: 'center',
    // Tailwind is overwriting the default <button> style
    background: `${colors.white} !important`,
    border: `1px solid ${colors.silver2}`,
    borderRadius: 4,
    display: 'flex',
    height: 36,
    justifyContent: 'space-between',
    padding: '0 10px',
    width: '100%',
  }),
  menu: css({
    backgroundColor: colors.white,
    borderRadius: 4,
    boxShadow: `0 4px 10px 0 ${colors.charcoalGray2alpha30}`,
    overflow: 'hidden',
    padding: '10px 0',
    position: 'absolute',
    width: '100%',
    zIndex: zIndex.select,
  }),
  menuClosed: css({
    display: 'none',
  }),
  placeholder: css(textStyles.silver3Normal13),
  search: css({
    margin: 10,
    marginTop: 0,
  }),
}

const BaseSelect = <T extends string | number>({
  disabled = false,
  children,
  className,
  handleClear,
  label,
  maxHeight,
  options,
  placeholder = 'Select an option',
  searchable = false,
  searchTextInputProps = {},
  title,
  tw,
  ...props
}: BaseSelectProps<T>) => {
  const [needle, setNeedle] = useState('')
  const results = useFuzzySort({
    haystack: options,
    keys: ['label'],
    needle,
  })
  const filtersAppliedLabel = label.split(',').length > 1 ? ` (${label.split(',').length})` : ''

  return (
    <Downshift itemToString={(item) => (item ? item.label : '')} {...props}>
      {({
        clearSelection,
        getItemProps,
        getMenuProps,
        getToggleButtonProps,
        highlightedIndex,
        isOpen,
      }) => (
        <div className={cx(css(styles.container, className), tw)} title={title}>
          <button {...getToggleButtonProps({ className: styles.main, disabled, title: label })}>
            {label ? (
              <span className={styles.label}>{label}</span>
            ) : (
              <span className={css(styles.label, styles.placeholder)}>{placeholder}</span>
            )}
            {filtersAppliedLabel && (
              <span className={css(styles.filtersAppliedLabel, styles.filtersAppliedLabel)}>
                {filtersAppliedLabel}
              </span>
            )}
            {label && handleClear ? (
              <Close
                className={styles.clear}
                onClick={(event: DownshiftMouseEvent) => {
                  event.nativeEvent.preventDownshiftDefault = true

                  clearSelection(handleClear)
                }}
                title="Clear selection"
              />
            ) : null}
            <Chevron
              className={styles.arrow}
              direction={isOpen ? Direction.Up : Direction.Down}
              title={isOpen ? 'Close' : 'Open'}
            />
          </button>
          <ul
            {...getMenuProps({
              className: cx(styles.menu, {
                [styles.menuClosed]: !isOpen,
                [css({ maxHeight, overflow: 'auto' })]: Boolean(maxHeight),
              }),
            })}
          >
            {searchable ? (
              <SearchTextInput
                accent="blue"
                className={styles.search}
                handleOnTextChange={setNeedle}
                placeholder={[
                  'Search',
                  typeof searchable === 'string' ? searchable : 'for an option',
                ].join(' ')}
                onClear={needle ? () => setNeedle('') : undefined}
                text={needle}
                {...searchTextInputProps}
              />
            ) : null}
            {!isOpen
              ? null
              : results.map((item, index) =>
                  children({
                    ...item,
                    className: css(styles.item, index === highlightedIndex && styles.active),
                    getItemProps,
                    index,
                  })
                )}
          </ul>
        </div>
      )}
    </Downshift>
  )
}

export { Option, SelectProps }
export default BaseSelect
