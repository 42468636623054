import { css } from 'emotion'
import React, { useMemo } from 'react'

import { colors, textStyles } from '../styles'
import getAvailablePageRange from '../utils/getAvailablePageRange'
import Button from './Button'

type PaginationBarProps = {
  className?: string
  current: number
  go: (page: number) => void
  pageCount: number
  theme?: 'green' | 'blue'
}

const styles = {
  container: css({
    '& > :not(:last-child)': {
      marginRight: 4,
    },
    background: colors.white,
    border: `1px solid ${colors.cloudyBlueAlpha50}`,
    borderRadius: 4,
    display: 'inline-flex',
    padding: 2,
  }),
  page: css(textStyles.charcoalGray2Bold13, {
    borderRadius: 4,
    height: 32,
    width: 32,
  }),
  pageActive: (theme: PaginationBarProps['theme']) =>
    css({
      backgroundColor: theme === 'blue' ? colors.azure : colors.appleGreen,
      color: colors.paleGray2,
    }),
  prevNext: css(textStyles.charcoalGray2Bold13, {
    '&:disabled': {
      color: colors.charcoalGray2alpha50,
    },
    backgroundColor: colors.paleGray,
    borderRadius: 4,
    padding: '7px 11px',
  }),
}

const PaginationBar = ({
  className,
  current,
  go,
  pageCount,
  theme = 'green',
}: PaginationBarProps) => {
  const pageList = useMemo(
    () => getAvailablePageRange(current, pageCount, 10),
    [current, pageCount]
  )
  const hasNext = current < pageCount
  const hasPrevious = current > 1

  if (pageCount <= 1) {
    return null
  }

  return (
    <div className={css(styles.container, className)}>
      <Button active={hasPrevious} className={styles.prevNext} handleClick={() => go(current - 1)}>
        Previous
      </Button>
      {pageList.map((num) => (
        <Button
          aria-pressed={num === current}
          className={css(styles.page, num === current && styles.pageActive(theme))}
          handleClick={() => go(num)}
          key={String(num)}
        >
          {num}
        </Button>
      ))}
      <Button active={hasNext} className={styles.prevNext} handleClick={() => go(current + 1)}>
        Next
      </Button>
    </div>
  )
}

export default PaginationBar
