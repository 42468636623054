import {
  ColumnGraphQLVariable,
  DataSource,
  FromApplication,
  MissingPermission,
  OrderDirection,
  SearchScope,
  SearchView,
  SortOrder,
} from '@juristat/common/types'

import { Action } from '../../redux'
// $CodeSplitFixMe
import { GraphQlOfficeAction, GraphQlRejectionBases } from '../application/types'
// $CodeSplitFixMe
import { ActiveReducer, Actions as FilterActions, SearchSetFilterState } from '../filter/types'
import { HttpContent } from '../http'
import { Actions as PaginationActions, PaginationState } from '../pagination/types'
import { Feature } from '../session/types'
import { Column, TableViewState } from './modules/table/types'

export { TransformedColumns } from './utils/transformColumns'

export enum SearchMatchInfoTypesEnum {
  Abstract = 'ABSTRACT',
  Appeal = 'APPEAL',
  Claims = 'CLAIMS',
  Description = 'DESCRIPTION',
  Other = 'OTHER',
  Rejection = 'REJECTION',
  Title = 'TITLE',
}

export enum SearchType {
  Keyword = 'keyword',
  SimilarTo = 'similarTo',
}

export type SearchSet = {
  dataSource: DataSource
  definition: Definition
  filters: SearchSetFilterState
  pagination: PaginationState
  phrase: string | null
  results: SearchResultHttpContent<TableResult[]>
  scopes: SearchScope[]
  sort: SortOrder
  time: string
  type: SearchType
  uid: string
}

export enum SearchSetReportStatus {
  Clean = 'clean',
  Dirty = 'dirty',
}

export type SearchSetReport = {
  checksum: string
  status: SearchSetReportStatus
  userDataKey: string
}

export type SearchSetViewState = {
  error: boolean
  table: TableViewState
}

export type SearchViewActiveState = {
  filters: string
  results: string
}

export type SearchViewState = {
  active: SearchViewActiveState
  advancedSearchVisible: boolean
}

export type SearchSetState = {
  report: SearchSetReport | null // naming this report in anticipation of terminology change
  search: SearchSet
  view: SearchSetViewState
}

export type SearchSets = {
  [searchId: string]: SearchSetState
}

export type SearchState = {
  sets: SearchSets
  view: SearchViewState
}

export type ApplicationMatchInfo = {
  document?: GraphQlOfficeAction & { files: string[] }
  officeAction?: {
    application: {
      officeActions: Array<{
        officeAction: {
          docCode: string
          mailroomDate: string
        }
      }>
    }
    bases: GraphQlRejectionBases[]
  }
  publishing?: 'APPLICATION' | 'PATENT'
  type: SearchMatchInfoTypesEnum
}

export type ApplicationContinuity =
  | 'CONTINUATION'
  | 'CONTINUATION_IN_PART'
  | 'DIVISION'
  | 'NATIONAL_STAGE_ENTRY'
  | 'PROVISIONAL_APPLICATION'
  | 'REEXAMINATION'
  | 'REISSUE'
  | 'SUBSTITUTION'
  | 'SUPPLEMENTAL_EXAMINATION'
  | 'UNKNOWN'

type DynamicColumn = {
  column: string
  queryId: string
  valueString: never
}

type StaticColumn = {
  column: string
  queryId: undefined
  valueString: string | null
}

export enum ContinuationType {
  Continuation = 'CONTINUATION',
  ContinuationInPart = 'CONTINUATION_IN_PART',
  Division = 'DIVISION',
  NationalStageEntry = 'NATIONAL_STAGE_ENTRY',
  ProvisionalApplication = 'PROVISIONAL_APPLICATION',
  Reexamination = 'REEXAMINATION',
  Reissue = 'REISSUE',
  Substitution = 'SUBSTITUTION',
  SupplementalExamination = 'SUPPLEMENTAL_EXAMINATION',
  Unknown = 'UNKNOWN',
}

export type ContinuityApplication = {
  application: {
    appno: number
  } | null
  continuationType: ContinuationType
}

export type Continuity = {
  children: ContinuityApplication[]
  parents: ContinuityApplication[]
}

export type ContinuityResult = {
  children: number[]
  count: number
  parents: number[]
}

export type GraphQLResult = {
  abstract: string[] | null
  appno: number
  drawings: string[] | null
  publication: {
    country: string
    kind: string
    number: string
  }
  status: string
  title: string
}

export type TableGraphQLResult = {
  columns: Array<StaticColumn | DynamicColumn>
}

type GraphQLErrorLocation = {
  column: number
  line: number
}

type GraphQLError = {
  locations?: GraphQLErrorLocation[]
  message: string
  path?: string[]
  stack: string
}

export type GraphQLSearchResponse = {
  uid: {
    page: GraphQLResult[]
  }
}

export type ExportResponse = {
  data: {
    submitExportJob: {
      applicationSet: {
        jobId: string
      }
    }
  }
  errors?: GraphQLError[]
}

export enum ExportStatus {
  Complete = 'Complete',
  Error = 'Error',
  InProgress = 'In progress',
  Received = 'Received',
}

export type ExportStatusObject = {
  downloadUrl: string
  status: ExportStatus
}

export type ExportStatusResponse = {
  data: {
    exportStatus: {
      downloadUrl: string
      status: string
    }
  }
  errors?: GraphQLError[]
}

export type UidGraphQlResponse<T> = {
  data: {
    uid: T
  }
}

export type IdAndMaybeName = {
  id: number
  name: string | null
}

export type ResultColumns = Nullable<{
  [Column.ApplicationNumber]: number
  [Column.ArtUnit]: number
  [Column.Assignee]: string
  [Column.AssigneeAtDisposition]: string
  [Column.AssigneeAtPublishing]: string
  [Column.AssigneeId]: number
  [Column.AssistantExaminer]: string
  [Column.AttorneyDocketNumber]: string
  [Column.CpcClass]: string
  [Column.DispositionDate]: string
  [Column.Examiner]: string
  [Column.ExaminerId]: number
  [Column.FilingDate]: string
  [Column.FinalRejections]: number
  [Column.Firm]: string
  [Column.FirmAtDisposition]: string
  [Column.FirmAtPublishing]: string
  [Column.FirmId]: number
  [Column.FirstNamedInventor]: string
  [Column.NonFinalRejections]: number
  [Column.OfficeActions]: number
  [Column.PairStatus]: string
  [Column.PairStatusDate]: string
  [Column.PatentIssueDate]: string
  [Column.PatentNumber]: number
  [Column.PrimaryExaminer]: string
  [Column.PublicationDate]: string
  [Column.PublicationNumber]: number
  [Column.Status]: string
  [Column.Title]: string
  [Column.UspcSubclass]: string
  [Column.UspcClass]: number
  [key: string]: boolean | number | string | null
}>

export type Result = GraphQLResult & {
  abstract: string[]
  dataSource: DataSource
  matchInfo: ApplicationMatchInfo[]
}

export type ApplicationResult<T = ResultColumns> = Result & TableResult<T>

export type TableResult<T = ResultColumns> = {
  columns: T
  dataSource: DataSource
}

export type RGB = [number, number, number]

export type SearchPhrase = { [key in SearchScope]: string }

export type SearchVariables = {
  columns: ColumnGraphQLVariable[]
  filters?: ActiveReducer
  pageNum: number
  pageSize: number
  scopes?: SearchScope[]
  searches?: SearchPhrase
  similarTo?: SearchPhrase
  sortOrders: SortOrderVariables[]
  withFilters?: boolean
  uid?: string
}

export type Definition = Pick<SearchVariables, 'filters' | 'searches' | 'similarTo'> & {
  orderings?: SearchVariables['sortOrders']
}

export type SortOrderVariables = {
  orderingDirection: OrderDirection
  orderingType: string
}

export type ForkSearchSetPayload = {
  newId: string
  partial: Partial<SearchSet>
}

export type HydrateFromUidPayload = {
  dataSource?: DataSource
  filters?: Partial<ActiveReducer>
  phrase?: string
  scopes?: SearchScope[]
  sort?: SortPayload | null
  type?: SearchType
}

export type SetActiveSearchSetMeta = {
  filters?: boolean
  results?: boolean
}

export type SearchId = string

export type SearchMeta = { searchId?: SearchId }
type SetReportMeta = { clearQueryString?: boolean }

export type SearchResultsGetPayload = {
  columns: ColumnGraphQLVariable[]
  dataSource: DataSource
  pageNum: number
  uid: string
  view: SearchView
}

export type SetReportPayload = HydrateFromUidPayload &
  Partial<{
    filters: { userTag?: string[] }
    pageNum: number
    uid: string
    userDataKey: string
    view: SearchView
  }>

export type ErrorAction = Action<'search/ERROR', undefined, SearchMeta>
export type FetchAction = Action<'search/FETCH', undefined, SearchMeta>
export type ForkSearchSetAction = Action<'search/set/FORK', ForkSearchSetPayload, SearchMeta>
export type HydrateFromUidAction = Action<'search/HYDRATE', HydrateFromUidPayload, SearchMeta>
export type InputAction = Action<'search/INPUT', string, SearchMeta>
export type LoadAction = Action<'search/LOAD', number, SearchMeta>
export type MissingPermissionAction = Action<'search/MISSING_PERMISSION', Feature[], SearchMeta>
export type SetAction = Action<'search/SET', SetPayload, SearchMeta>
export type OrderSortAction = Action<'search/sort/ORDER', SortPayload, SearchMeta>
export type SearchResultsGetAction = Action<'search/results/GET', SearchResultsGetPayload>
export type SetActiveSearchSetAction = Action<
  'search/set/SET_ACTIVE',
  SearchId,
  SetActiveSearchSetMeta
>
export type SetAdvancedSearchVisibleAction = Action<'search/ADVANCED_SEARCH_VISIBLE', boolean>
export type SetDataSourceAction = Action<'search/dataSource/SET', DataSource, SearchMeta>
export type SetReportAction = Action<
  'search/report/SET',
  SetReportPayload | string,
  SearchMeta & SetReportMeta
>
export type SetScopesAction = Action<'search/scopes/SET', SearchScope[], SearchMeta>
export type SetTypeAction = Action<'search/type/SET', SearchType, SearchMeta>
export type SetUidAction = Action<'search/uid/SET', string, SearchMeta>

export type Actions =
  | ErrorAction
  | FetchAction
  | ForkSearchSetAction
  | HydrateFromUidAction
  | InputAction
  | LoadAction
  | MissingPermissionAction
  | OrderSortAction
  | SetAction
  | SetActiveSearchSetAction
  | SetAdvancedSearchVisibleAction
  | SetDataSourceAction
  | SetReportAction
  | SetScopesAction
  | SetTypeAction
  | SetUidAction
  | FilterActions
  | PaginationActions

export type SetPayload = {
  dataSource: DataSource
  results: Result[]
  page: number
  time: string
}
export type SortPayload = { direction: OrderDirection; field: string }

export type FetchingActions = Actions | FilterActions | PaginationActions

export { OrderDirection }

export type SearchResultHttpContent<T> = HttpContent<T> | FromApplication<T> | MissingPermission
