import config from '@juristat/config'
import { stringify } from 'qs'
import { pathOr } from 'ramda'
import { call, put, takeLatest } from 'redux-saga/effects'

import { Response } from '../../../redux'
import { api } from '../../api'
import { actions as authActions } from '../../auth'
import {
  NotificationTypes,
  makeNotification,
  actions as notificationActions,
} from '../../notification'
import actions from '../../session/actions'
import {
  Entity,
  SessionMeta,
  SessionPayload,
  SessionResult,
  SettingTypes,
} from '../../session/types'

const query = stringify(
  {
    groupKey: ['entity', 'firmName', 'smartshellUrl'],
    key: [
      'entityOverride',
      'FIRSTNAME',
      'LASTNAME',
      `${SettingTypes.ColumnLayout}/default`,
      `${SettingTypes.DefaultView}/default`,
    ],
  },
  { indices: false }
)

function* getSession() {
  try {
    const sessionResult: Response<SessionResult> = yield call(
      api,
      `${config.accountsUrl}/session?${query}`
    )

    if (!sessionResult.ok) {
      throw new Error()
    }

    const { data, groupData, prices, user, accountStatus }: SessionResult = yield call([
      sessionResult,
      'json',
    ])
    const groupResult: Response<string> = yield call(
      api,
      `${config.accountsUrl}/group/${user.group_id}/name`
    )

    if (!groupResult.ok) {
      throw new Error()
    }

    const groupName: string = yield call([groupResult, 'json'])

    const smartshellUrl = pathOr(config.smartshellUrl, ['smartshellUrl'], groupData)
    const payload: SessionPayload = {
      entity: pathOr(null, ['entity', 'type'], groupData) as Entity | null,
      firstName: pathOr('', ['FIRSTNAME'], data),
      lastName: pathOr('', ['LASTNAME'], data),
      prices,
      settings: {
        columnLayout: pathOr(null, [`${SettingTypes.ColumnLayout}/default`], data),
        defaultView: pathOr(null, [`${SettingTypes.DefaultView}/default`], data),
        smartshellUrl,
      },
      username: user.username,
    }
    const meta: SessionMeta = {
      createdAt: user.created,
      entityId: pathOr(null, ['entity', 'id'], groupData),
      entityName: pathOr(null, ['entity', 'name'], groupData),
      groupId: user.group_id,
      groupName,
      juristatTrial: accountStatus?.type === 'trial' || undefined,
      userHash: user.hmac,
      uuid: user.uuid,
    }

    yield put(actions.set(payload, meta))
  } catch {
    yield put(
      notificationActions.push(
        makeNotification({
          message: 'An error occurred retrieving your Juristat session.',
          type: NotificationTypes.Error,
        })
      )
    )
    yield put(actions.clear())
  }
}

export { getSession, query }
export default function* watchGetSession() {
  yield takeLatest(authActions.setClaims().type, getSession)
}
