import { isNilOrEmpty } from '@juristat/common/utils'
import { call, put, select, take, takeLatest } from 'redux-saga/effects'

import searchActions from '../../search/actions'
import userSearchesActions from '../actions'
import { getSearchHistoryFavorites, makeGetSearchHistoryFavoritesItem } from '../selectors'
import { ApplyDefaultViewAction, SearchHistoryData } from '../types'

const searchHistoryToSearchVariables = (historyItem: SearchHistoryData) => {
  const { dataSource, query, searchScope: scope, searchType: type } = historyItem
  const { q, ...filters } = query

  return {
    dataSource,
    filters,
    phrase: query.q,
    scope,
    type,
  }
}

function* applyDefaultView(action: ApplyDefaultViewAction) {
  const defaultView = action.payload!
  const favorites = yield select(getSearchHistoryFavorites)

  // Fetch needed search history items
  if (isNilOrEmpty(favorites)) {
    yield put(userSearchesActions.get({ onlySaved: true }))
    yield take(userSearchesActions.set().type)
  }

  const getSearchHistoryFavoritesItem = yield call(makeGetSearchHistoryFavoritesItem, defaultView)
  const item = yield select(getSearchHistoryFavoritesItem)

  if (item !== null) {
    yield put(
      searchActions.setReport({
        ...searchHistoryToSearchVariables(item),
        userDataKey: defaultView,
      })
    )

    return
  }

  yield put(userSearchesActions.applyDefaultViewError())
}

function* watchApplyDefaultView() {
  yield takeLatest(userSearchesActions.applyDefaultView().type, applyDefaultView)
}

export { applyDefaultView, searchHistoryToSearchVariables }
export default watchApplyDefaultView
