import React, { ComponentProps } from 'react'
import { animated } from 'react-spring'
import { State } from 'xstate'

import ConditionalWrapper from '../../../components/ConditionalWrapper'
import Modal, { ModalSize } from '../../../components/Modal'
import { useSlideDown } from '../../../hooks/useSlide'
import { buttonStyles } from '../../../styles'
import { Spinner } from '../../icons'

type SingleOrArray<T> = T | T[]

type WorkflowAutomationModalProps<Context> = {
  disableSubmit?: boolean
  mutation: State<Record<string, unknown>, any>
  onClose: () => void
  onRest?: () => void
  onSubmit?: () => void
  responsive?: boolean
  size?: ModalSize
  state: State<Context, any>
  tertiaryButtonProps?: ComponentProps<typeof Modal>['tertiaryButtonProps']
  title: React.ReactNode
} & (
  | { children?: never; leftPane: JSX.Element; rightPane: JSX.Element }
  | { children: SingleOrArray<JSX.Element>; leftPane?: never; rightPane?: never }
)

const styles = {
  cancel: '!normal-case bg-transparent border !border-blue-gray !text-blue-gray min-w-[auto]',
  content: 'h-screen pt-[2%] w-screen',
  modal: '[&_>_div:first-of-type]:hidden shadow-[0px_4px_20px_0px_rgba(48,_55,_65,_0.3)] m-auto',
  pane: '[&_>_*:not(:first-child)]:mt-[10px] flex flex-col flex-1',
  panes: 'flex flex-1',
  popup: 'absolute z-[102]',
  responsive: {
    content: 'h-0',
    modal: 'h-[90vh] max-h-[740px] max-w-[580px] w-[80vw]',
    popup: 'left-[35%] top-[20%]',
  },
  spacer: 'w-[10px]',
  spinner: 'stroke-white',
  submit:
    '!normal-case ml-[10px] bg-azure min-w-[100px] transition-[background-color,opacity] duration-300 ease-in-out',
  thin: 'left-[45%]',
  title:
    'font-montserrat text-base font-stretch-normal not-italic font-bold tracking-normal border-b border-blue-gray text-blue-gray pb-[10px] mb-5',
  warning: 'bg-school-bus-yellow',
}

const WorkflowAutomationModal = <Context,>({
  disableSubmit = false,
  mutation,
  onClose,
  onRest,
  onSubmit,
  responsive = false,
  size,
  state,
  tertiaryButtonProps,
  title,
  children,
  leftPane,
  rightPane,
}: WorkflowAutomationModalProps<Context>) => {
  const transition = useSlideDown(state.matches('open'), undefined, onRest)

  return (
    <ConditionalWrapper condition={responsive} portal={document.body}>
      <>
        {transition(
          (props, item, { key }) =>
            item && (
              <animated.div
                className={`${styles.popup} ${size === ModalSize.Thin ? styles.thin : ''} ${
                  !responsive ? styles.responsive.popup : ''
                }`}
                key={key}
                style={props}
              >
                <ConditionalWrapper
                  condition={responsive}
                  wrapper={(children) => <div className={styles.content}>{children}</div>}
                >
                  <Modal
                    className={`${styles.modal} ${responsive ? styles.responsive.modal : ''}`}
                    classNameBodyContainer={responsive ? styles.responsive.content : undefined}
                    closeModal={onClose}
                    title=""
                    size={size}
                    primaryButtonProps={
                      onSubmit
                        ? {
                            action: onSubmit,
                            children: (
                              <>
                                {mutation.matches('loading') ? (
                                  <Spinner className={styles.spinner} height={20} width={20} />
                                ) : state.matches('warning.active') ? (
                                  'Continue'
                                ) : (
                                  'Submit'
                                )}
                              </>
                            ),
                            className: `${buttonStyles.tailWindPrimaryBlue} ${styles.submit} ${
                              state.matches('warning.active') ? styles.warning : ''
                            }`,
                            disabled:
                              disableSubmit ||
                              !state.matches('open.valid') ||
                              mutation.matches('loading'),
                            text: '',
                          }
                        : undefined
                    }
                    secondaryButtonProps={{
                      action: onClose,
                      className: `${buttonStyles.tailWindBlueOutlineWithIcon} ${styles.cancel}`,
                      text: 'Cancel',
                    }}
                    tertiaryButtonProps={tertiaryButtonProps}
                    tw={true}
                  >
                    <div className={styles.title}>{title}</div>
                    {children ?? (
                      <div className={styles.panes}>
                        <div className={styles.pane}>{leftPane}</div>
                        <div className={styles.spacer} />
                        <div className={styles.pane}>{rightPane}</div>
                      </div>
                    )}
                  </Modal>
                </ConditionalWrapper>
              </animated.div>
            )
        )}
      </>
    </ConditionalWrapper>
  )
}

export default WorkflowAutomationModal
