import { DataSource } from '@juristat/common/types'
import { SearchScope, SearchStatus, SearchView, SortOrder } from '@juristat/common/types'
import { contains, defaultTo, find, head, propEq } from 'ramda'
import { ParametricSelector, createSelector } from 'reselect'

import { AppState } from '../../../redux'
import matchSearchPath from '../../../utils/matchSearchPath'
import getFilterStateFromUrl from '../../filter/utils/getFilterStateFromUrl'
import { HttpStatus } from '../../http/types'
import { IntelligenceEntityType } from '../../intelligence/types'
import { getPaginationTotal } from '../../pagination/selectors'
import { PaginationTotal } from '../../pagination/types'
import { getAppnoParam, getPathname } from '../../router/selectors'
import { SearchResultHttpContent } from '../../search/types'
import { ApplicationColumn, Column } from '../modules/table/types'
import {
  OrderDirection,
  SearchSet,
  SearchSetReport,
  SearchSetReportStatus,
  SearchSetViewState,
  SearchType,
  SearchViewActiveState,
  SearchViewState,
  SortOrderVariables,
  TableResult,
} from '../types'
import transformColumns from '../utils/transformColumns'
import {
  getFilterSearchReport,
  getResultsSearchDetails,
  getResultsSearchView,
  getSearchId,
  getSearchViewActive,
  getSearchViewState,
} from './getSearch'

const defaultScopes = [SearchScope.FullText]

const emptyArray: any[] = []
const emptyObject = {}
const emptyString = ''
const byId = (_: unknown, { searchId }: { searchId: string }) => searchId

// We can't reuse the selector from table module because circular dependencies
const getTableColumns = createSelector(
  getResultsSearchView,
  (state: SearchSetViewState) => state.table.columns
)

const getActiveProp: ParametricSelector<
  AppState,
  { activeProp: keyof SearchViewActiveState },
  keyof SearchViewActiveState
> = (_: AppState, { activeProp }) => activeProp

export const getActiveSearchId = createSelector(
  getSearchViewActive,
  getActiveProp,
  (state, activeProp) => state?.[activeProp]
)

export const makeGetActiveSearchId = (activeProp: keyof SearchViewActiveState) =>
  createSelector(getSearchViewActive, (state: SearchViewActiveState) => state[activeProp])

export const makeGetSearchIdFromPayloadOrState = (activeProp: keyof SearchViewActiveState) => {
  const getSearchIdFromState = makeGetActiveSearchId(activeProp)
  return createSelector(
    getSearchId,
    getSearchIdFromState,
    (id: string | undefined, idFromState: string) => id ?? idFromState
  )
}

export const getAdvancedSearchVisible = createSelector(
  getSearchViewState,
  (state: SearchViewState) => state.advancedSearchVisible
)

const getSearchDataSourceByPathname = createSelector(
  getResultsSearchDetails,
  getPathname,
  (state, pathname) => {
    const { entity } = getFilterStateFromUrl(pathname)

    return entity === IntelligenceEntityType.SearchSet
      ? state?.dataSource ?? DataSource.PublicPair
      : DataSource.PublicPair
  }
)

export const getSearchDataSource = createSelector(getSearchDataSourceByPathname, (state) => state)

// Types are different between `useSelector()` and `yield select()`
export const getSearchDataSourceById = createSelector(
  getSearchDataSourceByPathname,
  byId,
  (state) => state
)

export const getSearchScopes = createSelector(
  getResultsSearchDetails,
  (state: SearchSet) => state?.scopes || defaultScopes
)

// Types are different between `useSelector()` and `yield select()`
export const getSearchScopesById = createSelector(
  getResultsSearchDetails,
  byId,
  (state: SearchSet) => state?.scopes || defaultScopes
)

export const getSearchDefinition = createSelector(
  getResultsSearchDetails,
  (state: SearchSet) => state.definition
)

export const getSearchType = createSelector(
  getResultsSearchDetails,
  (state: SearchSet): SearchType =>
    contains(state?.type, [SearchType.Keyword, SearchType.SimilarTo]) // don't return legacy types
      ? state.type
      : SearchType.Keyword
)

// Types are different between `useSelector()` and `yield select()`
export const getSearchTypeById = createSelector(
  getResultsSearchDetails,
  byId,
  (state: SearchSet): SearchType =>
    contains(state?.type, [SearchType.Keyword, SearchType.SimilarTo]) // don't return legacy types
      ? state.type
      : SearchType.Keyword
)

export const getSort = createSelector(
  getResultsSearchDetails,
  (state: SearchSet): SortOrder => state?.sort || emptyObject
)

export const getSearchResultContent = createSelector(
  getResultsSearchDetails,
  (state: SearchSet): SearchResultHttpContent<TableResult[]> => state.results
)

export const getSuccessfulSearchResults = createSelector(
  getSearchResultContent,
  (state: SearchResultHttpContent<TableResult[]>): TableResult[] => {
    switch (state.type) {
      case HttpStatus.Success:
      case SearchStatus.FromApplication:
        return state.data
      default:
        return []
    }
  }
)

export const isFetching = createSelector(
  getSearchResultContent,
  (state: SearchResultHttpContent<TableResult[]>): boolean => state.type === HttpStatus.Fetching
)

export const hasError = createSelector(
  getSearchResultContent,
  (state: SearchResultHttpContent<TableResult[]>): boolean => state.type === HttpStatus.Error
)

export const makeGetSearchResultByAppno = () =>
  createSelector(
    getSuccessfulSearchResults,
    getAppnoParam,
    (state: TableResult[] | null, appno: string): TableResult | null =>
      find(
        (result: TableResult) => result.columns[Column.ApplicationNumber] === Number(appno),
        state ?? []
      ) ?? null
  )

export const makeGetSearchResultTransformedByAppno = () => {
  const getSearchResultByAppno = makeGetSearchResultByAppno()

  return createSelector(getSearchResultByAppno, (result) =>
    result !== null ? { ...result, columns: transformColumns(result.columns) } : null
  )
}

export const makeGetSearchResultColumnsByAppno = () => {
  const getSearchResultTransformedByAppno = makeGetSearchResultTransformedByAppno()

  return createSelector(getSearchResultTransformedByAppno, (result) =>
    result !== null ? result.columns : null
  )
}

const appnoOrPhrase = (phrase: string = emptyString) => {
  const possibleAppno = phrase.replace(/[^a-zA-Z0-9]/g, '')
  return /^[0-9]{7,8}$/.test(possibleAppno) ? possibleAppno : phrase
}

export const getSearchPhrase = createSelector(
  getResultsSearchDetails,
  (state: SearchSet): string => state?.phrase ?? emptyString
)

export const getSearchPhraseParsed = createSelector(
  getSearchPhrase,
  (phrase: SearchSet['phrase']): string => (phrase ? appnoOrPhrase(phrase) : emptyString)
)

// Types are different between `useSelector()` and `yield select()`
export const getSearchPhraseParsedById = createSelector(
  getSearchPhrase,
  byId,
  (phrase: SearchSet['phrase']): string => (phrase ? appnoOrPhrase(phrase) : emptyString)
)

const getSearchTime = createSelector(
  getResultsSearchDetails,
  (state: SearchSet): string => state.time
)

export const getSearchInfo = createSelector(
  getPaginationTotal,
  getSearchPhrase,
  getSearchTime,
  (paginationTotal: PaginationTotal, phrase: string | null, searchTime: string) => ({
    ...paginationTotal,
    phrase,
    time: searchTime,
  })
)

export const getSearchOrderingDirection = createSelector(
  getSort,
  (state: SortOrder): OrderDirection | null => state.direction
)

export const getSearchOrderingField = createSelector(
  getSort,
  (state: SortOrder): string | null => state.field
)

// Types are different between `useSelector()` and `yield select()`
export const getSearchOrderingsById = createSelector(
  getSearchOrderingDirection,
  getSearchOrderingField,
  byId,
  (direction: OrderDirection | null, field: string | null): SortOrderVariables[] =>
    field && direction ? [{ orderingDirection: direction, orderingType: field }] : emptyArray
)

export const getSearchOrderings = createSelector(
  getSearchOrderingDirection,
  getSearchOrderingField,
  (direction: OrderDirection | null, field: string | null): SortOrderVariables[] =>
    field && direction ? [{ orderingDirection: direction, orderingType: field }] : emptyArray
)

export const getSortByOptions = createSelector(
  getTableColumns,
  getSearchPhrase,
  (columns, phrase): ApplicationColumn[] =>
    [
      phrase.length > 0 ? { enumName: null, label: 'Relevance' } : null,
      ...[
        Column.PublicationDate,
        Column.FilingDate,
        Column.DispositionDate,
        Column.OfficeActions,
        Column.FinalRejections,
        Column.NonFinalRejections,
      ].map((column) => find(propEq('enumName', column), columns)),
    ].filter((column): column is ApplicationColumn => Boolean(column))
)

export const getSortedBy = createSelector(
  getSearchOrderingField,
  getSearchOrderingDirection,
  (field, direction) => (field && direction ? { field, direction } : null)
)

const defaultToEmpty = defaultTo({ enumName: '', label: '' })

export const getSortedByAsColumn = createSelector(
  getSearchOrderingField,
  getTableColumns,
  getSortByOptions,
  (field, columns, options): ApplicationColumn => {
    const empty = defaultToEmpty(head(options)) as ApplicationColumn

    return field ? find(propEq('enumName', field), columns) ?? empty : empty
  }
)

export const getSearchReportStatus = createSelector(
  getFilterSearchReport,
  (state: SearchSetReport | null) => (state ? state.status : SearchSetReportStatus.Clean)
)

export const getSearchReportUserDataKey = createSelector(
  getFilterSearchReport,
  (state: SearchSetReport | null) => (state ? state.userDataKey : null)
)

export const getSearchUid = createSelector(
  getResultsSearchDetails,
  (state: SearchSet): string => state.uid
)

export const getSearchView = createSelector(
  getPathname,
  (state) => state.session.permissions.table,
  (pathname, canAccessTable) => {
    const view = !matchSearchPath(pathname)
      ? SearchView.Table
      : pathname.split('/').slice(2).join('/') || undefined

    if (pathname.endsWith('/applications/table')) {
      return SearchView.IntelligenceApplications
    }

    if (view?.startsWith('dashboards/')) {
      return view
    }

    if (canAccessTable) {
      return view ?? SearchView.Card
    }

    return view === SearchView.Table || !view ? SearchView.Card : view
  }
)
