import { css } from 'emotion'
import * as React from 'react'

import { edgeOnly, ie11only, makeTransition, scrollbar } from '../styles'

type ScrollerProps = {
  className?: string
  children?: React.ReactNode
  dark?: boolean
  contentContainerClassName?: string
  scrollableX?: boolean
}

const isMac = typeof navigator !== 'undefined' && navigator.platform.startsWith('Mac')

const styles = {
  container: (scrollableX = false, dark = false) =>
    css(
      dark ? scrollbar.dark : scrollbar.light,
      {
        overflowX: scrollableX ? 'auto' : 'hidden',
        overflowY: 'auto',
        transition: makeTransition('height'),
      },
      !isMac && {
        '&:focus, &:hover': {
          visibility: 'visible',
        },
        visibility: 'hidden',
      }
    ),
  content: css(
    edgeOnly({
      position: 'relative',
      top: 0,
    }),
    ie11only({
      position: 'relative',
      top: 0,
    }),
    !isMac && {
      visibility: 'visible',
    }
  ),
}

const ScrollableOnHover = React.forwardRef<HTMLDivElement, ScrollerProps>(
  ({ children, className, contentContainerClassName, dark, scrollableX }, ref) => (
    <div
      className={css(styles.container(scrollableX, dark), className)}
      data-testid="scrollable-on-hover"
      ref={ref!}
    >
      <div className={css(styles.content, contentContainerClassName)}>{children}</div>
    </div>
  )
)

export { ScrollerProps }
export default ScrollableOnHover
