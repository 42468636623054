import { call, takeLatest } from 'redux-saga/effects'

import { sagas } from '../../auth'
import * as sessionSagas from '../../session/sagas'
import actions from '../actions'

export function* logout() {
  yield call(sagas.logout)
  yield call(sessionSagas.deleteSession)
}

function* watchLogout() {
  yield takeLatest(actions.logout().type, logout)
}

export default watchLogout
