import { Authorization } from '..'

const reduceAuthorizations = (authorizations: Authorization[]): { [key: string]: boolean } =>
  authorizations.reduce(
    (acc, perm) => ({
      ...acc,
      [perm.permission]: perm.succeeded,
    }),
    {}
  )

export default reduceAuthorizations
