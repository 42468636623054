import config from '@juristat/config'
import * as auth0 from 'auth0-js'

const auth0Config = {
  audience: 'https://juristat.com/',
  clientID: config.auth0ClientId,
  domain: config.auth0Domain,
  redirectUri: `${location.protocol}//${location.host}/return`,
  responseType: 'token id_token',
  scope: 'openid profile use:juristat',
}

const auth0Client = new auth0.WebAuth(auth0Config)
const auth0Authorize = auth0Client.authorize.bind(auth0Client)
const auth0ChangePassword = auth0Client.changePassword.bind(auth0Client)
const auth0CheckSession = auth0Client.checkSession.bind(auth0Client)
const auth0Login = auth0Client.login.bind(auth0Client)
const auth0Logout = auth0Client.logout.bind(auth0Client)
const auth0PasswordlessStart = auth0Client.passwordlessStart.bind(auth0Client)

export {
  auth0Authorize,
  auth0ChangePassword,
  auth0CheckSession,
  auth0Client,
  auth0Config,
  auth0Login,
  auth0Logout,
  auth0PasswordlessStart,
}
