import config from '@juristat/config'
import { useMachine } from '@xstate/react'
import decode from 'jwt-decode'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useInterval } from '../../../hooks/useInterval'
import { getPathname } from '../../router'
import actions from '../actions'
import {
  AccessTokenContext,
  Auth0ClientContext,
  AuthorizeContext,
  ResetPasswordContext,
} from '../contexts'
import { resetPasswordMachine } from '../machines'
import { getAccessTokenId } from '../selectors'
import { AuthProvider, AuthorizeResponse } from '../types'
import { auth0Client } from '../utils/auth0'

function useResetPassword() {
  const [current, send] = useMachine(resetPasswordMachine, {
    services: {
      reset: (_context, event) =>
        new Promise<void>((resolve, reject) => {
          try {
            auth0Client.changePassword(
              {
                connection: config.auth0JuristatAccountsConnection,
                email: event.email,
              },
              (error) => {
                if (error) {
                  return reject(error.error)
                }

                resolve()
              }
            )
          } catch (unknownErr) {
            const error = unknownErr as Error & { description?: string }
            reject(error.description ?? 'An error occurred resetting your password.')
          }
        }),
    },
  })
  const pathname = useSelector(getPathname)

  const resetPassword = useCallback(
    (email: string) => {
      send('FETCH', { email })
    },
    [send]
  )

  useEffect(() => {
    if (!current.matches('idle')) {
      send('RESET')
    }
  }, [pathname])

  return [current, resetPassword] as const
}

function noAuthNeeded(pathname: string) {
  return pathname.startsWith('/confirm-email') || pathname.startsWith('/signup')
}

const Auth0ContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [accessToken, setAccessToken] = useState<string>('')
  const [delay, setDelay] = useState<number | null>(null)
  const dispatch = useDispatch()
  const accessTokenId = useSelector(getAccessTokenId)
  const { pathname } = useLocation()

  const checkSession = useCallback(() => {
    if (noAuthNeeded(pathname)) {
      return
    }

    try {
      auth0Client.checkSession({}, (error, result: AuthorizeResponse) => {
        if (error) {
          return dispatch(actions.hydrateAccessTokenError(error.error))
        }

        const { accessToken, expiresIn, idToken } = result
        const claims = decode<WeakObject>(idToken) ?? {}

        setAccessToken(accessToken)
        setDelay((expiresIn - 60) * 1000) // subtract 1 min, convert to ms

        dispatch(actions.setAccessToken(accessToken, { expiresIn }))
        dispatch(actions.setClaims(claims))
      })
    } catch (unknownErr) {
      const error = unknownErr as Error & { description?: string; error?: string }
      dispatch(actions.hydrateAccessTokenError(error.error))
    }
  }, [dispatch, pathname])

  const authorize = useCallback(
    (connection: AuthProvider) => {
      if (noAuthNeeded(pathname)) {
        return
      }

      try {
        auth0Client.authorize({ connection })
      } catch (unknownErr) {
        const error = unknownErr as Error & { description?: string }
        dispatch(actions.error(error.description ?? 'Failed to authorize', { debug: error }))
      }
    },
    [dispatch, pathname]
  )

  useInterval(checkSession, delay)
  useEffect(checkSession, [accessTokenId])

  return (
    <Auth0ClientContext.Provider value={auth0Client}>
      <AuthorizeContext.Provider value={authorize}>
        <ResetPasswordContext.Provider value={useResetPassword()}>
          <AccessTokenContext.Provider value={accessToken}>{children}</AccessTokenContext.Provider>
        </ResetPasswordContext.Provider>
      </AuthorizeContext.Provider>
    </Auth0ClientContext.Provider>
  )
}

export default Auth0ContextProvider
