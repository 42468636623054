import { DataSource } from '@juristat/common/types'
import { isNilOrEmpty } from '@juristat/common/utils'
import { Func4, call, select } from 'redux-saga/effects'

import { graphqlApi, ppairApi } from '.'
import { Response } from '../../redux'
import { getPpairUser } from '../session/selectors'
import { PpairUser } from '../session/types'

type GetPrivateOrPublicResponse<Variables, DataResult> = {
  context: {
    [key: string]: Func4<
      Record<string, unknown>,
      Variables,
      (result: DataResult) => boolean,
      DataSource
    >
  }
  fn: string
} & ((
  apiQuery: Record<string, unknown>,
  variables: Variables,
  checkEmptyResult: (result: DataResult) => boolean,
  incomingDataSource?: DataSource
) => (DataResult & { dataSource: DataSource }) | Generator<any, any, any> | null)

export function* getPrivateOrPublicResponse<Variables, DataResult>(
  apiQuery: Record<string, unknown>,
  variables: Variables,
  checkEmptyResult: (result: DataResult) => boolean = isNilOrEmpty,
  incomingDataSource?: DataSource
): (DataResult & { dataSource: DataSource }) | Generator<any, any, any> | null {
  const hasPpair = (yield select(getPpairUser)) === PpairUser.True
  const dataSource =
    incomingDataSource ?? (hasPpair ? DataSource.PrivatePair : DataSource.PublicPair)
  const apiMethod = dataSource === DataSource.PrivatePair ? ppairApi : graphqlApi
  const result: Response<GraphQLResponse<DataResult>> = yield call<
    Record<string, unknown>,
    Variables
  >(apiMethod, apiQuery, variables)

  if (result.ok) {
    const { data } = yield call([result, 'json'])
    const noResult = checkEmptyResult(data)
    if (!incomingDataSource && dataSource !== DataSource.PublicPair && noResult) {
      return yield call(
        getPrivateOrPublicResponse as GetPrivateOrPublicResponse<Variables, DataResult>,
        apiQuery,
        variables,
        checkEmptyResult,
        DataSource.PublicPair
      )
    }

    return noResult ? null : { ...data, dataSource }
  }

  throw new Error('API returned a non-200 response')
}

export { GetPrivateOrPublicResponse }
export default getPrivateOrPublicResponse
