import { RadioOption } from '@juristat/common/types'
import { css, cx } from 'emotion'
import * as React from 'react'

import Radio from './Radio'

type RadioGroupProps<T> = {
  className?: string
  classNameSelected?: string
  horizontal?: boolean
  options: Array<RadioOption<T>>
  selectedOption: T
  setSelectedOption: (value: T) => void
  tw?: string
}

const styles = {
  group: (horizontal: boolean) =>
    css({
      '& label:not(:last-child)': {
        [horizontal ? 'marginRight' : 'marginBottom']: horizontal ? 20 : 6,
      },
      display: 'flex',
      flexDirection: horizontal ? 'row' : 'column',
    }),
}

const RadioGroup = <T,>({
  className,
  classNameSelected,
  horizontal = false,
  options,
  selectedOption,
  setSelectedOption,
  tw,
}: RadioGroupProps<T>) => (
  <div className={cx(styles.group(horizontal), className, tw)} role="radiogroup">
    {options.map((option) => (
      <Radio
        key={String(option.value)}
        {...option}
        classNameSelected={classNameSelected}
        select={setSelectedOption}
        selected={selectedOption === option.value}
      />
    ))}
  </div>
)

export default RadioGroup
