import * as LogRocket from 'logrocket'
import { takeLatest } from 'redux-saga/effects'

import sessionActions from '../../session/actions'
import { SetAction } from '../../session/types'
import getNameFromPayload from '../utils/getNameFromPayload'

const addLogRocket = ({ meta, payload }: SetAction) => {
  LogRocket.identify(meta!.uuid, {
    email: payload!.username,
    name: getNameFromPayload(payload!) ?? '',
  })
}

function* watchAddLogRocket() {
  yield takeLatest(sessionActions.set().type, addLogRocket)
}

export default watchAddLogRocket
