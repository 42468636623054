import { css, cx } from 'emotion'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ActionButton from '../../../components/ActionButton'
import { ModalBackground } from '../../../components/Modal'
import { colors, textStyles } from '../../../styles'
import reduxActions from '../actions'
import { useDialogue } from '../hooks'
import { getDialogue } from '../selectors'
import { DialogueType } from '../types'

const styles = {
  buttonDestructive: css({
    backgroundColor: colors.pastelRed,
  }),
  buttonWarning: css({
    backgroundColor: colors.schoolbusYellow,
    color: colors.charcoalGray2,
  }),
  footerContainer: css({
    '& > :not(:last-child)': {
      marginRight: 10,
    },
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 10,
  }),
  main: css(textStyles.charcoalGrayNormal14, {
    '& > div[data-empty="true"]': {
      visibility: 'hidden',
    },
    backgroundColor: colors.white,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 200,
    justifyContent: 'space-between',
    padding: '30px 20px 20px 20px',
    width: 320,
  }),
}

// TODO: Remove redux but, template manager requires redux
const Dialogue: React.FC = () => {
  const dispatch = useDispatch()
  const reduxState = useSelector(getDialogue)
  const [dialogue, , actions] = useDialogue()

  const confirm = useCallback(() => {
    dispatch(reduxActions.confirm())
    actions.confirm()
  }, [actions.confirm])

  const dismiss = useCallback(() => {
    dispatch(reduxActions.dismiss())
    actions.dismiss()
  }, [actions.dismiss])

  if (dialogue.matches('closed') && reduxState === null) {
    return null
  }

  const {
    actionText = 'Confirm',
    text = 'Are you sure?',
    type = DialogueType.Normal,
  } = reduxState ?? dialogue.context

  return (
    <ModalBackground closeModal={dismiss}>
      <div className={styles.main} role="dialog">
        {text.split('\n').map((t) => (
          <div data-empty={t === '{empty}'} key={t}>
            {t}
          </div>
        ))}
        <div className={styles.footerContainer}>
          <ActionButton action={dismiss} secondary text="Cancel" />
          <ActionButton
            action={confirm}
            className={cx({
              [styles.buttonDestructive]: type === DialogueType.Destructive,
              [styles.buttonWarning]: type === DialogueType.Warning,
            })}
            primary
            text={actionText}
          />
        </div>
      </div>
    </ModalBackground>
  )
}

export default Dialogue
