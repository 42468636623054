import { css } from 'emotion'
import * as React from 'react'
import * as ReactDOM from 'react-dom'

import { colors, zIndex } from '../../styles'

type ModalBackgroundProps = Partial<{
  children: React.ReactNode
  closeModal: () => void
  onClose: () => void
}>

const styles = {
  main: css({
    alignItems: 'flex-start',
    background: colors.charcoalGray2alpha30,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    overflowY: 'auto',
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: zIndex.modalBackground,
  }),
  modalContainer: css({
    boxShadow: `0 4px 20px 0 ${colors.charcoalGray2alpha30}`,
    marginBottom: 70,
    marginTop: 70,
    zIndex: zIndex.modal,
  }),
}

class ModalBackground extends React.Component<ModalBackgroundProps> {
  closeOnEscape = (event: KeyboardEvent) => {
    const { closeModal } = this.props
    if (event.key === 'Escape' && typeof closeModal === 'function') {
      closeModal()
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.closeOnEscape, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.closeOnEscape, false)

    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {
    const { children, closeModal } = this.props

    return ReactDOM.createPortal(
      <div
        className={styles.main}
        data-testid="modal-background"
        onMouseDown={() => closeModal?.()}
      >
        <div className={styles.modalContainer} onMouseDown={(event) => event.stopPropagation()}>
          {children}
        </div>
      </div>,
      document.querySelector('body') as HTMLBodyElement
    )
  }
}

export default ModalBackground
