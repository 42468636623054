import makeAction from '../../redux/utils/makeAction'
import {
  ClearAction,
  FeatureModalSetAction,
  SaveSettingAction,
  SaveSettingErrorAction,
  SetAction,
  SetPermissionsAction,
  SetPpairUser,
  SetSettingAction,
} from './types'

const clear = makeAction<ClearAction>('session/CLEAR')
const featureModalSet = makeAction<FeatureModalSetAction>('session/featureModal/SET')
const saveSetting = makeAction<SaveSettingAction>('session/SETTING_SAVE')
const saveSettingError = makeAction<SaveSettingErrorAction>('session/SETTING_SAVE_ERROR')
const set = makeAction<SetAction>('session/SET')
const setPermissions = makeAction<SetPermissionsAction>('session/SET_PERMISSIONS')
const setPpairUser = makeAction<SetPpairUser>('session/SET_PPAIR')
const setSetting = makeAction<SetSettingAction>('session/SETTING_SET')

export default {
  clear,
  featureModalSet,
  saveSetting,
  saveSettingError,
  set,
  setPermissions,
  setPpairUser,
  setSetting,
}
