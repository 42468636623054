import { all, call, put, takeLatest } from 'redux-saga/effects'

import { appApi } from '../../api'
import { HttpStatus } from '../../http/types'
import actions from '../actions'
import { GetPortfoliosResponse, PortfolioSummaryData } from '../types'
import getCombinedAppCount from './getCombinedAppCount'

function* fetchMyPortfolios() {
  yield put(actions.setMyPortfolios({ type: HttpStatus.Fetching }))

  try {
    const response = yield call(appApi, '/reports')

    if (response.ok) {
      const { reports }: GetPortfoliosResponse = yield call([response, 'json'])

      const numApps: number[] = yield all(
        reports.map(({ searches }) =>
          call(
            getCombinedAppCount,
            searches.map(({ dataApiQuery: { dataApiUid } }) => dataApiUid)
          )
        )
      )

      const porfolioSummaries: PortfolioSummaryData[] = reports.map(
        ({ id, name, searches }, index) => ({
          id,
          numApps: numApps[index] || 0,
          numEntities: searches.length,
          title: name,
        })
      )

      yield put(actions.setMyPortfolios({ data: porfolioSummaries, type: HttpStatus.Success }))
    } else {
      yield put(
        actions.setMyPortfolios({ type: HttpStatus.Error, message: 'Error fetching portfolios' })
      )
    }
  } catch {
    yield put(
      actions.setMyPortfolios({ type: HttpStatus.Error, message: 'Error fetching portfolios' })
    )
  }
}

function* watchFetchMyPortfolios() {
  yield takeLatest(actions.fetchMyPortfolios().type, fetchMyPortfolios)
}

export { fetchMyPortfolios }
export default watchFetchMyPortfolios
