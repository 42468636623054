import { useContext, useEffect } from 'react'

import { useSession } from '../session/hooks'
import { IntercomActionsContext } from './contexts'

type PendoApi = {
  initialize(options: {
    account: { [key: string]: string }
    visitor: { [key: string]: string }
  }): void
}

declare global {
  interface Window {
    pendo?: PendoApi
  }
}

export function useIntercomActions() {
  const context = useContext(IntercomActionsContext)

  if (context === undefined) {
    throw new Error('useIntercomActions must be used within a IntercomActionsContext')
  }

  return context
}

export function usePendo() {
  const { name, username, uuid } = useSession()

  useEffect(
    function initializePendo() {
      if (!username) {
        return
      }

      window.pendo?.initialize({
        visitor: {
          id: uuid,
          email: username,
          firstName: name.first,
          lastName: name.last,
        },

        account: {
          id: uuid,
        },
      })
    },
    [name, username, uuid]
  )
}
