import { css } from 'emotion'
import React from 'react'

import { colorsOld, emptyMessage } from '../styles'

export enum StatusType {
  Error,
  Info,
  Warning,
}

type StatusProps = {
  text: string
  status: StatusType
}

const styles = {
  shared: css(emptyMessage, {
    fontSize: '1.2em',
    padding: '1em',
    width: '100%',
  }),
  [StatusType.Error]: css({
    background: colorsOld.merlotAlpha2,
    color: colorsOld.merlot,
  }),
  [StatusType.Info]: css({
    background: colorsOld.blumineAlpha2,
    color: colorsOld.blumine,
  }),
  [StatusType.Warning]: css({
    background: colorsOld.pacifikaAlpha2,
    color: colorsOld.pacifika,
  }),
}

const Status = ({ status, text }: StatusProps): JSX.Element => (
  <div className={css(styles.shared, styles[status])}>{text}</div>
)

export default Status
