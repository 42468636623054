import { css } from 'emotion'
import React, { Suspense } from 'react'

import Footer from './Footer'
import JuristatLoader from './JuristatLoader'

type PageContainerProps = Pick<React.HTMLAttributes<HTMLDivElement>, 'className' | 'id'> & {
  children: React.ReactNode
  tw?: boolean
}

const twstyles = {
  container: 'flex flex-col flex-grow overflow-x-hidden overflow-y-scroll w-0',
  contentContainer: 'flex-[1_0_auto] mb-10',
  footer: 'flex-[0_0_auto]',
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowX: 'hidden',
    overflowY: 'scroll',
    width: 0,
  }),
  contentContainer: css({
    flex: '1 0 auto',
    marginBottom: 40,
  }),
  footer: css({
    flex: '0 0 auto',
  }),
}

const PageContainer: React.FC<PageContainerProps> = ({
  children,
  className,
  tw = false,
  ...props
}) => (
  <div
    className={tw ? `${twstyles.container} ${className}` : css(styles.container, className)}
    id="page-container"
    {...props}
  >
    <div className={styles.contentContainer}>
      <Suspense fallback={<JuristatLoader />}>{children}</Suspense>
    </div>
    <Footer className={styles.footer} />
  </div>
)

export default PageContainer
