import { LocalStorageKey } from '@juristat/common/types'
import config from '@juristat/config'
import { call, put } from 'redux-saga/effects'

import actions from '../../session/actions'

export const performDelete = async () =>
  fetch(`${config.accountsUrl}/session`, { credentials: 'include', method: 'delete' })

function* deleteSession() {
  const result = yield call(performDelete)

  if (result.ok) {
    yield put(actions.clear())

    try {
      window?.localStorage?.removeItem?.(LocalStorageKey.Username)
    } catch (e) {
      /* No handling required */
    }
  }

  return result.ok
}

export default deleteSession
