import { css } from 'emotion'
import { contains } from 'ramda'
import React from 'react'

import { colors } from '../../../styles'
import { useMutation } from '../../api'
import { Bell, Spinner } from '../../icons'
import { useAlerts, useBulkAlertsLoading } from '../hooks'
import * as subscribeQuery from '../queries/subscribeToAlert.graphql'
import * as unsubscribeQuery from '../queries/unsubscribeFromAlert.graphql'

type AlertIconProps = {
  appno: number
}

const styles = {
  disabled: css({
    fill: colors.cloudyBlue,
  }),
  enabled: css({
    fill: colors.appleGreen,
  }),
  main: css({
    cursor: 'pointer',
    height: 12,
    width: 12,
  }),
  spinner: css({
    cursor: 'default',
    stroke: colors.cloudyBlue,
  }),
}

const AlertIcon: React.FC<AlertIconProps> = ({ appno }) => {
  const alerts = useAlerts()
  const bulkAlertsLoading = useBulkAlertsLoading()
  const enabled = contains(appno, alerts.matches('success') ? alerts.context.data : [])
  const [mutate, state] = useMutation(
    enabled ? unsubscribeQuery : subscribeQuery,
    { appnos: [appno] },
    { refetchQueries: ['alerts'] }
  )
  const loading =
    bulkAlertsLoading ||
    state.matches('loading') ||
    alerts.matches('loading') ||
    alerts.matches({ success: 'refetching' })

  return loading ? (
    <Spinner
      className={css(styles.main, styles.spinner)}
      title={`${alerts.matches('loading') ? 'Fetching' : 'Toggling'} alerts`}
    />
  ) : (
    <Bell
      className={css(styles.main, enabled ? styles.enabled : styles.disabled)}
      onClick={() => mutate()}
      title={`${enabled ? 'Disable' : 'Enable'} alert`}
    />
  )
}

export default AlertIcon
