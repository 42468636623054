import { isNilOrEmpty } from '@juristat/common/utils'
import { find, pathOr, propEq, reject } from 'ramda'
import { ParametricSelector, createSelector } from 'reselect'

import { AppState } from '../../../../../redux'
import { HttpContent, HttpStatus } from '../../../../http/types'
import { getResultsSearchView } from '../../../selectors/getSearch'
import { SearchSetViewState } from '../../../types'
import { ApplicationColumn, DynamicColumn, TableViewState } from '../types'

const getTable = createSelector(
  getResultsSearchView,
  (state: SearchSetViewState): TableViewState => state.table
)

export const getTableColumns = createSelector(getTable, ({ columns }) => columns)

export const getColumnVariables = createSelector(getTableColumns, (state: ApplicationColumn[]) =>
  state.map(({ enumName }) => ({ column: enumName }))
)

export const getSelectedColumnNames = createSelector(
  getTable,
  ({ selectedColumns }) => selectedColumns
)

const findColumnByName =
  <P extends 'column' | 'enumName', T extends Record<P, string>>(prop: P, columns: T[]) =>
  (name: string) =>
    find(propEq(prop, name), columns)

export const getSelectedColumnVariables = createSelector(
  getColumnVariables,
  getSelectedColumnNames,
  (columns, selected) => selected.map(findColumnByName('column', columns))
)

export const getSelectedTableColumns = createSelector(
  getTableColumns,
  getSelectedColumnNames,
  (columns, selected) =>
    reject(
      isNilOrEmpty,
      selected.map(findColumnByName('enumName', columns))
    ) as unknown as ApplicationColumn[]
)

const pathOrNotAsked = pathOr<HttpContent<number>>({ type: HttpStatus.NotAsked })

type ColumnAndKey = Partial<{
  column: DynamicColumn
  key: number
}>

const getColumnAndKey: ParametricSelector<AppState, ColumnAndKey, ColumnAndKey> = (
  _,
  { column, key }
) => ({
  column,
  key,
})

const getDynamicColumnContent = createSelector(
  getTable,
  getColumnAndKey,
  (state: TableViewState, { column, key }: ColumnAndKey): HttpContent<number> =>
    pathOrNotAsked([column ?? '', key ?? ''], state.dynamicValues)
)

export { getDynamicColumnContent }
