import { css } from 'emotion'
import * as React from 'react'

import { colorsOld } from '../../styles'

type FooterItemProps = {
  children: React.ReactNode
}

const styles = {
  main: css({
    color: colorsOld.gray,
    marginLeft: '1.8em',
  }),
}

const FooterItem = ({ children }: FooterItemProps) => <li className={styles.main}>{children}</li>

export default FooterItem
