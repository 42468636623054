import { put, takeLatest } from 'redux-saga/effects'

import sessionActions from '../../session/actions'
import { SettingTypes } from '../../session/types'
import userSearchesActions from '../../userSearches/actions'
import { SearchHistoryUpdateFavoriteAction } from '../../userSearches/types'

function* maybeUpdateDefaultView(action: SearchHistoryUpdateFavoriteAction) {
  if (action.meta && action.meta.asDefault === true) {
    const { userDataKey: value } = action.payload!

    yield put(
      sessionActions.saveSetting({
        dataKey: 'default',
        type: SettingTypes.DefaultView,
        value,
      })
    )
  }
}

function* watchUpdateFavorite() {
  yield takeLatest(userSearchesActions.updateFavorite().type, maybeUpdateDefaultView)
}

export { maybeUpdateDefaultView, watchUpdateFavorite }
