import React from 'react'

import TextArea from '../../../components/TextArea'

type WorkflowAutomationTextAreaProps = {
  background: 'white' | 'blue'
  disabled?: boolean
  handleOnBlur?: () => void
  handleOnKeyDown?: (keyCode: number, event: React.KeyboardEvent<HTMLInputElement>) => void
  handleOnPaste?: (value: string) => void
  handleOnTextChange: (value: string) => void
  placeholder: string
  value: string
}

const styles = {
  input:
    'text-blue-gray font-montserrat text-s font-bold placeholder:text-blue-gray placeholder:font-montserrat placeholder:placeholder:text-s placeholder:font-bold placeholder:opacity-60 bg-white focus:bg-white border-transparent focus:border-transparent transition-[background-color,border-color] duration-300 ease-in-out p-[10px]',
  invalid: '[&_>_svg]:fill-current ml-1 mr-2 items-center text-[#bd4535] flex text-[11px]',
}

const WorkflowAutomationTextArea = ({
  background,
  handleOnTextChange,
  value,
  ...props
}: WorkflowAutomationTextAreaProps) => {
  return (
    <TextArea
      className={`${styles.input} ${
        background === 'blue' ? '!text-blue-gray !bg-[#f5f9ff] !font-bold focus:!bg-[#f5f9ff]' : ''
      }`}
      handleTextChange={handleOnTextChange}
      text={value}
      tw={true}
      {...props}
    />
  )
}

export default WorkflowAutomationTextArea
