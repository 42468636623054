import { LocalStorageKey } from '@juristat/common/types'
import { css } from 'emotion'
import { compose, prop, sortBy, toLower, values } from 'ramda'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Accordion from '../../../../components/Accordion'
import ScrollableOnHover from '../../../../components/ScrollableOnHover'
import { useLocalStorage } from '../../../../hooks'
import { colors } from '../../../../styles'
import { MyAlerts } from '../../../alerts'
import searchActions from '../../../search/actions'
import { getSearchReportStatus, getSearchReportUserDataKey } from '../../../search/selectors'
import { SearchSetReportStatus, SetReportAction } from '../../../search/types'
import CheckPermission from '../../../session/components/CheckPermission'
import { Access } from '../../../session/types'
import userSearchesActions from '../../actions'
import { getSearchHistoryFavorites } from '../../selectors'
import { SearchHistoryData } from '../../types'
import formatSavedSearch from '../../utils/formatSavedSearch'
import ExampleSearches from './ExampleSearches'
import SavedSearch from './SavedSearch'

const styles = {
  container: css({
    backgroundColor: colors.charcoalGray3,
    borderRadius: 4,
    marginBottom: 10,
    padding: 10,
  }),
  main: css({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: '0 10px',
  }),
}

const SavedSearchesList: React.FC = () => {
  const dispatch = useDispatch()
  const savedSearches = useSelector(getSearchHistoryFavorites)
  const selectedItem = useSelector(getSearchReportUserDataKey)
  const selectedItemStatus = useSelector(getSearchReportStatus)

  const [examplesHidden, setExamplesHidden] = useLocalStorage(LocalStorageKey.ExampleSavedSearches)

  const setReport = useCallback(
    (payload: SetReportAction['payload']) => dispatch(searchActions.setReport(payload)),
    [dispatch]
  )

  useEffect(() => {
    dispatch(userSearchesActions.get({ onlySaved: true }))
  }, [dispatch])

  const searches = values(savedSearches || {}).map((search: SearchHistoryData) => ({
    ...search,
    name: search.name ?? formatSavedSearch(search.timestamp),
  }))

  const searchesSorted = sortBy(compose(toLower, prop('name')), searches)

  return (
    <>
      <ScrollableOnHover className={styles.main} contentContainerClassName={styles.container} dark>
        <MyAlerts performSearch={setReport} />
        <CheckPermission
          canAccess={Access.PlatformSavedSearchAndHistory}
          renderWithAccess={() => (
            <>
              {searchesSorted.map((saved) => (
                <SavedSearch
                  active={saved.userDataKey === selectedItem}
                  canUndo={
                    saved.userDataKey === selectedItem &&
                    selectedItemStatus === SearchSetReportStatus.Dirty
                  }
                  key={saved.userDataKey}
                  item={saved}
                  performSearch={setReport}
                />
              ))}
            </>
          )}
        />
        <Accordion
          handleClick={() => setExamplesHidden((state) => (state === 'false' ? 'true' : 'false'))}
          headerClassName={css({ padding: '10px 0' })}
          label="Example Saved Searches"
          open={examplesHidden !== 'false'}
        >
          <ExampleSearches performSearch={setReport} />
        </Accordion>
      </ScrollableOnHover>
    </>
  )
}

export default SavedSearchesList
