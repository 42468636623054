import React, { Children } from 'react'

import Menu from '../../../components/Menu'
import { Angle } from '../../icons'

type WorkflowAutomationAssignmentFilterContainerProps = {
  children: JSX.Element[]
  width: number
}

const styles = {
  icon: '[&:hover_>_svg]:fill-azure hover:border-azure hover:opacity-70 [&_>_svg]:fill-silver2 [&_>_svg]:transition-[fill] [&_>_svg]:duration-300 [&_>_svg]:ease-in-out [&_>_svg]:ml-[2px] items-center bg-white border border-transparent rounded-[50%] flex h-[30px] ml-5 p-[5px] transition-[border-color,opacity] duration-300 ease-in-out w-[30px]',
  menu: '[&_>_div_>_div_>_div]:shadow-[0_3px_8px_rgba(0,_0,_0,_0.25)] [&_>_div_>_div_>_div]:!ml-0 [&_>_div_>_div_>_div:not(:first-of-type)]:mt-[10px] [&_>_div_>_div]:bg-transparent [&_>_div_>_div]:-bottom-[65px] [&_>_div_>_div]:shadow-[unset] [&_>_div_>_div]:left-[70px]',
  ok: 'flex flex-col',
  open: '[&_>_svg]:fill-azure border-azure',
}

export default function WorkflowAutomationAssignmentFilterContainer({
  children,
  width,
}: WorkflowAutomationAssignmentFilterContainerProps) {
  const items = Children.toArray(children) as JSX.Element[]
  const options = items.map((_, value) => ({ label: '', value }))

  return (
    <>
      {width < 760 ? (
        <span className={styles.menu}>
          <Menu
            align="left"
            direction="up"
            handleClick={(value) => {
              console.log(value)
            }}
            nested
            options={options}
            title={(open) => (
              <div className={`${styles.icon} ${open ? styles.open : ''}`} title="OAR Filters">
                <Angle height={20} title="" transform="rotate(90)" width={20} />
              </div>
            )}
            width={150}
          >
            {({ value }) => items[value]}
          </Menu>
        </span>
      ) : (
        children
      )}
    </>
  )
}
