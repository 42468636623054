import { css } from 'emotion'
import * as React from 'react'

import { Triangle } from '../modules/icons'
import { colors, makeTransition } from '../styles'

export enum Direction {
  Down = 'down',
  Up = 'up',
  Left = 'left',
  Right = 'right',
}

type ChevronProps = {
  className?: string
  color?: string
  direction: Direction
  handleClick?: () => void
  title: string
  tw?: boolean
}

const styles = {
  main: {
    fill: colors.charcoalGray,
    height: 5,
    transition: makeTransition('transform', 0.3),
    width: 10,
  },
}

const Chevron = ({
  className,
  direction,
  handleClick,
  title,
  tw = false,
}: ChevronProps): JSX.Element => (
  <Triangle
    className={
      tw
        ? `fill-charcoal h-[5px] transition-[transform] duration-300 ease-in-out ${className}`
        : css(styles.main, className)
    }
    direction={direction}
    onClick={handleClick}
    title={title}
  />
)

export default Chevron
