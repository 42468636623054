import { css } from 'emotion'
import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { colors, textStyles, twTextStyles } from '../styles'
import Button from './Button'

type ButtonGroupProps<T> = {
  classNameButton?: string
  classNameButtonActive?: string
  classNameContainer?: string
  buttons: Array<{
    value: T
    label: string
  }>
  tw?: boolean
  update: (selection: T | null) => void
  value: T | null
}

const styles = {
  button: css(textStyles.paleGray2Normal14, {
    backgroundColor: 'transparent',
    border: `1px solid ${colors.dark}`,
    display: 'inline-block',
    padding: '6px 10px',
  }),
  buttonActive: css({
    backgroundColor: colors.greenyBlue,
    border: `1px solid ${colors.greenyBlue}`,
  }),
  container: css({
    '& > :first-child': {
      borderRadius: '4px 0 0 4px',
    },
    '& > :last-child': {
      borderRadius: '0 4px 4px 0',
    },
    '& button + button': {
      marginLeft: -1,
    },
  }),
}

const twstyles = {
  button: `${twTextStyles.paleGray2Normal14} bg-transparent border border-dark inline-block px-[10px] py-[6px] !text-sm`,
  buttonActive: 'bg-greeny-blue border border-greeny-blue',
  container:
    '[&_>_:first-child]:rounded-tl [&_>_:first-child]:rounded-tr-none [&_>_:first-child]:rounded-br-none [&_>_:first-child]:rounded-bl [&_>_:last-child]:rounded-tl-none [&_>_:last-child]:rounded-tr [&_>_:last-child]:rounded-br [&_>_:last-child]:rounded-bl-none [&_button_+_button]:-ml-[1px]',
}

const ButtonGroup = <T,>({
  classNameButton,
  classNameButtonActive,
  classNameContainer,
  tw = false,
  update,
  value,
  buttons,
}: ButtonGroupProps<T>) => {
  return (
    <div
      className={
        tw
          ? `${twstyles.container} ${classNameContainer}`
          : css(styles.container, classNameContainer)
      }
    >
      {buttons.map(({ label, value: buttonValue }) => (
        <Button
          aria-pressed={value === buttonValue}
          handleClick={() => update(value === buttonValue ? null : buttonValue)}
          className={
            !tw
              ? css(
                  styles.button,
                  classNameButton,
                  value === buttonValue && styles.buttonActive,
                  value === buttonValue && classNameButtonActive
                )
              : undefined
          }
          key={String(buttonValue)}
          tw={
            tw
              ? twMerge(
                  twstyles.button,
                  classNameButton,
                  value === buttonValue ? `${twstyles.buttonActive} ${classNameButtonActive}` : ''
                )
              : undefined
          }
        >
          {label}
        </Button>
      ))}
    </div>
  )
}

export default ButtonGroup
