export default function () {
  const baseUrl = 'https://search.mock.local'

  return {
    accountsApiId: process.env.ACCOUNTS_API_ID || 'test-accounts-api-id',

    accountsApiKey: process.env.ACCOUNTS_API_KEY || 'test-accounts-api-key',

    accountsUrl: process.env.ACCOUNTS_URL || 'https://accounts.mock.local',

    appApiAwsRegion: process.env.APP_API_AWS_REGION || 'us-west-2',

    appApiPort: Number(process.env.APP_API_PORT) || 5463,

    appApiUrl: 'https://app-api.mock.local',

    appUrl: `${baseUrl}/search`,

    auth0Audience: process.env.AUTH0_AUDIENCE || 'https://juristat.com/',

    auth0ClientId: process.env.AUTH0_CLIENT_ID || '34tatDJn1Y376cAGv9WlTKusgq1wAemL',

    auth0Domain: process.env.AUTH0_DOMAIN || 'login.juristat.com',

    auth0Host: process.env.AUTH0_HOST || 'https://login.juristat.com',

    auth0Issuer: process.env.AUTH0_ISSUER || [
      'https://login.juristat.com/',
      'https://juristat.auth0.com/',
    ],

    auth0JuristatAccountsConnection:
      process.env.AUTH0_JURISTAT_ACCOUNTS_CONNECTION || 'accounts-db-username-prod',

    auth0Namespace: process.env.AUTH0_NAMESPACE || 'https://juristat.com/',

    auth0ProviderName: process.env.AUTH0_PROVIDER_NAME || 'login.juristat.com',

    authServiceUrl: 'https://auth-api.juristat.com',

    baseUrl,

    credentialsApiUrl: process.env.CREDENTIALS_API_URL || '',

    emptySearchUid: 'eyJ0eXBlIjoiQXBwbGljYXRpb25TZXQiLCJpbmZvIjp7ImFyZ3MiOnt9fX0=',

    intercomAccessToken: '',

    jiraApiToken:
      process.env.ATLASSIAN_TOKEN ||
      'ATATT3xFfGF0dmdBpnE7XmMM8M_UTexvcXY3-a_0SOW-2wp_diAAhQMplXA1Xg9yAFDO1cbrMIamFRZbIuEQDbMJ22Bjd8V5ihG82UUmTVaDhSNJlvjZT5pLQSlGuN3GlM3Gm7UMpD7u1a8Qf9V-i5t3S7cDupaa8IkYKscQMb3-YNvFh0IAt7Y=52F5089F',

    jiraAssigneeId: process.env.ATLASSIAN_ASSIGNEE || '712020:6e09cb5b-741c-46db-a19d-f3e23cb73422',

    jiraAuthEmail: process.env.ATLASSIAN_AUTH_EMAIL || 'michelle.evans@juristat.com',

    jiraEmail: process.env.ATLASSIAN_EMAIL || 'michelle.evans@juristat.com',

    jiraHost: process.env.ATLASSIAN_HOST || 'https://juristatv2.atlassian.net/',

    jurilog: {
      errorSnsArn: '',
      eventSnsArn: '',
      production: false,
    },

    legacyAppUrl: 'https://app.mock.local',

    ppairUrl: 'http://ppair.mock.local',

    production: false,

    appApiS3Bucket: process.env.APP_API_S3_BUCKET || '',

    sendGridApiKey: 'SendGrid API Key',

    smartshellUrl: 'https://juristat-trials.turbopatent.us/officeaction/shell',

    supportEmail: 'noreply@juristat.com',

    slackBotToken: 'slack-bot-token',

    slackChannels: {
      analystRoom: 'slack-analyst-room-id',
      idsNotificationRoom: 'slack-ids-notification-room-id',
      oarPriorityReportRoom: 'slack-oar-priority-report-room-id',
      oarsRoom: 'slack-oars-room-id',
    },

    netDocs: {
      bakerHostetler: {
        groupId: process.env.ND_BH_GROUP_ID || '',
        groupName: process.env.ND_BH_GROUP_NAME || '',
        authUrl: process.env.ND_BH_AUTH_URL || '',
        apiUrl: process.env.ND_BH_API_URL || '',
        clientId: process.env.ND_BH_CLIENT_ID || '',
        clientSecret: process.env.ND_BH_CLIENT_SECRET || '',
      },
    },

    iManage: {
      '00000000-0000-1000-0000-000000000000': {
        groupName: 'arent-fox' as const,
        authUrl: process.env.IM_AF_AUTH_URL || '',
        apiUrl: process.env.IM_AF_API_URL || '',
        clientId: process.env.IM_AF_CLIENT_ID || '',
        clientSecret: process.env.IM_AF_CLIENT_SECRET || '',
        clientUser: process.env.IM_AF_CLIENT_USER || '',
        clientPass: process.env.IM_AF_CLIENT_PASS || '',
        clientCustomerId: process.env.IM_AF_CLIENT_CUSTOMER_ID || '',
        clientLibraryName: process.env.IM_AF_CLIENT_LIBRARY_NAME || '',
      },
      '10000000-0000-1000-0000-000000000000': {
        groupName: 'arent-fox' as const,
        authUrl: process.env.IM_AF_AUTH_URL || '',
        apiUrl: process.env.IM_AF_API_URL || '',
        clientId: process.env.IM_AF_CLIENT_ID || '',
        clientSecret: process.env.IM_AF_CLIENT_SECRET || '',
        clientUser: process.env.IM_AF_CLIENT_USER || '',
        clientPass: process.env.IM_AF_CLIENT_PASS || '',
        clientCustomerId: process.env.IM_AF_CLIENT_CUSTOMER_ID || '',
        clientLibraryName: process.env.IM_AF_CLIENT_LIBRARY_NAME || '',
      },
    },

    usptoData: {
      consoleUrl: process.env.CONSOLE_API_URL || 'https://api.mock.local/v2/graphql/api',
      graphqlUrl: 'http://api.mock.local/graphql',
      legacyUrl: 'http://api.mock.local',
      s3PublicApplicationRole: 's3:public:application:role',
      db: {
        host: process.env.USPTO_DB_HOST || '',
        port: process.env.USPTO_DB_PORT || '',
        name: process.env.USPTO_DB_NAME || '',
        username: process.env.USPTO_DB_USERNAME || '',
        password: process.env.USPTO_DB_PASSWORD || '',
      },
    },

    packetKmsKeyId: process.env.PACKET_UPLOADER_KMS_KEY || '',
    packetUploaderRole: process.env.PACKET_UPLOADER_ROLE || '',

    websocketUrl: 'wss://mock.local',
  }
}
