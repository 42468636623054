import { css } from 'emotion'
import * as React from 'react'

import Button from '../../../../components/Button'
import Chevron, { Direction } from '../../../../components/Chevron'
import { colors } from '../../../../styles'

type GalleryNavProps = {
  className?: string
  disabled: boolean
  onClick: React.MouseEventHandler
  direction: Direction
}

const styles = {
  main: css({
    backgroundColor: colors.paleGray,
    height: 33,
    width: 33,
  }),
}

const GalleryNav: React.FC<GalleryNavProps> = ({ className, direction, disabled, onClick }) => (
  <Button active={!disabled} className={css(styles.main, className)} onClick={onClick}>
    <Chevron direction={direction} title={direction === Direction.Right ? 'Next' : 'Previous'} />
  </Button>
)

export default GalleryNav
