import config from '@juristat/config'
import { call, put, takeEvery } from 'redux-saga/effects'

import { api } from '../../api'
import actions from '../../session/actions'
import { SaveSettingAction } from '../../session/types'

function* saveSetting(action: SaveSettingAction) {
  const { dataKey, type, value } = action.payload!
  if (!dataKey || !type || value === undefined) {
    return
  }

  const urlBase = `${config.accountsUrl}/user/data/`
  const url = `${urlBase}${type}/${dataKey}`
  const options = {
    body: JSON.stringify(value),
    credentials: 'include',
    method: 'put',
    url,
  }
  try {
    const response = yield call(api, url, options)
    if (response.ok) {
      yield put(actions.setSetting(action.payload!))
    } else {
      yield put(actions.saveSettingError())
    }
  } catch {
    yield put(actions.saveSettingError())
  }
}

function* watchSaveSetting() {
  yield takeEvery(actions.saveSetting().type, saveSetting)
}

export { saveSetting }
export default watchSaveSetting
