import { css } from 'emotion'
import React from 'react'
import { SpringConfig, animated, config, useSpring } from 'react-spring'

import { colors } from '../styles'

type ProgressBarProps = {
  color?: string
  progress: number
  springConfig?: SpringConfig
}

const styles = {
  main: css({
    backgroundColor: colors.paleGray,
    borderRadius: 4,
    height: 6,
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  }),
  meter: (color: string) =>
    css({
      backgroundColor: color,
      borderBottomRightRadius: 4,
      borderTopRightRadius: 4,
      height: 6,
      left: 0,
      position: 'absolute',
      top: 0,
    }),
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  color = colors.appleGreen,
  progress,
  springConfig = config.wobbly,
}) => {
  const props = useSpring({
    config: springConfig,
    from: { width: '0%' },
    width: `${progress * 100}%`,
  })

  return (
    <div className={styles.main} title={`${(progress * 100).toFixed(0)}%`}>
      <animated.div
        className={styles.meter(color)}
        data-testid="progress-indicator"
        style={props}
      />
    </div>
  )
}

export default ProgressBar
