import { combineReducers } from 'redux'

import {
  Actions,
  Entity,
  Feature,
  PermissionsState,
  PpairUser,
  Prices,
  SessionGroup,
  Settings,
} from './types'

const entity = (state: Entity | null = null, action: Actions) => {
  switch (action.type) {
    case 'session/CLEAR':
      return null
    case 'session/SET':
      return action.payload!.entity
    default:
      return state
  }
}

const focusFeature = (state: Feature = Feature.Other, action: Actions) => {
  switch (action.type) {
    case 'session/featureModal/SET':
      return action.payload!.focusFeature ?? state
    default:
      return state
  }
}

const open = (state = false, action: Actions) => {
  switch (action.type) {
    case 'session/featureModal/SET':
      return action.payload!.open
    default:
      return state
  }
}

const featureModal = combineReducers({
  focusFeature,
  open,
})

const first = (state = '', action: Actions) => {
  switch (action.type) {
    case 'session/CLEAR':
      return ''
    case 'session/SET':
      return action.payload!.firstName
    default:
      return state
  }
}

const group = (state: SessionGroup = {}, action: Actions) => {
  switch (action.type) {
    case 'session/CLEAR':
      return {}
    case 'session/SET':
      return {
        id: action.meta!.groupId,
        name: action.meta!.groupName,
      }
    default:
      return state
  }
}

const last = (state = '', action: Actions) => {
  switch (action.type) {
    case 'session/CLEAR':
      return ''
    case 'session/SET':
      return action.payload!.lastName
    default:
      return state
  }
}

const initialPermissions: PermissionsState = {
  accountTools: false,
  alpha: false,
  analystMetrics: false,
  api: false,
  assignmentSheet: false,
  bi: false,
  charts: false,
  confidentialData: false,
  customerPpairSettings: false,
  clientConfig: false,
  clientUsageReport: false,
  drafting: false,
  editOarTimes: false,
  examiner: false,
  expertSearch: false,
  expertSearchExport: false,
  exportAnalytics: false,
  freeHealthDashboard: false,
  groupAdmin: false,
  groupOwner: false,
  humanTasks: false,
  ids: false,
  idsAssignmentSheet: false,
  idsViewer: false,
  oarBuild: false,
  oarBuildQuality: false,
  oarReview: false,
  oarReviewQuality: false,
  oarSender: false,
  oarTech: false,
  oarViewer: false,
  oneOTwoReports: false,
  personal: false,
  platformExaminerAuTcFilters: false,
  platformFirmAssigneeFilters: false,
  platformOaRceCountFilters: false,
  platformPatentFamilyComponent: false,
  platformPremiumSorts: false,
  platformRegCustNumFilters: false,
  platformSavedSearchAndHistory: false,
  platformSearchScopes: false,
  platformThreePaneView: false,
  ppair: false,
  premiumWork: false,
  rejectionBasisFilter: false,
  rejections: false,
  selfAnalystMetrics: false,
  smartshell: false,
  staffSettings: false,
  table: false,
}

const permissions = (state: PermissionsState = initialPermissions, action: Actions) => {
  switch (action.type) {
    case 'session/CLEAR':
      return initialPermissions
    case 'session/SET_PERMISSIONS':
      return action.payload! || state
    default:
      return state
  }
}

const ppairUser = (state: PpairUser = PpairUser.Unknown, action: Actions) => {
  switch (action.type) {
    case 'session/SET_PPAIR':
      return action.payload!
    default:
      return state
  }
}

const initialPrices: Prices = {
  competitor: null,
  examiner: null,
  marketing: null,
}

const prices = (state: Prices = initialPrices, action: Actions) => {
  switch (action.type) {
    case 'session/CLEAR':
      return initialPrices
    case 'session/SET':
      return action.payload!.prices
    default:
      return state
  }
}

const settings = (state: Settings = {}, action: Actions) => {
  switch (action.type) {
    case 'session/CLEAR':
      return {}
    case 'session/SET':
      return action.payload!.settings
    case 'session/SETTING_SET':
      return { ...state, [action.payload!.type]: action.payload!.value }
    default:
      return state
  }
}

const username = (state = '', action: Actions) => {
  switch (action.type) {
    case 'session/CLEAR':
      return ''
    case 'session/SET':
      return action.payload!.username
    default:
      return state
  }
}

const uuid = (state = '', action: Actions) => {
  switch (action.type) {
    case 'session/CLEAR':
      return ''
    case 'session/SET':
      return action.meta!.uuid
    default:
      return state
  }
}

const name = combineReducers({ first, last })

const reducer = combineReducers({
  entity,
  featureModal,
  group,
  name,
  permissions,
  ppairUser,
  prices,
  settings,
  username,
  uuid,
})

export default reducer
