import { css } from 'emotion'
import * as React from 'react'

import { colorsOld } from '../../styles'
import Copyright from '../Copyright'
import FooterLinks from './FooterLinks'

type FooterProps = {
  className?: string
}

const styles = {
  main: css({
    // Give some room inside search result container
    ['[data-component="Pagination"] + &']: {
      margin: '0 1em',
    },
    alignItems: 'center',
    borderTop: `1px solid ${colorsOld.blackAlpha1}`,
    display: 'flex',
    flexDirection: 'column',
    padding: '1em',
  }),
}

const Footer = ({ className }: FooterProps) => (
  <footer className={css(styles.main, className)}>
    <Copyright />
    <FooterLinks />
  </footer>
)

export default Footer
