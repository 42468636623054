import { Permission } from '@juristat/common/accounts'
import { activeGroupClaimKey } from '@juristat/common/constants'
import config from '@juristat/config'
import { stringify } from 'qs'
import { call, put, takeLatest } from 'redux-saga/effects'

import { reduceAuthorizations } from '../../accounts'
import { api } from '../../api'
import { SetClaimsAction, actions as authActions } from '../../auth'
import {
  NotificationTypes,
  makeNotification,
  actions as notificationActions,
} from '../../notification'
import actions from '../../session/actions'

type Params = {
  groupId: string
}

const createQuery = ({ groupId }: Params) =>
  stringify(
    {
      assertions: [
        'CAN_ACCESS_CHARTS',
        'CAN_ACCESS_EXPERT_SEARCH',
        'CAN_ACCESS_TABLE',
        `CAN_ADD_GROUP_OWNERS:${groupId}`,
        `CAN_ADMINISTER_PPAIR_CUST_NO_WHITELIST:${groupId}`,
        'CAN_BUY_EXAMINER_REPORT:1',
        'CAN_CREATE_GROUP_TOKENS',
        'CAN_DO_HUMAN_TASKS',
        'CAN_EDIT_TIMES',
        'CAN_EXPORT_EXPERT_SEARCH_RESULTS',
        'CAN_EXPORT_ANALYTICS',
        'CAN_HAVE_TURBOPATENT_REJECTION_LINK',
        'CAN_OAR_BUILD',
        'CAN_OAR_REVIEW',
        'CAN_OAR_TECH',
        'CAN_OVERRIDE_PRIMARY_ENTITY',
        'CAN_SEND_OARS',
        'CAN_SKIP_PLAN_SELECT',
        'CAN_UPLOAD_IDS',
        'CAN_USE_ALPHA_JURISTAT',
        'CAN_USE_API_CONSOLE',
        'CAN_USE_EPIPHANY',
        'CAN_USE_EXAMINER_AU_TC_FILTERS',
        'CAN_USE_FIRM_ASSIGNEE_FILTERS',
        'CAN_USE_FREE_HEALTH_DASHBOARD',
        'CAN_USE_JURISTAT',
        'CAN_USE_OA_RCE_COUNTS_FILTERS',
        'CAN_USE_PERSONAL',
        'CAN_USE_PLATFORM_SAVED_SEARCH_AND_HISTORY',
        'CAN_USE_PLATFORM_PREMIUM_SORTS',
        'CAN_USE_PLATFORM_SEARCH_SCOPES',
        'CAN_USE_PLATFORM_THREE_PANE_VIEW',
        'CAN_USE_PPAIR_UI',
        'CAN_USE_PROJECT_Z',
        'CAN_USE_REG_CUST_NUM_FILTERS',
        'CAN_USE_SAVED_SEARCH_AND_HISTORY',
        'CAN_USE_TOOLS',
        'CAN_VIEW_CONFIDENTIAL_DATA',
        'CAN_USE_REJECTION_BASIS_FILTER',
        'CAN_VIEW_ANALYST_METRICS',
        'CAN_VIEW_OAR_ASSIGNMENT_SHEET',
        'CAN_VIEW_OAR_BUILD_QUALITY',
        'CAN_VIEW_OAR_CLIENT_CONFIGURATION',
        'CAN_VIEW_OAR_CLIENT_USAGE_REPORT',
        'CAN_VIEW_OAR_REVIEW_QUALITY',
        'CAN_VIEW_OARS',
        'CAN_VIEW_ONEOTWO_REPORTS',
        'CAN_VIEW_IDS',
        'CAN_VIEW_IDS_ASSIGNMENT_SHEET',
        'CAN_VIEW_PLATFORM_PATENT_FAMILY_COMPONENT',
        'CAN_VIEW_PREMIUM_WORK',
        'CAN_VIEW_REJECTION_RESPONSE',
        'CAN_VIEW_SELF_ANALYST_METRICS',
        'CAN_VIEW_STAFF_SETTINGS',
      ],
    },
    { addQueryPrefix: true, arrayFormat: 'repeat' }
  )

function* getPermissions(action: SetClaimsAction) {
  const groupId: string = action.payload![activeGroupClaimKey]
  const query = createQuery({ groupId })
  const url = `${config.accountsUrl}/user/access/query${query}`
  const options = {
    method: 'get',
    url,
  }
  const response = yield call(api, url, options)
  if (response.ok) {
    const { authorizations } = yield call([response, 'json'])
    const permissions = reduceAuthorizations(authorizations)
    const payload = {
      accountTools: permissions[Permission.CanUseTools] || false,
      alpha: permissions[Permission.Alpha] || false,
      analystMetrics: permissions[Permission.AnalystMetrics] || false,
      api: permissions[Permission.Api] || false,
      assignmentSheet: permissions[Permission.AssignmentSheet] || false,
      bi: permissions[Permission.Bi] || false,
      charts: permissions[Permission.Charts] || false,
      clientConfig: permissions[Permission.CanViewOarClientConfig] || false,
      clientUsageReport: permissions[Permission.CanViewOarClientUsageReport] || false,
      confidentialData: permissions[Permission.CanViewConfidentialData] || false,
      customerPpairSettings: permissions[Permission.CanAdministerPpairWhitelist] || false,
      drafting: permissions[Permission.Drafting] || false,
      editOarTimes: permissions[Permission.CanEditTimes] || false,
      examiner: permissions[Permission.Examiner] || false,
      expertSearch: permissions[Permission.ExpertSearch] || false,
      expertSearchExport: permissions[Permission.ExpertSearchExport] || false,
      exportAnalytics: permissions[Permission.ExportAnalytics] || false,
      freeHealthDashboard: permissions[Permission.FreeHealthDashboard] || false,
      groupAdmin: permissions[Permission.GroupAdmin] || false,
      groupOwner: permissions[Permission.GroupOwner] || false,
      humanTasks: permissions[Permission.HumanTasks] || false,
      ids: permissions[Permission.Idss] || false,
      idsAssignmentSheet: permissions[Permission.IdsAssignmentSheet] || false,
      idsViewer: permissions[Permission.IdsViewer] || false,
      oarBuild: permissions[Permission.CanOarBuild] || false,
      oarBuildQuality: permissions[Permission.OarBuildQuality] || false,
      oarReview: permissions[Permission.CanOarReview] || false,
      oarReviewQuality: permissions[Permission.OarReviewQuality] || false,
      oarSender: permissions[Permission.OarSender] || false,
      oarTech: permissions[Permission.CanOarTech] || false,
      oarViewer: permissions[Permission.OarViewer] || false,
      oneOTwoReports: permissions[Permission.OneOTwoReports] || false,
      personal: permissions[Permission.Personal] || false,
      platformExaminerAuTcFilters: permissions[Permission.PlatformExaminerAuTcFilters] || false,
      platformFirmAssigneeFilters: permissions[Permission.PlatformFirmAssigneeFilters] || false,
      platformOaRceCountFilters: permissions[Permission.PlatformOaRceCountFilters] || false,
      platformPatentFamilyComponent: permissions[Permission.PlatformPatentFamilyComponent] || false,
      platformPremiumSorts: permissions[Permission.PlatformPremiumSorts] || false,
      platformRegCustNumFilters: permissions[Permission.PlatformRegCustNumFilters] || false,
      platformSavedSearchAndHistory: permissions[Permission.PlatformSavedSearchAndHistory] || false,
      platformSearchScopes: permissions[Permission.PlatformSearchScopes] || false,
      platformThreePaneView: permissions[Permission.PlatformThreePaneView] || false,
      ppair: permissions[Permission.PrivatePair] || false,
      premiumWork: permissions[Permission.PremiumWork] || false,
      rejectionBasisFilter: permissions[Permission.RejectionBasisFilter] || false,
      rejections: permissions[Permission.RejectionView] || false,
      selfAnalystMetrics: permissions[Permission.SelfAnalystMetrics] || false,
      smartshell: permissions[Permission.TurboPatentRejectionLink] || false,
      staffSettings: permissions[Permission.StaffSettings] || false,
      table: permissions[Permission.Table] || false,
    }
    yield put(actions.setPermissions(payload))
  } else {
    yield put(
      notificationActions.push(
        makeNotification({
          message: 'Failed to fetch permissions',
          type: NotificationTypes.Error,
        })
      )
    )
  }
}

export { createQuery, getPermissions }
export default function* watchGetPermissions() {
  yield takeLatest(authActions.setClaims().type, getPermissions)
}
