import { css } from 'emotion'
import * as React from 'react'

import { Code } from '../../modules/icons'
import { colorsOld } from '../../styles'
import FooterItem from './FooterItem'

const styles = {
  icon: css({
    fill: colorsOld.danube,
    height: '1em',
    marginBottom: '-.25em',
    width: '1em',
  }),
}

const ProductInfo = () => (
  <FooterItem>
    <Code className={styles.icon} title={process.env.PRODUCT_INFO} />
  </FooterItem>
)

export default ProductInfo
