import { css, cx } from 'emotion'
import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'

import Checkbox from '../../../components/Checkbox'
import Menu from '../../../components/Menu'
import { colors } from '../../../styles'
import { More } from '../../icons'
import { useBulkAlerts, useToggleModal } from '../hooks'

type BulkAlertsMenuProps<T> = Omit<ReturnType<typeof useBulkAlerts>, 'loading'> & {
  className?: string
  items: T[]
  results: T[]
  setItems: React.Dispatch<React.SetStateAction<T[]>>
  type: 'appnos' | 'saved-searches'
}

const styles = {
  checkbox: css({
    '& > div > button > svg': {
      marginLeft: 0,
    },
    alignItems: 'center',
    display: 'flex',
    height: 32,
  }),
  more: css({
    '&:hover': {
      '& > svg': {
        fill: colors.azure,
      },
      borderColor: colors.azure,
      opacity: 0.7,
    },
    '& > svg': {
      fill: colors.silver2,
      transition: 'fill 300ms ease-in-out',
    },
    border: `1px solid ${colors.silver2}`,
    borderRadius: '50%',
    display: 'flex',
    marginLeft: 15,
    padding: 5,
    transition: 'border-color 300ms ease-in-out, opacity 300ms ease-in-out',
  }),
  open: css({
    '& > svg': {
      fill: colors.azure,
    },
    borderColor: colors.azure,
  }),
  placeholder: css({
    height: 40,
  }),
}

const BulkAlertsMenu = <T,>({
  bulk,
  className,
  items,
  results,
  setItems,
  subscribe,
  type,
  unsubscribe,
}: BulkAlertsMenuProps<T>) => {
  const [debouncedBulkSubscribe] = useDebouncedCallback(() => bulk.subscribe(), 250)
  const bulkSubscribe = useRef(false)
  const toggle = useToggleModal()
  const history = useHistory()

  useEffect(() => {
    if (bulkSubscribe.current) {
      debouncedBulkSubscribe()

      bulkSubscribe.current = false
    }
  }, [debouncedBulkSubscribe, results])

  if (results.length === 0) {
    return <div className={styles.placeholder} />
  }

  const hidden = type === 'saved-searches'
  const state =
    items.length === results.length ? 'checked' : items.length > 0 ? 'partial' : 'unchecked'

  return (
    <div className={css(styles.checkbox, className)}>
      <Checkbox
        accent="blue"
        checked={false}
        handleClick={() => setItems(results.length === items.length ? [] : results)}
        label={`${state === 'checked' ? 'Des' : 'S'}elect all`}
        renderLabel={() => null}
        state={state}
      />
      <Menu
        align="left"
        handleClick={(value) => {
          switch (value) {
            case 'all':
              return setItems(results)
            case 'none':
              return setItems([])
          }
        }}
        options={[
          { disabled: state === 'checked', label: 'Select All', value: 'all' },
          { disabled: state === 'unchecked', label: 'Select None', value: 'none' },
        ]}
        title=""
        width={150}
      />
      <Menu
        align="left"
        handleClick={(value) => {
          switch (value) {
            case 'selected':
              return type === 'saved-searches' ? bulk.subscribe() : subscribe({ appnos: items })
            case 'all-on-page':
              setItems(results)

              if (type === 'saved-searches') {
                bulkSubscribe.current = true

                return
              }

              return subscribe({ appnos: results })
            case 'all-results':
              return bulk.subscribe()
            case 'disable':
              return unsubscribe({ appnos: items })
            case 'manage-recipients':
              return toggle()
            case 'alerts-configuration':
              history.push('/alerts-configuration')
              return
          }
        }}
        nested
        options={[
          {
            disabled: items.length === 0,
            label: `Add selected ${type === 'saved-searches' ? 'searches' : 'apps'} to alerts`,
            value: 'selected',
          },
          { label: 'Select all on page and add to alerts', value: 'all-on-page' },
          {
            hidden,
            label: `Add ${bulk.message} apps to alerts`,
            value: 'all-results',
          },
          {
            hidden,
            disabled: items.length === 0,
            label: 'Manage alert recipients for selected apps',
            value: 'manage-recipients',
          },
          {
            hidden,
            disabled: items.length === 0,
            label: 'Disable alerts for selected apps',
            value: 'disable',
          },
          {
            label: 'Go to alerts configuration page',
            value: 'alerts-configuration',
          },
        ]}
        title={(open) => (
          <div className={cx(styles.more, { [styles.open]: open })} title="Alert actions">
            <More title="" transform="rotate(90)" />
          </div>
        )}
        width={300}
      />
    </div>
  )
}

export default BulkAlertsMenu
