import Downshift, { ControllerStateAndHelpers, DownshiftProps } from 'downshift'
import { css, cx } from 'emotion'
import { __, contains, flatten, values } from 'ramda'
import React, { useCallback, useState } from 'react'
import { animated, config, useSpring, useTransition } from 'react-spring'

import { useFuzzySort, usePortal } from '../hooks'
import { buttonStyles, colors, textStyles, zIndex } from '../styles'
import Button from './Button'
import Chevron, { Direction } from './Chevron'
import ConditionalWrapper from './ConditionalWrapper'
import SearchTextInput from './SearchTextInput'

type MenuProps<T> = {
  align?: 'left' | 'right'
  children?: (
    props: Pick<
      ControllerStateAndHelpers<T>,
      'getItemProps' | 'highlightedIndex' | 'isOpen' | 'toggleMenu'
    > &
      Option<T> & {
        className: string
        index: number
      }
  ) => JSX.Element
  direction?: 'down' | 'up'
  disabled?: boolean
  dropdownCss?: string
  startsWith?: boolean
  handleClick: (value: T) => void
  maxHeight?: number
  nested?: boolean
  optionCss?: string
  options: Options<T> | Array<Option<T>>
  portal?: { id: string; x: number; y: number }
  searchable?: boolean | string
  title: React.ReactNode | RenderTitle
  variant?: 'normal' | 'outline' | 'solid'
  width?: 150 | 200 | 250 | 300 | 350
} & Omit<DownshiftProps<T>, 'children'>

type Option<T> = {
  disabled?: boolean
  hidden?: boolean
  label: string
  value: T
}

type Options<T> = {
  [key: string]: Array<Option<T>>
}

type RenderTitle = (open: boolean) => React.ReactNode

type Render<T> = (
  props: Pick<
    ControllerStateAndHelpers<T>,
    'getItemProps' | 'highlightedIndex' | 'isOpen' | 'toggleMenu'
  > & {
    className: string
    options: Array<Option<T>>
    parentIndex?: number
  }
) => JSX.Element[]

const styles = {
  button: css(textStyles.azureNormal13, {
    '&:disabled': {
      opacity: 0.6,
    },
  }),
  container: css({
    position: 'relative',
  }),
  icon: css({
    fill: 'currentColor',
    height: 9,
    marginLeft: 11,
    width: 9,
  }),
  menu: ({
    align,
    direction,
    nested,
    variant,
    width,
  }: Required<Pick<MenuProps<unknown>, 'align' | 'direction' | 'nested' | 'variant' | 'width'>>) =>
    css({
      backgroundColor: colors.white,
      borderRadius: 4,
      boxShadow: `0 4px 10px 0 ${colors.charcoalGray2alpha30}`,
      bottom: direction === 'up' ? 0 : 'inherit',
      left: align === 'left' ? (nested ? 40 : 0) : undefined,
      padding: '10px 0',
      position: 'absolute',
      right: align === 'right' ? (nested ? 40 : 0) : undefined,
      top: nested ? (direction === 'up' ? 'inherit' : 0) : variant !== 'normal' ? 40 : 20,
      width,
      zIndex: zIndex.select,
    }),
  option: css(textStyles.darkNormal13, {
    '&:hover:not(:disabled)': {
      backgroundColor: colors.cloudyBlueAlpha20,
    },
    '&:disabled': {
      color: colors.silver,
    },
    cursor: 'pointer',
    justifyContent: 'flex-start',
    padding: '6px 14px',
    transition: 'background-color 200ms ease-in-out',
    width: '100%',
  }),
  optionHeader: css(textStyles.charcoalGrayBold12),
  outline: css(buttonStyles.blueOutlineWithIcon, {
    height: 'inherit',
    padding: '7px 10px',
    textTransform: 'inherit',
  }),
  row: css({
    padding: '4px 14px',
  }),
  search: css({
    margin: 10,
    marginTop: 0,
  }),
  section: css({
    '&:not(:last-child)': {
      marginBottom: 20,
    },
  }),
  solid: css(buttonStyles.primaryBlue),
}

const hasTitles = (options: unknown): options is Options<unknown> => !Array.isArray(options)

const Menu = <T,>({
  align = 'right',
  startsWith = false,
  children,
  direction = 'down',
  disabled = false,
  dropdownCss = '',
  handleClick,
  maxHeight = Infinity,
  nested = false,
  optionCss = '',
  options,
  portal,
  searchable = false,
  title,
  variant = 'normal',
  width = 250,
  ...props
}: MenuProps<T>) => {
  const [downshiftOpen, setDownshiftOpen] = useState(props.defaultIsOpen ?? false)
  const [needle, setNeedle] = useState('')
  const [portalY, setPortalY] = useState(0)
  const transition = useTransition(downshiftOpen, {
    config: config.stiff,
    enter: { opacity: 1, transform: 'translateY(0)' },
    from: { opacity: 0, transform: `translateY(${direction === 'down' ? '-16px' : '16px'})` },
    leave: { opacity: 0, transform: `translateY(${direction === 'down' ? '-16px' : '16px'})` },
  })

  const target = usePortal(portal?.id ?? 'no-menu-portal')

  const haystack = (
    hasTitles(options) ? (flatten(values(options)) as Array<Option<T>>) : options
  ).filter((option) => !option.hidden)
  const results = useFuzzySort({
    startsWith,
    haystack,
    keys: ['label'],
    needle,
  })
  const sections = hasTitles(options) ? Object.keys(options).length : 0
  const height = Math.min(
    maxHeight,
    [
      results.length * 30, // available options
      20, // menu padding
      searchable ? 46 : 0, // if we have search input, include it
      sections * 25, // include height of each section title
      sections !== 0 ? (sections - 1) * 20 : 0, // include margin between each section
    ].reduce((acc, value) => acc + value, 0)
  )

  const heightProps = useSpring({
    to: [{ overflowY: 'hidden' }, { height }, { overflowY: 'auto' }],
  })

  const render: Render<T> = useCallback(
    ({ className, getItemProps, highlightedIndex, isOpen, options, parentIndex = 0, toggleMenu }) =>
      options.filter(contains(__, results)).map(
        ({ disabled, label, value }, index) =>
          children?.({
            className,
            disabled,
            getItemProps,
            highlightedIndex,
            index: parseInt(`${parentIndex}${index}`),
            isOpen,
            label,
            toggleMenu,
            value,
          }) ?? (
            <Button
              {...getItemProps({
                className,
                disabled,
                key: JSON.stringify(value),
                item: value,
                index,
                onClick: () => {
                  handleClick(value)
                  toggleMenu()
                },
              })}
            >
              {label}
            </Button>
          )
      ),
    [children, handleClick, results]
  )

  return (
    <Downshift
      stateReducer={(_, changes) => {
        if (changes.isOpen !== undefined) {
          setDownshiftOpen(changes.isOpen)
        }

        return changes
      }}
      {...props}
    >
      {({
        getItemProps,
        getMenuProps,
        highlightedIndex,
        isOpen,
        toggleMenu,
      }: ControllerStateAndHelpers<T>) => (
        <div className={css(styles.container, dropdownCss)}>
          <Button
            className={cx(styles.button, {
              [styles.outline]: variant === 'outline',
              [styles.solid]: variant === 'solid',
            })}
            disabled={disabled}
            onClick={(event) => {
              if (portal) {
                setPortalY(event.clientY)
              }

              toggleMenu()
            }}
          >
            {typeof title === 'function' ? title(downshiftOpen) : title}
            {typeof title === 'string' && (
              <Chevron
                className={styles.icon}
                direction={isOpen ? Direction.Up : Direction.Down}
                title={`${downshiftOpen ? 'Close' : 'Open'} menu`}
              />
            )}
          </Button>
          {transition(
            (props, item, { key }) =>
              item && (
                <ConditionalWrapper condition={Boolean(portal)} portal={target}>
                  <animated.div
                    {...getMenuProps({
                      className: styles.menu({ align, direction, nested, variant, width }),
                    })}
                    key={key}
                    style={{
                      ...props,
                      ...heightProps,
                      ...(portal ? { x: portal.x, y: portalY } : {}),
                    }}
                  >
                    {searchable ? (
                      <SearchTextInput
                        accent="blue"
                        className={styles.search}
                        handleOnTextChange={setNeedle}
                        placeholder={[
                          'Search',
                          typeof searchable === 'string' ? searchable : 'Dashboards',
                        ].join(' ')}
                        onClear={needle ? () => setNeedle('') : undefined}
                        text={needle}
                      />
                    ) : null}
                    {hasTitles(options)
                      ? Object.keys(options).map((section, parentIndex) =>
                          options[section].length > 0 ? (
                            <div className={styles.section} key={section}>
                              <h3 className={css(styles.row, styles.optionHeader)}>{section}</h3>
                              {render({
                                className: css(styles.option, optionCss),
                                getItemProps,
                                highlightedIndex,
                                isOpen,
                                options: options[section],
                                parentIndex,
                                toggleMenu,
                              })}
                            </div>
                          ) : null
                        )
                      : render({
                          className: css(styles.option, optionCss),
                          getItemProps,
                          highlightedIndex,
                          isOpen,
                          options,
                          toggleMenu,
                        })}
                  </animated.div>
                </ConditionalWrapper>
              )
          )}
        </div>
      )}
    </Downshift>
  )
}

export default Menu
