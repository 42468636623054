import {
  addDays,
  addMonths,
  endOfMonth,
  format,
  isSameDay,
  isSameMonth,
  startOfMonth,
  startOfWeek,
} from 'date-fns'
import Downshift, { DownshiftInterface } from 'downshift'
import { css, cx } from 'emotion'
import { any, filter, splitEvery } from 'ramda'
import React, { useState } from 'react'

import { colors, textStyles, zIndex } from '../styles'
import Button from './Button'
import Chevron, { Direction } from './Chevron'

type DatePickerCalendarProps = {
  date: Date | null
  dateFormat?: string
  isDayDisabled?: (value: Date) => boolean
  isOpen: boolean
  onChange: (value: Date | null) => void
}

const styles = {
  container: css({
    position: 'relative',
  }),
  day: css(textStyles.charcoalGrayNormal12, {
    '&:disabled': {
      opacity: 0.5,
    },
    '&:hover:enabled': {
      backgroundColor: colors.charcoalGray2alpha10,
    },
    borderRadius: 4,
    height: 28,
    margin: 2,
    position: 'relative',
    width: 28,
  }),
  dayCurrent: css({
    '&:hover:enabled': {
      backgroundColor: colors.azure,
    },
    backgroundColor: colors.azure,
    border: 'none',
    color: colors.white,
    fontWeight: 'bold',
  }),
  dayNotInMonth: css({
    visibility: 'hidden',
  }),
  dayToday: css({
    border: `1px solid ${colors.greenyBlue}`,
  }),
  dropdown: css({
    backgroundColor: colors.white,
    borderRadius: 4,
    boxShadow: `0 4px 20px 0 ${colors.charcoalGray2alpha30}`,
    flex: 1,
    overflow: 'hidden',
    padding: 20,
    position: 'absolute',
    zIndex: zIndex.splash,
  }),
  leftRightButton: css({
    '&:disabled': {
      opacity: 0.5,
    },
    '&:hover:enabled': {
      border: `1px solid ${colors.silver2}`,
      boxShadow: `0 2px 8px 0 ${colors.charcoalGray2alpha10}`,
    },
    backgroundColor: colors.white,
    borderRadius: 4,
    fill: colors.charcoalGray,
    height: 28,
    width: 28,
  }),
  title: css({
    alignItems: 'center',
    display: 'flex',
    fontWeight: 'bold',
    justifyContent: 'space-between',
  }),
  weekday: css(textStyles.charcoalGrayBold12, {
    alignItems: 'center',
    display: 'flex',
    height: 28,
    justifyContent: 'center',
    margin: 2,
    width: 28,
  }),
  weeks: css({
    display: 'flex',
    fontSize: '80%',
  }),
}

const getWeeks = (date: Date): Date[][] => {
  const start = startOfWeek(startOfMonth(date))

  return filter(
    (week: Date[]) => any((day: Date) => isSameMonth(date, day), week),
    splitEvery(
      7,
      Array.from({ length: 42 }).map((_, i) => addDays(start, i))
    )
  )
}

const getWeekdays = (date: Date) =>
  Array.from({ length: 7 }).map((_, i) => format(addDays(startOfWeek(date), i), 'eeeeee'))

const TypedDownshift: DownshiftInterface<Date | null> = Downshift

const DatePickerCalendar = ({
  date,
  dateFormat = 'MM/dd/yyyy',
  isDayDisabled,
  isOpen,
  onChange,
}: DatePickerCalendarProps) => {
  const [baseDate, setBaseDate] = useState(date ?? new Date())
  const weeks = getWeeks(baseDate)
  const today = new Date()
  const weekdays = getWeekdays(baseDate)

  return (
    <TypedDownshift
      isOpen={isOpen}
      itemToString={(item) => (item ? format(item, dateFormat) : '')}
      onChange={onChange}
      selectedItem={date}
    >
      {({ getItemProps, getMenuProps, selectedItem }) => (
        <div>
          <div {...getMenuProps({ className: styles.dropdown })}>
            <h2 className={styles.title}>
              <Button
                active={!isDayDisabled?.(startOfMonth(baseDate))}
                className={styles.leftRightButton}
                handleClick={() => setBaseDate((value) => addMonths(value, -1))}
              >
                <Chevron direction={Direction.Left} title="Previous" />
              </Button>
              {format(baseDate, 'MMMM yyyy')}
              <Button
                active={!isDayDisabled?.(endOfMonth(baseDate))}
                className={styles.leftRightButton}
                handleClick={() => setBaseDate((value) => addMonths(value, 1))}
              >
                <Chevron direction={Direction.Right} title="Next" />
              </Button>
            </h2>
            <div className={styles.weeks}>
              {weekdays.map((weekday) => (
                <div key={weekday} className={styles.weekday}>
                  {weekday}
                </div>
              ))}
            </div>
            {weeks.map((week, index) => (
              <div key={index} className={styles.weeks}>
                {week.map((day) => {
                  const isToday = isSameDay(day, today)
                  const isInMonth = isSameMonth(baseDate, day)
                  const isCurrent = selectedItem ? isSameDay(day, selectedItem) : false
                  const isDisabled = isDayDisabled?.(day) ?? false

                  return (
                    <Button
                      key={day.toString()}
                      {...getItemProps({
                        className: cx(styles.day, {
                          [styles.dayToday]: isToday,
                          [styles.dayNotInMonth]: !isInMonth,
                          [styles.dayCurrent]: isCurrent,
                        }),
                        item: day,
                      })}
                      active={!isDisabled}
                    >
                      {isInMonth ? format(day, 'dd') : null}
                    </Button>
                  )
                })}
              </div>
            ))}
          </div>
        </div>
      )}
    </TypedDownshift>
  )
}

export default DatePickerCalendar
