import { equals, indexOf, insert, pipe, reject } from 'ramda'

const moveToPosition = <T>(element: T, toIndex: number, array: T[]) => {
  const currIndex = indexOf(element, array)

  if (toIndex > currIndex) {
    toIndex--
  }

  return pipe(reject<T, 'array'>(equals(element)), insert(toIndex, element))(array)
}

export default moveToPosition
