import { Key } from '@juristat/common/types'
import { startCase } from '@juristat/common/utils'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import Chevron, { Direction } from '../../../components/Chevron'
import DatePicker from '../../../components/DatePicker'
import { InputHandle } from '../../../components/Input'
import Menu from '../../../components/Menu'
import TextInput from '../../../components/TextInput'
import { useEditableText } from '../../../hooks'
import { Close } from '../../icons'
import { usePortalAndDirection, useUpdateAction } from '../hooks'
import WorkflowAutomationHighlightableCell from './WorkflowAutomationHighlightableCell'

type WorkflowAutomationEditableCellProps = {
  className?: string
  column: string
  dropdownCss?: string
  disabled?: boolean
  id: string
  labelCss?: string
  menuWidth?: 150 | 200 | 250 | 300 | 350
  optionCss?: string
  startsWith?: boolean
}

type Format<T> = (value: T | undefined) => string | undefined

type Time = {
  hours: number
  minutes: number
}

type DateProps = {
  format?: Format<Date>
  options?: never
  type: 'date'
  rows: number
  value: Date | undefined
}

type SelectProps = {
  format?: Format<string>
  options: Array<{ label: string; value: string }>
  rows: number
  type: 'select'
  value: string | undefined
}

type SelectNullableProps = {
  format?: Format<string>
  options: Array<{ label: string; value: string }>
  rows: number
  type: 'select-nullable'
  value: string | undefined
}

type TextProps = {
  format?: Format<number | string>
  options?: never
  type: 'number' | 'text'
  value: number | string | undefined
}

type TimeProps = {
  format?: Format<Time>
  options?: never
  type: 'time'
  value: Time | undefined
}

type EditableProps = DateProps | SelectProps | SelectNullableProps | TextProps | TimeProps

const styles = {
  display: 'block h-full overflow-hidden pt-[15px] overflow-ellipsis whitespace-nowrap w-full',
  dropdown: {
    container:
      '[&_>_div_>_button]:text-blue-gray [&_>_div_>_button]:font-bold [&_>_div_>_button]:justify-between [&_>_div_>_button]:w-full items-center flex gap-[10px]',
    remove:
      'hover:opacity-70 cursor-pointer fill-red-500 transition-[opacity] duration-300 ease-in-out',
    label: 'max-w-[100px] overflow-hidden overflow-ellipsis whitespace-nowrap',
    up: '[&_>_div_>_button_+_div]:bottom-[30px]  [&_>_div_>_button_+_div]:top-[inherit]',
  },
  icon: '!fill-current h-[9px] ml-[11px] w-[9px]',
  input:
    'bg-[#fffdf2] border-[#4db1ed] rounded-none text-blue-gray font-roboto text-s h-full my-0 -m-[10px] !py-[6px] !px-[9px] w-[inherit] focus:bg-[#fffdf2] focus:border-[#4db1ed] focus:rounded-none focus:text-blue-gray focus:font-roboto focus:text-s focus:h-full focus:my-0 focus:-m-[10px] focus:!py-[6px] focus:!px-[9px] focus:w-[inherit]',
  picker: {
    container: 'h-full w-full [&_>_div]:h-full [&_>_div]:w-full',
    // haven't found a tailwind equivalent for this rule yet: '& [role="listbox"]': { bottom: 40 }
    up: 'bottom-10',
  },
}

const WorkflowAutomationEditableDateCell = ({
  column,
  disabled = false,
  format,
  id,
  rows,
  value,
}: WorkflowAutomationEditableCellProps & DateProps) => {
  const [text, actions] = useEditableText(value)
  const [ref, portal, direction] = usePortalAndDirection(column, 315, rows, 47)

  const update = useUpdateAction()
  const textInputRef = useRef<InputHandle>(null)

  useEffect(() => {
    if (text.matches('saving')) {
      update({ [column]: text.context.next }, id)
    }

    if (text.matches('editing')) {
      textInputRef.current?.focus()
    }
  }, [column, id, text, update])

  if (text.matches('idle')) {
    return (
      <div className={styles.display} onClick={actions.edit} ref={ref}>
        {format?.(text.context.previous)}
      </div>
    )
  }

  return (
    <div className={`${styles.picker.container} ${direction === 'up' ? styles.picker.up : ''}`}>
      <DatePicker
        date={text.context.next ?? null}
        disabled={disabled}
        inputProps={{ className: styles.input, readOnly: true }}
        onChange={(date) => {
          if (date) {
            actions.update(date)
            actions.save()
          }
        }}
        onOuterClick={actions.cancel}
        portal={portal}
        ref={textInputRef}
      />
    </div>
  )
}

const OarEditableSelectCell = ({
  className = '',
  column,
  disabled = false,
  dropdownCss,
  format,
  id,
  labelCss,
  optionCss = '',
  options,
  type,
  rows,
  value,
  menuWidth,
  startsWith = false,
}: WorkflowAutomationEditableCellProps & (SelectProps | SelectNullableProps)) => {
  const [selected, setSelected] = useState(value)
  const [ref, portal, direction] = usePortalAndDirection(column, options.length * 30, rows)

  const update = useUpdateAction()

  useEffect(() => {
    setSelected((state) => (state === value ? state : value))
  }, [value])

  return (
    <div
      className={`${styles.dropdown.container} ${
        direction === 'up' ? styles.dropdown.up : ''
      } ${className}`}
      ref={ref}
    >
      <Menu
        align="left"
        direction={direction}
        disabled={disabled}
        dropdownCss={dropdownCss}
        handleClick={(value) =>
          setSelected((state) => {
            if (state === value) {
              return state
            }

            update({ [column]: value }, id)

            return value
          })
        }
        maxHeight={320}
        optionCss={optionCss}
        options={options}
        portal={portal}
        searchable={startCase(column.replace(/([A-Z])/, (_, letter) => ` ${letter}`))}
        startsWith={startsWith}
        title={(isOpen) => (
          <>
            <span className={labelCss ? labelCss : styles.dropdown.label}>
              {format?.(selected ?? column) ??
                selected ??
                startCase(column.replace(/([A-Z])/, (_, letter) => ` ${letter}`))}
            </span>
            <Chevron
              className={styles.icon}
              direction={isOpen ? Direction.Up : Direction.Down}
              title={`${isOpen ? 'Close' : 'Open'} menu`}
              tw={true}
            />
          </>
        )}
        width={menuWidth}
      />
      {!disabled && type === 'select-nullable' && value ? (
        <Close
          className={styles.dropdown.remove}
          height={11}
          onClick={() => update({ [column]: null }, id)}
          title={`Clear ${startCase(column.replace(/([A-Z])/, (_, letter) => ` ${letter}`))}`}
          width={11}
        />
      ) : null}
    </div>
  )
}

const OarEditableStringCell = ({
  column,
  disabled = false,
  format,
  id,
  value,
}: WorkflowAutomationEditableCellProps & TextProps) => {
  const [text, actions] = useEditableText(value)
  const isNumber = typeof value == 'number'

  const update = useUpdateAction()
  const textInputRef = useRef<InputHandle>(null)

  useEffect(() => {
    if (text.matches('saving')) {
      update({ [column]: isNumber ? Number(text.context.next) : text.context.next }, id)
    }

    if (text.matches('editing')) {
      textInputRef.current?.focus()
    }
  }, [column, id, isNumber, text, update, value])

  if (text.matches('idle')) {
    return (
      <div className={styles.display} onClick={actions.edit}>
        {format?.(text.context.previous) ?? text.context.previous}
      </div>
    )
  }

  return (
    <TextInput
      className={styles.input}
      disabled={disabled}
      handleOnBlur={actions.save}
      handleOnKeyDown={(key) => {
        switch (key) {
          case Key.Escape:
            return actions.cancel()
          case Key.Enter:
            return actions.save()
        }
      }}
      handleOnTextChange={actions.update}
      ref={textInputRef}
      text={text.context.next as string | undefined}
    />
  )
}

const OarEditableTimeCell = ({
  column,
  format,
  id,
  value,
}: WorkflowAutomationEditableCellProps & TimeProps) => {
  const [text, actions] = useEditableText(value ? format?.(value) ?? '' : '')

  const update = useUpdateAction()
  const textInputRef = useRef<InputHandle>(null)

  const time = React.useMemo(() => {
    if (text.context.next?.includes(':')) {
      const [hours = 0, minutes = 0] = text.context.next.split(':').map(Number)

      return { hours, minutes }
    }

    const total = Number(text.context.next)
    const hours = Math.floor(total / 60)
    const minutes = total % 60

    return { hours, minutes }
  }, [text.context.next])

  const save = useCallback(() => {
    actions.update(format?.(time) ?? text.context.next ?? '')
    actions.save()
  }, [actions, format, text.context.next, time])

  useEffect(() => {
    if (text.matches('saving')) {
      update({ [column]: time.hours * 60 + time.minutes }, id)
    }

    if (text.matches('editing')) {
      textInputRef.current?.focus()
    }
  }, [column, id, text, time, update])

  if (text.matches('idle')) {
    return (
      <div className={styles.display} onClick={actions.edit}>
        {text.context.previous}
      </div>
    )
  }

  return (
    <TextInput
      className={styles.input}
      handleOnBlur={save}
      handleOnKeyDown={(key) => {
        switch (key) {
          case Key.Escape:
            return actions.cancel()
          case Key.Enter:
            return save()
        }
      }}
      handleOnTextChange={actions.update}
      ref={textInputRef}
      text={text.context.next}
    />
  )
}

const OarEditableCell = (props: WorkflowAutomationEditableCellProps & EditableProps) => {
  switch (props.type) {
    case 'date':
      return <WorkflowAutomationEditableDateCell {...props} />
    case 'number':
    case 'text':
      return <OarEditableStringCell {...props} />
    case 'select':
    case 'select-nullable':
      return <OarEditableSelectCell {...props} />
    case 'time':
      return <OarEditableTimeCell {...props} />
    default:
      return null
  }
}

const OarEditableCellWrapper = (props: WorkflowAutomationEditableCellProps & EditableProps) => {
  return (
    <WorkflowAutomationHighlightableCell column={props.column} id={props.id}>
      <OarEditableCell key={props.id} {...props} />
    </WorkflowAutomationHighlightableCell>
  )
}

export default OarEditableCellWrapper
