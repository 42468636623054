import { css } from 'emotion'
import { isEmpty } from 'ramda'
import React from 'react'
import { useDispatch } from 'react-redux'

import Accordion from '../../../components/Accordion'
import FetchingBars from '../../../components/FetchingBars'
import Status, { StatusType } from '../../../components/Status'
import { colors } from '../../../styles'
import HttpContentComponent from '../../http/components/HttpContentComponent'
import { Access } from '../../session/types'
import actions from '../actions'
import { useFilterState } from '../hooks'
import { AvailableFilter, AvailableReducer, Filter } from '../types'
import ActionButtons from './ActionButtons'
import ActiveFilterIcon from './ActiveFilterIcon'

type FilterState = Pick<ReturnType<typeof useFilterState>, 'active' | 'selected'>

type FilterContainerRenderProps = FilterState & {
  available?: NonNullable<AvailableReducer[keyof AvailableReducer]>
}

type FilterContainerProps = {
  buttons?: boolean
  canAccess?: Access
  filter: Filter
  maxHeight?: number
  render: (props: FilterContainerRenderProps) => JSX.Element
  skipHttpStatus?: boolean
  title: string
}

type RenderFilterProps = Pick<FilterContainerProps, 'filter' | 'render'> & FilterState

const styles = {
  accordion: css({
    backgroundColor: colors.charcoalGray3,
    borderRadius: 4,
    marginBottom: 2,
  }),
  accordionContent: css({
    margin: '8px 10px',
  }),
  accordionHeader: css({
    padding: '8px 10px',
  }),
  loader: css({
    marginBottom: 20,
  }),
}

const renderError = (message: string) => <Status text={message} status={StatusType.Error} />

const renderFilter =
  ({ filter, render, ...props }: RenderFilterProps) =>
  (data: AvailableReducer) => {
    return render({ ...props, available: data[filter as AvailableFilter] })
  }

const FilterContainer: React.FC<FilterContainerProps> = ({
  buttons = true,
  canAccess,
  filter,
  maxHeight = 750,
  render,
  skipHttpStatus,
  title,
  ...rest
}) => {
  const { active, content, selected, meta, open } = useFilterState(filter)

  const dispatch = useDispatch()
  const toggle = () => dispatch(actions.toggle(filter, meta))

  return (
    <Accordion
      className={styles.accordion}
      contentClassName={styles.accordionContent}
      headerClassName={styles.accordionHeader}
      label={title}
      handleClick={toggle}
      maxHeight={maxHeight}
      onlyRenderWhenOpen
      open={open}
      renderStatus={isEmpty(active) ? undefined : ActiveFilterIcon}
      renderContent={() => (
        <span>
          {skipHttpStatus === true ? (
            renderFilter({ active, filter, render, selected, ...rest })(content as AvailableReducer)
          ) : (
            <HttpContentComponent
              content={content}
              renderError={renderError}
              renderFetching={() => <FetchingBars className={styles.loader} dark quantity={4} />}
              renderSuccess={renderFilter({ active, filter, render, selected, ...rest })}
            />
          )}
          {buttons && <ActionButtons filter={filter} />}
        </span>
      )}
    />
  )
}

export { FilterContainerProps }
export default FilterContainer
