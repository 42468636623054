import Downshift, { DownshiftProps } from 'downshift'
import { css } from 'emotion'
import React from 'react'
import { animated, config, useTransition } from 'react-spring'

import { InfoCircle } from '../modules/icons'
import { colors, zIndex } from '../styles'
import Button from './Button'
import Modal, { ModalSize } from './Modal'

type InformationPopupProps = Pick<DownshiftProps<any>, 'onStateChange'> & {
  children: React.ReactNode
  className?: string
  direction?: 'down' | 'up'
  tw?: boolean
}

type ModalPopupProps = {
  children: React.ReactNode
  isOpen: boolean
  toggleMenu: () => void
  direction: 'down' | 'up'
}

const styles = {
  info: css({
    '& > svg': {
      height: 17,
      width: 17,
    },
    '&:hover': {
      fill: colors.silver3,
    },
    alignItems: 'center',
    background: colors.white,
    display: 'flex',
    fill: colors.silver2,
    height: 28,
    justifyContent: 'center',
    transition: 'fill 300ms ease-out',
    width: 28,
  }),
  main: css({
    position: 'relative',
  }),
  modal: css({
    boxShadow: `0 4px 20px 0 ${colors.charcoalGray2alpha30}`,
  }),
  popup: css({
    position: 'absolute',
    zIndex: zIndex.select,
  }),
}

const ModalPopup: React.FC<ModalPopupProps> = ({ isOpen, children, direction, toggleMenu }) => {
  const transition = useTransition(isOpen, {
    config: config.stiff,
    enter: { opacity: 1, transform: 'translateY(0)' },
    from: { opacity: 0, transform: 'translateY(-16px)' },
    leave: { opacity: 0, transform: 'translateY(-16px)' },
  })

  return transition(
    (props, item, { key }) =>
      item && (
        <animated.div
          className={styles.popup}
          key={key}
          style={{
            ...(direction === 'down' ? { right: 0, top: 38 } : { right: 28, bottom: 0 }),
            ...props,
          }}
        >
          <Modal
            className={styles.modal}
            closeModal={toggleMenu}
            size={ModalSize.Short}
            title="Description"
          >
            {children}
          </Modal>
        </animated.div>
      )
  )
}

const InformationPopup: React.FC<InformationPopupProps> = ({
  children,
  className,
  direction = 'down',
  onStateChange,
  tw = false,
}) => {
  return (
    <Downshift onStateChange={onStateChange}>
      {({ isOpen, toggleMenu }) => (
        <div className={tw ? `relative ${className}` : css(styles.main, className)}>
          <Button className={styles.info} handleClick={toggleMenu}>
            <InfoCircle title="Click for more information" />
          </Button>
          <ModalPopup {...{ direction, isOpen, toggleMenu }}>{children}</ModalPopup>
        </div>
      )}
    </Downshift>
  )
}

export default InformationPopup
