import { css } from 'emotion'
import * as React from 'react'

import { colors } from '../styles'

type FetchingBarsProps = {
  className?: string
  dark?: boolean
  quantity: number
}

const styles = {
  skeleton: (dark: boolean) =>
    css({
      backgroundColor: dark ? colors.paleGray2 : colors.charcoalGray,
      borderRadius: 4,
      height: 12,
      marginBottom: 10,
      opacity: 0.2,
      width: '100%',
    }),
}

const FetchingBars: React.FC<FetchingBarsProps> = ({ className, dark = false, quantity }) => (
  <div className={className}>
    {Array(quantity)
      .fill(0)
      .map((_, index) => (
        <div
          className={styles.skeleton(dark)}
          data-testid="fetching-bars"
          key={index}
          role="progressbar"
        />
      ))}
  </div>
)

export default FetchingBars
