import { isEmpty, pathOr } from 'ramda'
import { Reducer, combineReducers } from 'redux'

import { HttpStatus } from '../../../../http/types'
import {
  SetAction as SessionSetAction,
  SetSettingAction,
  SettingTypes,
} from '../../../../session/types'
import { Actions, ApplicationColumn, Column, DynamicValuesState, TableViewState } from '../types'

const columns = (state: ApplicationColumn[] = [], action: Actions): TableViewState['columns'] => {
  switch (action.type) {
    case 'search/table/SET_AVAILABLE_COLUMNS':
      return action.payload!
    default:
      return state
  }
}

const initialDynamicValues: DynamicValuesState = {
  [Column.ExaminerAllowanceRate]: {},
  [Column.ExaminerAllowanceRateAfterTwoOfficeActions]: {},
  [Column.ExaminerAllowanceRateWithInterview]: {},
  [Column.ExaminerAllowanceRateWithoutInterview]: {},
}

const dynamicValues = (state = initialDynamicValues, action: Actions) => {
  switch (action.type) {
    case 'search/table/DYNAMIC_VALUE_ERROR': {
      const { column, key } = action.payload!

      return {
        ...state,
        [column]: {
          ...state[column],
          [key]: { message: 'Error fetching result.', type: HttpStatus.Error },
        },
      }
    }
    case 'search/table/FETCH_EXAMINER_ALLOWANCE_RATE': {
      const { column, examiner } = action.payload!

      return { ...state, [column]: { ...state[column], [examiner]: { type: HttpStatus.Fetching } } }
    }
    case 'search/table/SET_DYNAMIC_VALUE': {
      const { column, key, value } = action.payload!

      return {
        ...state,
        [column]: {
          ...state[column],
          [key]: { data: value, type: HttpStatus.Success },
        },
      }
    }
    default:
      return state
  }
}

export const initialSelectedColumnsState = [
  Column.PairStatusDate,
  Column.PairStatus,
  Column.AttorneyDocketNumber,
  Column.Title,
  Column.Assignee,
  Column.Examiner,
]

const selectedColumns: Reducer<TableViewState['selectedColumns']> = (
  state = initialSelectedColumnsState,
  action: Actions | SessionSetAction | SetSettingAction
) => {
  switch (action.type) {
    case 'search/table/SET_ALL_SELECTED_COLUMNS':
      // The payload will be empty if we've filtered out all invalid/old columns
      // or user saved with 0 columns selected
      return action.payload?.length === 0 ? state : (action.payload as Column[]) ?? state
    case 'session/SET': {
      const visibleColumns = pathOr(state, ['payload', 'settings', 'columnLayout'], action)
      return isEmpty(visibleColumns) ? state : visibleColumns
    }
    case 'session/SETTING_SET':
      return action.payload!.type === SettingTypes.ColumnLayout
        ? pathOr(state, ['payload', 'value'], action)
        : state
    default:
      return state
  }
}

const reducer = combineReducers<TableViewState>({ columns, dynamicValues, selectedColumns })

export default reducer
