import { isNilOrEmpty } from '@juristat/common/utils'
import gql from 'graphql-tag'
import { identity, merge, negate } from 'ramda'

import { Definition } from '../../search/types'
import { Charts } from '../types'
import { BucketType, Buckets, ChartsGraphQLResponse } from '../types'
import defaultDispositionDateFilter from './defaultDispositionDateFilter'
import defaultFilingDateFilter from './defaultFilingDateFilter'
import defaultIssuanceDateFilter from './defaultIssuanceDateFilter'
import defaultOfficeActionDateFilter from './defaultOfficeActionDateFilter'
import defaultTechCentersFilter from './defaultTechCentersFilter'

type DefinitionFilters = NonNullable<Definition['filters']>

const bucketFragment = gql`
  fragment Buckets on ApplicationSetMetrics {
    buckets {
      name
      value
    }
  }
`

const oaBucketFragment = gql`
  fragment OfficeActionBuckets on ApplicationOfficeActionSetMetrics {
    buckets {
      name
      value
    }
  }
`

const getBucketValue = <T extends { buckets: Buckets }>(data: T) =>
  Number(data?.buckets?.[0]?.value ?? 0)

const transformAllowanceRatesByTechCenter = ({
  metrics,
}: ChartsGraphQLResponse[Charts.AllowanceRatesByTechCenter]) =>
  metrics.map((data) => ({
    allowanceRate: data.allowanceRate ?? 0,
    techCenter: getBucketValue(data),
  }))

const transformAllowanceRatesOverTime = ({
  metrics,
}: ChartsGraphQLResponse[Charts.AllowanceRatesOverTime]) =>
  metrics.map((data) => ({
    allowanceRate: data.allowanceRate ?? 0,
    year: getBucketValue(data),
  }))

const transformApplicationStatuses = ({
  metrics,
}: ChartsGraphQLResponse[Charts.ApplicationStatus]) => {
  const getCount = (id: 'abandoned' | 'allowed' | 'issued' | 'pending') => ({
    id: id.toUpperCase(),
    value: metrics?.[0]?.applicationCounts?.[id] ?? 0,
  })

  return [getCount('abandoned'), getCount('allowed'), getCount('issued'), getCount('pending')]
}

const transformAverageOfficeActionsOverTime = ({
  metrics,
}: ChartsGraphQLResponse[Charts.AverageOfficeActionsOverTime]) =>
  metrics.map((data) => ({
    officeActions: data.officeActions.toDisposition.average ?? 0,
    year: getBucketValue(data),
  }))

const transformDependentClaimsOverTime = ({
  metrics,
}: ChartsGraphQLResponse[Charts.DependentClaimsOverTime]) =>
  metrics.map((data) => ({
    claims: Number(negate(data.claims.lost.dependentClaims.average ?? 0)),
    year: getBucketValue(data),
  }))

const transformIndependentClaimsOverTime = ({
  metrics,
}: ChartsGraphQLResponse[Charts.IndependentClaimsOverTime]) =>
  metrics.map((data) => ({
    claims: Number(negate(data.claims.lost.independentClaims.average ?? 0)),
    year: getBucketValue(data),
  }))

const transformDaysToDispositionOverTime = ({
  metrics,
}: ChartsGraphQLResponse[Charts.DaysToDispositionOverTime]) =>
  metrics.map((data) => ({
    daysToDisposition: data.timing.daysToDisposition.average ?? 0,
    year: getBucketValue(data),
  }))

const transformDaysToFirstResponseOverTime = ({
  officeActionSet: { metrics },
}: ChartsGraphQLResponse[Charts.DaysToFirstResponseOverTime]) =>
  metrics.map((data) => ({
    daysToFirstResponse: data.timing.daysToFirstResponse.average ?? 0,
    year: Number(data.buckets[0].value ?? 0),
  }))

const transformExtensions = ({
  officeActionSet: { metrics },
}: ChartsGraphQLResponse[Charts.Extensions]) =>
  metrics.map((data) => ({
    extensions: data.counts.haveExtension ?? 0,
    total: data.counts.total ?? 1,
    year: getBucketValue(data),
  }))

const transformFilingsByTechCenter = ({
  metrics,
}: ChartsGraphQLResponse[Charts.FilingsByTechCenter]) =>
  metrics
    .map((data) => ({
      filings: data.applicationCounts.total ?? 0,
      techCenter: Number(
        data.buckets.find((bucket) => bucket.name === BucketType.TechCenter)?.value ?? 0
      ),
      year: Number(
        data.buckets.find((bucket) => bucket.name === BucketType.FilingDate)?.value ?? 0
      ),
    }))
    .filter((data) => !isNilOrEmpty(data.filings) && data.techCenter && data.year)

const transformFilingsOverTime = ({ metrics }: ChartsGraphQLResponse[Charts.FilingsOverTime]) =>
  metrics.map((data) => ({
    filings: data.applicationCounts.total ?? 0,
    year: getBucketValue(data),
  }))

const transformIssuedPatentsByTechCenter = ({
  metrics,
}: ChartsGraphQLResponse[Charts.IssuedPatentsByTechCenterOverTime]) =>
  metrics
    .map((data) => ({
      issued: data.applicationCounts.issued ?? 0,
      techCenter: Number(
        data.buckets.find((bucket) => bucket.name === BucketType.TechCenter)?.value ?? 0
      ),
      year: Number(
        data.buckets.find((bucket) => bucket.name === BucketType.IssuanceDate)?.value ?? 0
      ),
    }))
    .filter((data) => !isNilOrEmpty(data.issued) && data.techCenter && data.year)

const transformIssuedPatentsOverTime = ({
  metrics,
}: ChartsGraphQLResponse[Charts.IssuedPatentsOverTime]) =>
  metrics.map((data) => ({
    issued: data.applicationCounts.issued ?? 0,
    year: getBucketValue(data),
  }))

const transformOfficeActionsByDispositionYear = ({
  metrics,
}: ChartsGraphQLResponse[Charts.OfficeActionsByDispositionYear]) =>
  metrics.map((data) => ({
    toAbandonment: data.officeActions.toAbandonment.average ?? 0,
    toAllowance: data.officeActions.toAllowance.average ?? 0,
    toDisposition: data.officeActions.toDisposition.average ?? 0,
    year: getBucketValue(data),
  }))

const transformRejectionBasesOverTime = ({
  officeActionSet: { metrics },
}: ChartsGraphQLResponse[Charts.RejectionBasesOverTime]) =>
  metrics.map((data) => ({
    counts: data.counts ?? {},
    year: getBucketValue(data),
  }))

const transformWordsPerDependentClaimOverTime = ({
  metrics,
}: ChartsGraphQLResponse[Charts.WordsPerDependentClaimOverTime]) =>
  metrics.map((data) => ({
    words: negate(data.claims.lost.wordsPerDependentClaims.average ?? 0),
    year: getBucketValue(data),
  }))

const transformWordsPerIndependentClaimOverTime = ({
  metrics,
}: ChartsGraphQLResponse[Charts.WordsPerIndependentClaimOverTime]) =>
  metrics.map((data) => ({
    words: negate(data.claims.lost.wordsPerIndependentClaims.average ?? 0),
    year: getBucketValue(data),
  }))

const getChartQueryConfig = (chart: Charts) => {
  const transformOfficeActionSetFilters = defaultOfficeActionDateFilter

  switch (chart) {
    case Charts.AllowanceRatesByTechCenter:
      return {
        createQuery(length: number) {
          const array = Array(length).fill(0)
          const variables = array.map(
            (_, index) => `
          $filters${index}: ApplicationFiltersInputType!
          $searches${index}: ApplicationSearches
          $similarTo${index}: SimilarApplicationsComparisons
            `
          )

          const fields = array.map(
            (_, index) => `
          index${index}: applicationSet(filters: $filters${index}, searches: $searches${index}, similarTo: $similarTo${index}) {
            metrics(by: { column: TECH_CENTER }) {
              ...Buckets
              allowanceRate
            }
          }
          `
          )

          return gql`query AllowanceRatesByTechCenter(${variables}) { ${fields} } ${bucketFragment}`
        },
        transformData: transformAllowanceRatesByTechCenter,
        transformFilters: defaultTechCentersFilter,
      }
    case Charts.AllowanceRatesOverTime:
      return {
        createQuery(length: number) {
          const array = Array(length).fill(0)
          const variables = array.map(
            (_, index) => `
          $filters${index}: ApplicationFiltersInputType!
          $searches${index}: ApplicationSearches
          $similarTo${index}: SimilarApplicationsComparisons
            `
          )

          const fields = array.map(
            (_, index) => `
          index${index}: applicationSet(filters: $filters${index}, searches: $searches${index}, similarTo: $similarTo${index}) {
            metrics(by: { column: DISPOSITION_DATE, year: true }) {
              ...Buckets
              allowanceRate
            }
          }
          `
          )

          return gql`query AllowanceRatesOverTime(${variables}) { ${fields} } ${bucketFragment}`
        },
        transformData: transformAllowanceRatesOverTime,
        transformFilters: defaultDispositionDateFilter,
      }
    case Charts.ApplicationStatus:
      return {
        createQuery(length: number) {
          const array = Array(length).fill(0)
          const variables = array.map(
            (_, index) => `
          $filters${index}: ApplicationFiltersInputType!
          $searches${index}: ApplicationSearches
          $similarTo${index}: SimilarApplicationsComparisons
            `
          )

          const fields = array.map(
            (_, index) => `
          index${index}: applicationSet(filters: $filters${index}, searches: $searches${index}, similarTo: $similarTo${index}) {
            metrics {
              applicationCounts {
                abandoned
                allowed
                issued
                pending
              }
            }
          }
          `
          )

          return gql`query ApplicationStatus(${variables}) { ${fields} }`
        },
        transformData: transformApplicationStatuses,
        transformFilters: identity,
      }
    case Charts.AverageOfficeActionsOverTime:
      return {
        createQuery(length: number) {
          const array = Array(length).fill(0)
          const variables = array.map(
            (_, index) => `
          $filters${index}: ApplicationFiltersInputType!
          $searches${index}: ApplicationSearches
          $similarTo${index}: SimilarApplicationsComparisons
            `
          )

          const fields = array.map(
            (_, index) => `
          index${index}: applicationSet(filters: $filters${index}, searches: $searches${index}, similarTo: $similarTo${index}) {
            metrics(by: { column: DISPOSITION_DATE, year: true }) {
              ...Buckets
              officeActions {
                toAbandonment {
                  average
                }
                toAllowance {
                  average
                }
                toDisposition {
                  average
                }
              }
            }
          }
          `
          )

          return gql`query AverageOfficeActionsOverTime(${variables}) { ${fields} } ${bucketFragment}`
        },
        transformData: transformAverageOfficeActionsOverTime,
        transformFilters: defaultDispositionDateFilter,
      }
    case Charts.DaysToDispositionOverTime:
      return {
        createQuery(length: number) {
          const array = Array(length).fill(0)
          const variables = array.map(
            (_, index) => `
          $filters${index}: ApplicationFiltersInputType!
          $searches${index}: ApplicationSearches
          $similarTo${index}: SimilarApplicationsComparisons
            `
          )

          const fields = array.map(
            (_, index) => `
          index${index}: applicationSet(filters: $filters${index}, searches: $searches${index}, similarTo: $similarTo${index}) {
            metrics(by: { column: DISPOSITION_DATE, year: true }) {
              ...Buckets
              timing {
                daysToDisposition {
                  average
                }
              }
            }
          }
          `
          )

          return gql`query DaysToDispositionOverTime(${variables}) { ${fields} } ${bucketFragment}`
        },
        transformData: transformDaysToDispositionOverTime,
        transformFilters: defaultDispositionDateFilter,
      }
    case Charts.DaysToFirstResponseOverTime:
      return {
        createQuery(length: number) {
          const array = Array(length).fill(0)
          const variables = array.map(
            (_, index) => `
          $filters${index}: ApplicationFiltersInputType!
          $oaFilters${index}: ApplicationOfficeActionSetFilters!
          $searches${index}: ApplicationSearches
          $similarTo${index}: SimilarApplicationsComparisons
            `
          )

          const fields = array.map(
            (_, index) => `
          index${index}: applicationSet(filters: $filters${index}, searches: $searches${index}, similarTo: $similarTo${index}) {
            officeActionSet(filters: $oaFilters${index}) {
              metrics(by: OFFICE_ACTION_YEAR) {
                ...OfficeActionBuckets
                timing {
                  daysToFirstResponse {
                    average
                  }
                }
              }
            }
          }
          `
          )

          return gql`query DaysToFirstResponseOverTime(${variables}) { ${fields} } ${oaBucketFragment}`
        },
        transformData: transformDaysToFirstResponseOverTime,
        transformFilters: defaultDispositionDateFilter,
        transformOfficeActionSetFilters,
      }
    case Charts.DependentClaimsOverTime:
      return {
        createQuery(length: number) {
          const array = Array(length).fill(0)
          const variables = array.map(
            (_, index) => `
          $filters${index}: ApplicationFiltersInputType!
          $searches${index}: ApplicationSearches
          $similarTo${index}: SimilarApplicationsComparisons
            `
          )

          const fields = array.map(
            (_, index) => `
          index${index}: applicationSet(filters: $filters${index}, searches: $searches${index}, similarTo: $similarTo${index}) {
            metrics(by: { column: DISPOSITION_DATE, year: true }) {
              ...Buckets
              claims {
                lost {
                  dependentClaims {
                    average
                  }
                }
              }
            }
          }
          `
          )

          return gql`query DependentClaimsOverTime(${variables}) { ${fields} } ${bucketFragment}`
        },
        transformData: transformDependentClaimsOverTime,
        transformFilters: defaultDispositionDateFilter,
      }
    case Charts.Extensions:
      return {
        createQuery(length: number) {
          const array = Array(length).fill(0)
          const variables = array.map(
            (_, index) => `
          $filters${index}: ApplicationFiltersInputType!
          $oaFilters${index}: ApplicationOfficeActionSetFilters!
          $searches${index}: ApplicationSearches
          $similarTo${index}: SimilarApplicationsComparisons
            `
          )

          const fields = array.map(
            (_, index) => `
          index${index}: applicationSet(filters: $filters${index}, searches: $searches${index}, similarTo: $similarTo${index}) {
            officeActionSet(filters: $oaFilters${index}) {
              metrics(by: OFFICE_ACTION_YEAR) {
                ...OfficeActionBuckets
                counts {
                  haveExtension
                  total
                }
              }
            }
          }
          `
          )

          return gql`query Extensions(${variables}) { ${fields} } ${oaBucketFragment}`
        },
        transformData: transformExtensions,
        transformFilters: defaultDispositionDateFilter,
        transformOfficeActionSetFilters,
      }
    case Charts.FilingsByTechCenter:
      return {
        createQuery(length: number) {
          const array = Array(length).fill(0)
          const variables = array.map(
            (_, index) => `
          $filters${index}: ApplicationFiltersInputType!
          $searches${index}: ApplicationSearches
          $similarTo${index}: SimilarApplicationsComparisons
            `
          )

          const fields = array.map(
            (_, index) => `
          index${index}: applicationSet(filters: $filters${index}, searches: $searches${index}, similarTo: $similarTo${index}) {
            metrics(by: [{ column: TECH_CENTER }, { column: FILING_DATE, year: true }]) {
              ...Buckets
              applicationCounts {
                total
              }
            }
          }
          `
          )

          return gql`query FilingsByTechCenter(${variables}) { ${fields} } ${bucketFragment}`
        },
        transformData: transformFilingsByTechCenter,
        transformFilters: (filters: DefinitionFilters) =>
          merge(defaultTechCentersFilter({}), defaultFilingDateFilter(filters)),
      }
    case Charts.DivisionOfWork:
    case Charts.FilingsOverTime:
      return {
        createQuery(length: number) {
          const array = Array(length).fill(0)
          const variables = array.map(
            (_, index) => `
          $filters${index}: ApplicationFiltersInputType!
          $searches${index}: ApplicationSearches
          $similarTo${index}: SimilarApplicationsComparisons
            `
          )

          const fields = array.map(
            (_, index) => `
          index${index}: applicationSet(filters: $filters${index}, searches: $searches${index}, similarTo: $similarTo${index}) {
            metrics(by: { column: FILING_DATE, year: true }) {
              ...Buckets
              applicationCounts {
                total
              }
            }
          }
          `
          )

          return gql`query FilingsOverTime(${variables}) { ${fields} } ${bucketFragment}`
        },
        transformData: transformFilingsOverTime,
        transformFilters: defaultFilingDateFilter,
      }
    case Charts.IndependentClaimsOverTime:
      return {
        createQuery(length: number) {
          const array = Array(length).fill(0)
          const variables = array.map(
            (_, index) => `
          $filters${index}: ApplicationFiltersInputType!
          $searches${index}: ApplicationSearches
          $similarTo${index}: SimilarApplicationsComparisons
            `
          )

          const fields = array.map(
            (_, index) => `
          index${index}: applicationSet(filters: $filters${index}, searches: $searches${index}, similarTo: $similarTo${index}) {
            metrics(by: { column: DISPOSITION_DATE, year: true }) {
              ...Buckets
              claims {
                lost {
                  independentClaims {
                    average
                  }
                }
              }
            }
          }
          `
          )

          return gql`query IndependentClaimsOverTime(${variables}) { ${fields} } ${bucketFragment}`
        },
        transformData: transformIndependentClaimsOverTime,
        transformFilters: defaultDispositionDateFilter,
      }
    case Charts.IssuedPatentsByTechCenterOverTime:
      return {
        createQuery(length: number) {
          const array = Array(length).fill(0)
          const variables = array.map(
            (_, index) => `
          $filters${index}: ApplicationFiltersInputType!
          $searches${index}: ApplicationSearches
          $similarTo${index}: SimilarApplicationsComparisons
            `
          )

          const fields = array.map(
            (_, index) => `
          index${index}: applicationSet(filters: $filters${index}, searches: $searches${index}, similarTo: $similarTo${index}) {
            metrics(by: [{ column: TECH_CENTER }, { column: ISSUANCE_DATE, year: true }]) {
              ...Buckets
              applicationCounts {
                issued
              }
            }
          }
          `
          )

          return gql`query IssuedPatentsByTechCenterOverTime(${variables}) { ${fields} } ${bucketFragment}`
        },
        transformData: transformIssuedPatentsByTechCenter,
        transformFilters: (filters: DefinitionFilters) =>
          merge(defaultTechCentersFilter({}), defaultIssuanceDateFilter(filters)),
      }
    case Charts.IssuedPatentsOverTime:
      return {
        createQuery(length: number) {
          const array = Array(length).fill(0)
          const variables = array.map(
            (_, index) => `
          $filters${index}: ApplicationFiltersInputType!
          $searches${index}: ApplicationSearches
          $similarTo${index}: SimilarApplicationsComparisons
            `
          )

          const fields = array.map(
            (_, index) => `
          index${index}: applicationSet(filters: $filters${index}, searches: $searches${index}, similarTo: $similarTo${index}) {
            metrics(by: { column: ISSUANCE_DATE, year: true }) {
              ...Buckets
              applicationCounts {
                issued
              }
            }
          }
          `
          )

          return gql`query IssuedPatentsOverTime(${variables}) { ${fields} } ${bucketFragment}`
        },
        transformData: transformIssuedPatentsOverTime,
        transformFilters: defaultIssuanceDateFilter,
      }
    case Charts.OfficeActionsByDispositionYear:
      return {
        createQuery(length: number) {
          const array = Array(length).fill(0)
          const variables = array.map(
            (_, index) => `
          $filters${index}: ApplicationFiltersInputType!
          $searches${index}: ApplicationSearches
          $similarTo${index}: SimilarApplicationsComparisons
            `
          )

          const fields = array.map(
            (_, index) => `
          index${index}: applicationSet(filters: $filters${index}, searches: $searches${index}, similarTo: $similarTo${index}) {
            metrics(by: { column: DISPOSITION_DATE, year: true }) {
              ...Buckets
              officeActions {
                toAbandonment {
                  average
                }
                toAllowance {
                  average
                }
                toDisposition {
                  average
                }
              }
            }
          }
          `
          )

          return gql`query OfficeActionsByDispositionYear(${variables}) { ${fields} } ${bucketFragment}`
        },
        transformData: transformOfficeActionsByDispositionYear,
        transformFilters: defaultDispositionDateFilter,
      }
    case Charts.RejectionBasesOverTime:
      return {
        createQuery(length: number) {
          const array = Array(length).fill(0)
          const variables = array.map(
            (_, index) => `
          $filters${index}: ApplicationFiltersInputType!
          $oaFilters${index}: ApplicationOfficeActionSetFilters!
          $searches${index}: ApplicationSearches
          $similarTo${index}: SimilarApplicationsComparisons
            `
          )

          const fields = array.map(
            (_, index) => `
          index${index}: applicationSet(filters: $filters${index}, searches: $searches${index}, similarTo: $similarTo${index}) {
            officeActionSet(filters: $oaFilters${index}) {
              metrics(by: OFFICE_ACTION_YEAR) {
                ...OfficeActionBuckets
                counts {
                  haveType101
                  haveType102
                  haveType103
                  haveType112a
                  haveType112b
                  haveType171
                  haveTypeAlice
                  haveTypeBilski
                  haveTypeDoublePatenting
                  haveTypeMayoMyriad
                }
              }
            }
          }
          `
          )

          return gql`query RejectionBasesOverTime(${variables}) { ${fields} } ${oaBucketFragment}`
        },
        transformData: transformRejectionBasesOverTime,
        transformFilters: defaultDispositionDateFilter,
        transformOfficeActionSetFilters,
      }
    case Charts.WordsPerDependentClaimOverTime:
      return {
        createQuery(length: number) {
          const array = Array(length).fill(0)
          const variables = array.map(
            (_, index) => `
          $filters${index}: ApplicationFiltersInputType!
          $searches${index}: ApplicationSearches
          $similarTo${index}: SimilarApplicationsComparisons
            `
          )

          const fields = array.map(
            (_, index) => `
          index${index}: applicationSet(filters: $filters${index}, searches: $searches${index}, similarTo: $similarTo${index}) {
            metrics(by: { column: DISPOSITION_DATE, year: true }) {
              ...Buckets
              claims {
                lost {
                  wordsPerDependentClaims {
                    average
                  }
                }
              }
            }
          }
          `
          )

          return gql`query WordsPerDependentClaimOverTime(${variables}) { ${fields} } ${bucketFragment}`
        },
        transformData: transformWordsPerDependentClaimOverTime,
        transformFilters: defaultDispositionDateFilter,
      }
    case Charts.WordsPerIndependentClaimOverTime:
      return {
        createQuery(length: number) {
          const array = Array(length).fill(0)
          const variables = array.map(
            (_, index) => `
          $filters${index}: ApplicationFiltersInputType!
          $searches${index}: ApplicationSearches
          $similarTo${index}: SimilarApplicationsComparisons
            `
          )

          const fields = array.map(
            (_, index) => `
          index${index}: applicationSet(filters: $filters${index}, searches: $searches${index}, similarTo: $similarTo${index}) {
            metrics(by: { column: DISPOSITION_DATE, year: true }) {
              ...Buckets
              claims {
                lost {
                  wordsPerIndependentClaims {
                    average
                  }
                }
              }
            }
          }
          `
          )

          return gql`query WordsPerIndependentClaimOverTime(${variables}) { ${fields} } ${bucketFragment}`
        },
        transformData: transformWordsPerIndependentClaimOverTime,
        transformFilters: defaultDispositionDateFilter,
      }
    default:
      throw new Error(`No chart configuration for ${chart}`)
  }
}

export default getChartQueryConfig
