import { DataSource } from '@juristat/common/types'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { HttpStatus } from '../../../../http/types'
import { getPpairUser } from '../../../../session/selectors'
import { PpairUser } from '../../../../session/types'
import performSearchQuery from '../../../sagas/performSearchQuery'
import { getSearchDataSource } from '../../../selectors'
import { getActiveResultsSearchId } from '../../../selectors/getSearch'
import actions from '../actions'
import * as query from '../queries/getExaminerAllowanceRate.graphql'
import { getDynamicColumnContent } from '../selectors'
import { Column, DynamicColumn, GetExaminerAllowanceRateAction } from '../types'

const getValue = (result: WeakObject): number | null =>
  result?.applicationSet?.metrics?.[0]?.allowanceRate ?? null
const makeError = (column: DynamicColumn, key: number, searchId: string) => () =>
  actions.dynamicValueError({ column, key }, { searchId })

function* getExaminerAllowanceRate(action: GetExaminerAllowanceRateAction) {
  const searchId = yield select(getActiveResultsSearchId)
  const dataSource = (yield select(getSearchDataSource)) || DataSource.PublicPair

  const { column, examiner } = action.payload!
  const filters = {
    examiner: [examiner],
    finalRejectionsCount:
      column === Column.ExaminerAllowanceRateAfterTwoOfficeActions ? { atLeast: 2 } : undefined,
    interviewCount:
      column === Column.ExaminerAllowanceRateWithInterview
        ? { atLeast: 1 }
        : column === Column.ExaminerAllowanceRateWithoutInterview
        ? { atMost: 0 }
        : undefined,
  }
  const content = yield select(getDynamicColumnContent, { column, key: examiner })

  // Don't fetch duplicate data
  if (content.type !== HttpStatus.NotAsked) {
    return
  }

  yield put(actions.fetchExaminerAllowanceRate({ column, examiner }, { searchId }))

  const error: ReturnType<typeof makeError> = yield call(makeError, column, examiner, searchId)

  try {
    const result = yield call(performSearchQuery, { filters }, error, query, dataSource)

    if (result.ok) {
      const { data } = yield call([result, 'json'])
      const value = getValue(data) ?? 0

      const hasPpair = (yield select(getPpairUser)) === PpairUser.True

      if (hasPpair && dataSource === DataSource.PublicPair) {
        const ppairResult = yield call(
          performSearchQuery,
          { filters },
          error,
          query,
          DataSource.PrivatePair
        )

        if (ppairResult.ok) {
          const { data: ppairData } = yield call([ppairResult, 'json'])
          const ppairValue = getValue(ppairData)

          yield put(
            actions.setDynamicValue(
              { column, key: examiner, value: ppairValue ?? value },
              { searchId }
            )
          )

          return
        }
      }

      yield put(actions.setDynamicValue({ column, key: examiner, value }, { searchId }))
    } else {
      yield put(error())
    }
  } catch (ex) {
    yield put(error())
  }
}

function* watchGetExaminerAllowanceRate() {
  yield takeEvery(actions.getExaminerAllowanceRate().type, getExaminerAllowanceRate)
}

export { getExaminerAllowanceRate, makeError }
export default watchGetExaminerAllowanceRate
