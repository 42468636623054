import { useSelector } from 'react-redux'

import { getSession, getSettings, makeGetAccesses } from '../session/selectors'
import { Access } from '../session/types'
import { getGroupId, getUsername, getUuid } from './selectors'

export function useHasAccess(access?: Access | Access[], defaultAccess = false) {
  const accesses = useSelector(access ? makeGetAccesses([access].flat()) : () => [defaultAccess])

  return accesses.some(Boolean)
}

export function useSession() {
  return useSelector(getSession)
}

export function useSmartshellUrl() {
  const canUseSmartshell = useHasAccess(Access.Smartshell)
  const settings = useSelector(getSettings)

  return canUseSmartshell ? settings.smartshellUrl ?? null : null
}

export function useGroupId() {
  return useSelector(getGroupId)
}

export function useUserUuid() {
  return useSelector(getUuid)
}

export function useUsername() {
  return useSelector(getUsername)
}

export { Access }
