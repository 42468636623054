import { css } from 'emotion'
import { omit, values } from 'ramda'
import React, { useEffect, useState } from 'react'
import ReactImageGallery, { ReactImageGalleryItem } from 'react-image-gallery'
import { useDispatch, useSelector } from 'react-redux'

import Button from '../../../../components/Button'
import { Direction } from '../../../../components/Chevron'
import { ModalBackground } from '../../../../components/Modal'
import { usePrevious } from '../../../../hooks'
import { colors, makeTransition, textStyles } from '../../../../styles'
import analyticsActions from '../../../analytics/actions'
import { IntercomActions } from '../../../analytics/types'
import sessionActions from '../../../session/actions'
import { getFeatureModal } from '../../../session/selectors'
import { Feature } from '../../../session/types'
import GalleryNav from './GalleryNav'
import allFeatureInfo, { FeatureInfo } from './featureInfo'
import featureIntercomEvents from './featureIntercomEvents'

const galleryStyles = css({
  '& .image-gallery': {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  '& .image-gallery-content': {
    position: 'relative',
  },
  '& .image-gallery-slide': {
    '&.center': { position: 'relative' },
    left: 0,
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  '& .image-gallery-slides': {
    overflow: 'hidden',
    position: 'relative',
    whiteSpace: 'nowrap',
  },
})

const styles = {
  galleryItem: css({
    maxHeight: '100%',
    maxWidth: '100%',
  }),
  galleryItemContainer: css({
    alignItems: 'center',
    display: 'flex',
    height: 190,
    justifyContent: 'center',
    width: 300,
  }),
  galleryNav: (direction: Direction) =>
    css({
      left: direction === Direction.Left ? -48 : undefined,
      position: 'absolute',
      right: direction === Direction.Right ? -48 : undefined,
      top: 'calc(50% - 11px)',
    }),
  leftContainer: css({
    '& a': textStyles.paleGray2Normal13,
    '& button': {
      '&:hover': {
        boxShadow: `0 4px 20px 0 ${colors.charcoalGray2alpha30}`,
      },
      ...textStyles.charcoalGray2Bold13,
      backgroundColor: colors.white,
      borderRadius: 4,
      height: 32,
      marginBottom: 40,
      transition: makeTransition('box-shadow'),
      width: 190,
    },
    '& h1': {
      ...textStyles.paleGray2Bold16Caps,
      margin: '15px 0 25px 0',
    },
    '& p': {
      ...textStyles.paleGray2Normal13,
      lineHeight: '18px',
      margin: '0 10px 25px 10px',
    },
    alignItems: 'center',
    backgroundColor: colors.greenyBlue,
    borderRadius: '4px 0 0 4px',
    display: 'flex',
    flexDirection: 'column',
    height: 400,
    padding: 20,
    textAlign: 'center',
    width: 250,
  }),
  main: css({
    display: 'flex',
  }),
  rightContainer: css({
    '& h2': {
      ...textStyles.charcoalGray2Bold12Caps,
      marginBottom: 15,
    },
    '& p': {
      ...textStyles.charcoalGrayNormal13,
      alignItems: 'flex-end',
      display: 'flex',
      height: 88,
      justifyContent: 'center',
      lineHeight: '22px',
      marginBottom: -7,
    },
    backgroundColor: colors.white,
    borderRadius: '0 4px 4px 0',
    display: 'flex',
    flexDirection: 'column',
    height: 400,
    justifyContent: 'space-between',
    padding: '38px 29px',
    textAlign: 'center',
    width: 450,
  }),
}

const renderItem = ({ original }: ReactImageGalleryItem) => (
  <div className={styles.galleryItemContainer}>
    <img className={styles.galleryItem} src={original} />
  </div>
)

const FeatureModal: React.FC = () => {
  const dispatch = useDispatch()
  const { focusFeature, open } = useSelector(getFeatureModal)
  const [currentIndex, setCurrentIndex] = useState(0)
  const previousFocusFeature = usePrevious(focusFeature, true)
  const previousOpen = usePrevious(open, true)

  const featureArray: FeatureInfo[] = focusFeature
    ? [allFeatureInfo[focusFeature], ...values(omit([focusFeature], allFeatureInfo))]
    : values(allFeatureInfo)

  const itemsArray = featureArray.map((featureInfo) => ({
    ...featureInfo,
    original: featureInfo.img,
  }))

  useEffect(() => {
    if ((previousOpen === false || previousFocusFeature !== focusFeature) && open) {
      dispatch(analyticsActions.intercom.log(featureIntercomEvents[focusFeature || Feature.Other]))
    }
  }, [focusFeature, open, previousFocusFeature, previousOpen])

  const closeModal = () => {
    dispatch(
      sessionActions.featureModalSet({
        open: false,
      })
    )
  }

  const chatWithSales = () => {
    closeModal()
    dispatch(analyticsActions.intercom.log(IntercomActions.FeatureChatWithSales))
    dispatch(
      analyticsActions.intercom.showNewMessage(
        "Hi, I'm interested in hearing about Juristat Platform"
      )
    )
  }

  return open ? (
    <ModalBackground closeModal={closeModal}>
      <div className={styles.main}>
        <div className={styles.leftContainer}>
          <h1>Patent prosecution reinvented.</h1>
          <p>
            Platform is the most comprehensive patent analytics tool available, with nearly
            limitless ways to customize data sets and run analytics, keep tabs on applications, and
            generate competitive insights.
          </p>
          <Button handleClick={chatWithSales}>Chat With Sales</Button>
          <a target="_blank" href="https://blog.juristat.com/introducing-juristat-platform">
            Learn More
          </a>
        </div>
        <div className={css(galleryStyles, styles.rightContainer)}>
          <h2>{itemsArray[currentIndex].title}</h2>
          <ReactImageGallery
            items={itemsArray}
            onSlide={setCurrentIndex}
            renderItem={renderItem}
            renderLeftNav={(onClick, isDisabled) => (
              <GalleryNav
                className={styles.galleryNav(Direction.Left)}
                onClick={onClick}
                disabled={isDisabled}
                direction={Direction.Left}
              />
            )}
            renderRightNav={(onClick, isDisabled) => (
              <GalleryNav
                className={styles.galleryNav(Direction.Right)}
                onClick={onClick}
                disabled={isDisabled}
                direction={Direction.Right}
              />
            )}
            showThumbnails={false}
            showFullscreenButton={false}
            showPlayButton={false}
          />
          <p>{itemsArray[currentIndex].description}</p>
        </div>
      </div>
    </ModalBackground>
  ) : null
}

export default FeatureModal
