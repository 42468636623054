import { useContext } from 'react'

import {
  AccessTokenContext,
  Auth0ClientContext,
  AuthorizeContext,
  ResetPasswordContext,
} from './contexts'

export function useAccessToken() {
  const context = useContext(AccessTokenContext)

  if (context === undefined) {
    throw new Error('useAccessToken must be used within a Auth0ContextProvider')
  }

  return context
}

export function useAuth0Client() {
  const context = useContext(Auth0ClientContext)

  if (context === undefined) {
    throw new Error('useAuth0Client must be used within a Auth0ContextProvider')
  }

  return context
}

export function useAuthorize() {
  const context = useContext(AuthorizeContext)

  if (context === undefined) {
    throw new Error('useAuthorize must be used within a Auth0ContextProvider')
  }

  return context
}

export function useResetPassword() {
  const context = useContext(ResetPasswordContext)

  if (context === undefined) {
    throw new Error('useResetPassword must be used within a Auth0ContextProvider')
  }

  return context
}
