import { css } from 'emotion'
import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'

import ButtonGroup from '../../../components/ButtonGroup'
import actions from '../actions'
import FilterContext from '../context/filterContext'
import { BooleanFilter as BooleanFilterType } from '../types'
import FilterContainer, { FilterContainerProps } from './FilterContainer'

type BooleanFilterProps = Omit<FilterContainerProps, 'render' | 'filter'> & {
  excludeText?: string
  filter: BooleanFilterType
  includeText?: string
}

const styles = {
  button: css({
    '&:first-of-type': {
      borderRadius: 4,
    },
    '&:last-of-type': {
      borderRadius: 4,
      marginLeft: 0,
      marginTop: 10,
    },
    width: '100%',
  }),
}

const BooleanFilter: React.FC<BooleanFilterProps> = ({
  excludeText = 'Exclude',
  filter,
  includeText = 'Include',
  title,
}) => {
  const dispatch = useDispatch()
  const { meta } = useContext(FilterContext)

  return (
    <FilterContainer
      filter={filter}
      render={({ selected }) => (
        <ButtonGroup
          buttons={[
            { label: includeText, value: true },
            { label: excludeText, value: false },
          ]}
          classNameButton={styles.button}
          update={(value) => {
            if (value === null) {
              return dispatch(actions.selectSome({ filter }, meta))
            }

            dispatch(actions.selectSome({ filter, value }, meta))
          }}
          value={selected as boolean}
        />
      )}
      skipHttpStatus={true}
      title={title}
    />
  )
}

export default BooleanFilter
