import { call } from 'redux-saga/effects'

import { getPrivateOrPublicResponse } from '../../api'
import * as query from '../queries/getCombinedAppCount.graphql'

function* getCombinedAppCount(uids: string[]) {
  const {
    applicationSet: { total },
  } = yield call(getPrivateOrPublicResponse, query, { uids })

  return total
}

export default getCombinedAppCount
