import { Column } from '@juristat/common/types'

import { Action } from '../../../../redux'
import { HttpContent } from '../../../http/types'
import { SearchMeta } from '../../types'

export type ApplicationColumn = {
  description: string
  enumName: Column
  label: string
  pinned?: boolean
  sortable: boolean
}

export type ApplicationColumnResponse = {
  data: {
    applicationColumns: ApplicationColumn[]
  }
}

export type DynamicColumn =
  | Column.ExaminerAllowanceRate
  | Column.ExaminerAllowanceRateAfterTwoOfficeActions
  | Column.ExaminerAllowanceRateWithInterview
  | Column.ExaminerAllowanceRateWithoutInterview

export type DynamicValuesState = {
  [K in DynamicColumn]: {
    [appno: number]: HttpContent<{ [appno: number]: number }>
  }
}

export type TableViewState = {
  columns: ApplicationColumn[]
  dynamicValues: DynamicValuesState
  selectedColumns: Column[]
}

export type SetSelectedColumnPayload = {
  column: Column
  selected: boolean
}
export type MoveColumnPayload = {
  column: Column
  position: number
}

type DynamicValueErrorPayload = {
  column: DynamicColumn
  key: number
}

type GetExaminerAllowanceRatePayload = {
  column: DynamicColumn
  examiner: number
}

type SetDynamicValuePayload = {
  column: DynamicColumn
  key: number
  value: number | string
}

type DynamicValueErrorAction = Action<
  'search/table/DYNAMIC_VALUE_ERROR',
  DynamicValueErrorPayload,
  SearchMeta
>

type SetExaminerAllowanceRateFetchingAction = Action<
  'search/table/FETCH_EXAMINER_ALLOWANCE_RATE',
  GetExaminerAllowanceRatePayload,
  SearchMeta
>

type GetExaminerAllowanceRateAction = Action<
  'search/table/GET_EXAMINER_ALLOWANCE_RATE',
  GetExaminerAllowanceRatePayload,
  SearchMeta
>

type SetDynamicValueAction = Action<
  'search/table/SET_DYNAMIC_VALUE',
  SetDynamicValuePayload,
  SearchMeta
>

export type SetAllSelectedColumnsAction = Action<
  'search/table/SET_ALL_SELECTED_COLUMNS',
  string[],
  SearchMeta
>
export type SetAvailableColumnsAction = Action<
  'search/table/SET_AVAILABLE_COLUMNS',
  ApplicationColumn[],
  SearchMeta
>
export type MoveColumnAction = Action<'search/table/MOVE_COLUMN', MoveColumnPayload, SearchMeta>
export type MoveColumnsAction = Action<'search/table/MOVE_COLUMNS', string[], SearchMeta>

export type Actions =
  | DynamicValueErrorAction
  | SetExaminerAllowanceRateFetchingAction
  | GetExaminerAllowanceRateAction
  | MoveColumnAction
  | SetAllSelectedColumnsAction
  | SetAvailableColumnsAction
  | SetDynamicValueAction

export {
  Column,
  DynamicValueErrorAction,
  GetExaminerAllowanceRateAction,
  SetDynamicValueAction,
  SetExaminerAllowanceRateFetchingAction,
}
