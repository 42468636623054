import { css } from 'emotion'
import * as React from 'react'

import { Close } from '../modules/icons'
import { colors, textStyles } from '../styles'

type TagProps = {
  className?: string
  handleOnRemoveClick: () => void
  key?: string
  label: string
}

const styles = {
  icon: css({
    cursor: 'pointer',
    fill: colors.dark,
  }),
  main: css({
    alignItems: 'center',
    backgroundColor: colors.cloudyBlue,
    borderRadius: 4,
    display: 'flex',
    height: 26,
    justifyContent: 'space-between',
    paddingRight: 8,
  }),
  text: css(textStyles.darkNormal14, {
    overflow: 'hidden',
    padding: '3.5px 8px',
    textOverflow: 'ellipsis',
  }),
}

const Tag = ({ className, handleOnRemoveClick, label }: TagProps) => (
  <div className={css(styles.main, className)}>
    <div className={styles.text}>{label}</div>
    <Close className={styles.icon} onClick={handleOnRemoveClick} height={10} width={10} />
  </div>
)

export default Tag
