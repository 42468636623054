import { css } from 'emotion'
import * as React from 'react'

import { colors, textStyles } from '../styles'

type TextAreaProps = Partial<{
  className: string
  handleTextChange: (text: string) => void
  name: string
  placeholder: string
  text: string
  tw?: boolean
}> &
  Pick<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'onKeyDown' | 'onPaste'>

const twstyles = {
  main: 'placeholder:text-charcoal placeholder:font-opensans placeholder:text-xs placeholder:font-stretch-normal placeholder:not-italic placeholder:font-normal placeholder:tracking-normal focus:text-placeholder focus:font-opensans focus:text-xs focus:font-stretch-normal focus:not-italic focus:font-normal focus:tracking-normal disabled:cursor-not-allowed disabled:opacity-50 bg-[rgba(48,_55,_65,_0.04)] border border-charcoal-gray6 h-[144px] outline-[0] py-[6px] px-[11px] resize-none w-full',
}

const styles = {
  main: css(textStyles.charcoalGrayNormal12, {
    '&::placeholder': textStyles.placeholderNormal12,
    '&:focus': {
      backgroundColor: 'transparent',
      border: `1px solid ${colors.appleGreen}`,
    },
    '&[disabled]': {
      cursor: 'not-allowed',
      opacity: 0.5,
    },
    backgroundColor: colors.charcoalGray2alpha04,
    border: `1px solid ${colors.dark2Alpha50}`,
    borderRadius: 4,
    color: colors.charcoalGray6,
    height: 144,
    outline: 0,
    padding: '6px 11px',
    resize: 'none',
    width: '100%',
  }),
}

const TextArea = ({ className, handleTextChange, text, tw = false, ...props }: TextAreaProps) => (
  <textarea
    {...props}
    className={tw ? `${twstyles.main} ${className}` : css(styles.main, className)}
    onChange={(event) => handleTextChange?.(event.target.value)}
    value={text}
  />
)

export default TextArea
