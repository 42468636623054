import { css } from 'emotion'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'

import TextInput from '../../../components/TextInput'
import { useUpdateEffect } from '../../../hooks/useUpdateEffect'
import { colors } from '../../../styles'
import actions from '../actions'
import { useFilterState } from '../hooks'
import { ActiveReducer, StatCountFilter } from '../types'
import FilterContainer, { FilterContainerProps } from './FilterContainer'

type RangeFilterContainerProps = Omit<FilterContainerProps, 'render' | 'filter'> & {
  filter: StatCountFilter
}

type ThumbProps = {
  label: string
  left: number
  max: number
  min: number
  set: React.Dispatch<React.SetStateAction<number>>
  value: number
}

const styles = {
  container: css({
    alignItems: 'center',
    color: colors.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }),
  inputs: {
    container: css({
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '16px 0',
      width: '100%',
    }),
    input: css({
      '&:focus': {
        backgroundColor: colors.white,
      },
      textAlign: 'center',
      width: 60,
    }),
  },
  rail: css({
    background: colors.silver2,
    borderRadius: 9999,
    border: `1px solid ${colors.placeholder}`,
    height: 8,
    position: 'relative',
    width: 210,
  }),
  slider: css({
    '&:hover, &:focus': {
      borderColor: colors.white,
    },
    background: colors.appleGreen,
    border: `1px solid ${colors.placeholder}`,
    borderRadius: 9999,
    cursor: 'ew-resize',
    height: 20,
    margin: 0,
    outline: 'none',
    padding: 0,
    position: 'absolute',
    top: -7,
    transition: '300ms border-color ease-in-out',
    width: 20,
  }),
  value: css({
    background: colors.appleGreen,
    height: 6,
    position: 'absolute',
  }),
}

// This is the position from the left of the screen, always the same currently for us.
const offsetLeft = 76

// Min and max value of our filter
const min = 0
const max = 100

// Width of our slider in the filter bank
const width = 210

// Border width around the slider
const border = 1

// Width of each thumb on the slider
const thumb = 20

const keys = [
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'End',
  'Home',
  'PageDown',
  'PageUp',
]

const clampValue = (value: number, min: number, max: number) =>
  value > max ? max : value < min ? min : value

const Thumb = ({ label, left, set, ...value }: ThumbProps) => {
  const [dragging, setDragging] = useState(false)

  const handleDrag = useCallback(
    (event) => {
      const diffX = event.pageX - offsetLeft

      set(clampValue(Math.round(min + ((max - min) * diffX) / width), value.min, value.max))
    },
    [set, value.max, value.min]
  )
  const handleEnd = useCallback(() => setDragging(false), [])

  useEffect(() => {
    if (!dragging) {
      return
    }

    document.addEventListener('mousemove', handleDrag)
    document.addEventListener('mouseup', handleEnd)

    return () => {
      document.removeEventListener('mousemove', handleDrag)
      document.removeEventListener('mouseup', handleEnd)
    }
  }, [dragging, handleDrag, handleEnd])

  return (
    <div
      aria-label={label}
      aria-valuemax={value.max}
      aria-valuemin={value.min}
      aria-valuenow={value.value}
      aria-valuetext={`${value.value}%`}
      className={styles.slider}
      onKeyDown={(event) => {
        if (keys.includes(event.key)) {
          event.preventDefault()
          event.stopPropagation()
        }

        switch (event.key) {
          case 'ArrowDown':
          case 'ArrowLeft':
            return set((state) => clampValue(state - 1, value.min, value.max))
          case 'ArrowRight':
          case 'ArrowUp':
            return set((state) => clampValue(state + 1, value.min, value.max))
          case 'End':
            return set(value.max)
          case 'Home':
            return set(value.min)
          case 'PageDown':
            return set((state) => clampValue(state - 10, value.min, value.max))
          case 'PageUp':
            return set((state) => clampValue(state + 10, value.min, value.max))
        }
      }}
      onMouseDown={() => setDragging(true)}
      role="slider"
      style={{ left }}
      tabIndex={0}
    />
  )
}

const RangeFilterContainer = (props: RangeFilterContainerProps) => {
  const { meta, statCount, ...state } = useFilterState(props.filter)
  const { selection } = statCount[props.filter]
  const active = state.active as ActiveReducer[typeof props.filter]

  const dispatch = useDispatch()
  const [debouncedDispatch] = useDebouncedCallback(dispatch, 100)

  const [minimum, setMinimum] = useState(active?.atLeast ?? 0)
  const [maximum, setMaximum] = useState(active?.atMost ?? 100)

  const [clampMaximum] = useDebouncedCallback(setMaximum, 350)
  const [clampMinimum] = useDebouncedCallback(setMinimum, 350)

  const minimumPosition = useMemo(() => {
    const value = clampValue(minimum, min, maximum)
    const position = Math.round(((value - min) * (width - 2 * (thumb - border))) / (max - min))

    return position - border
  }, [minimum, maximum])

  const maximumPosition = useMemo(() => {
    const value = clampValue(maximum, minimum, max)
    const position = Math.round(((value - min) * (width - 2 * (thumb - border))) / (max - min))

    return position + thumb - border
  }, [minimum, maximum])

  useEffect(() => {
    setMinimum(Math.round((active?.atLeast ?? 0) * 100))
  }, [active?.atLeast])

  useEffect(() => {
    setMaximum(Math.round((active?.atMost ?? 1) * 100))
  }, [active?.atMost])

  useUpdateEffect(() => {
    const atLeast = minimum / 100
    const atMost = maximum / 100

    debouncedDispatch(
      actions.selectSome({ filter: props.filter, value: { atLeast, atMost } }, meta)
    )
  }, [minimum, maximum, debouncedDispatch, meta, props.filter])

  useUpdateEffect(() => {
    if (!selection) {
      setMinimum(0)
      setMaximum(100)
    }
  }, [selection])

  return (
    <FilterContainer
      render={() => {
        return (
          <div className={styles.container}>
            <div className={styles.rail}>
              <div
                className={styles.value}
                style={{ width: maximumPosition - minimumPosition, left: minimumPosition + 10 }}
              />
              <Thumb
                label="Examiner Allowance Rate Minimum Percentage"
                left={minimumPosition}
                max={maximum}
                min={min}
                set={setMinimum}
                value={minimum}
              />
              <Thumb
                label="Examiner Allowance Rate Maximum Percentage"
                left={maximumPosition}
                max={max}
                min={minimum}
                set={setMaximum}
                value={maximum}
              />
            </div>
            <div className={styles.inputs.container}>
              <TextInput
                className={styles.inputs.input}
                handleOnTextChange={(text) => {
                  setMinimum(Number(text))
                  clampMinimum(clampValue(Number(text), min, maximum))
                }}
                placeholder="Enter Examiner Allowance Rate Minimum Percentage"
                text={`${minimum}`}
                type="number"
              />
              <TextInput
                className={styles.inputs.input}
                handleOnTextChange={(text) => {
                  setMaximum(Number(text))
                  clampMaximum(clampValue(Number(text), minimum, max))
                }}
                placeholder="Enter Examiner Allowance Rate Maximum Percentage"
                text={`${maximum}`}
                type="number"
              />
            </div>
          </div>
        )
      }}
      skipHttpStatus
      {...props}
    />
  )
}

export default RangeFilterContainer
