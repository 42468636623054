import { OrderDirection, TableColumn } from '@juristat/common/types'
import { Cell } from '@juristat/react-sticky-table'
import { css } from 'emotion'
import { is } from 'ramda'
import * as React from 'react'

import { UpDownArrow } from '../../modules/icons'
import { colors, textStyles } from '../../styles'
import { noop } from '../../utils'
import BaseTable from './BaseTable'
import TableFetchingLoader from './TableFetchingLoader'

type StaticTableProps<T> = {
  className?: string
  classNameCell?: string
  columns: Array<TableColumn<T>>
  cellsByRow?: React.ReactNode[][]
  fetching?: number
  handleHeaderClick?: (key: T) => void
  sort?: {
    by: T | null
    direction: OrderDirection
  }
  stickyHeader?: boolean
}

type TableHeaderProps<T> = Pick<StaticTableProps<T>, 'handleHeaderClick' | 'sort'> & {
  classNameCell?: string
  key?: string
  column: TableColumn<T>
}

const styles = {
  cell: css(textStyles.charcoalGrayNormal12, {
    color: colors.charcoalGrayAlpha80,
    display: 'table-cell',
    height: 40,
    maxWidth: 160,
    overflow: 'hidden',
    padding: '11px 11px 11px 16px',
    textOverflow: 'ellipsis',
    verticalAlign: 'middle',
  }),
  headerCells: css({
    borderBottom: `1px solid ${colors.charcoalGray2alpha50}`,
  }),
  headerTextAndIconContainer: (sortable: boolean) =>
    css(textStyles.charcoalGrayBold12, {
      alignItems: 'center',
      cursor: sortable ? 'pointer' : 'auto',
      display: 'flex',
      justifyContent: 'space-between',
    }),
  row: css({
    '&:not(:first-child)': {
      '& > *': {
        borderBottom: `1px solid ${colors.cloudyBlueAlpha50}`,
      },
    },
    '&:nth-child(odd)': {
      '&:not(:first-child)': {
        backgroundColor: colors.paleGray2Alpha80,
      },
    },
    display: 'table-row',
  }),
  rowStickyHeader: css({
    '& > *': {
      borderBottom: `1px solid ${colors.cloudyBlueAlpha50}`,
    },
    '&:nth-child(even)': {
      backgroundColor: colors.paleGray2Alpha80,
    },
    display: 'table-row',
  }),
  sortIcon: css({
    fill: colors.charcoalGray,
    height: 15,
    marginLeft: 10,
    width: 15,
  }),
  tableContainerStickyHeader: css({
    overflow: 'hidden',
    position: 'relative',
  }),
  tableStickyHeader: css({
    height: '100%',
    position: 'absolute',
    width: '100%',
  }),
}

const sortDirection = <T,>(sort: StaticTableProps<T>['sort'], columnKey: T) => {
  if (!sort || sort.by !== columnKey) {
    return undefined
  }
  return sort.direction
}

const orderDirectionToIcon = (sortDir?: OrderDirection) => {
  switch (sortDir) {
    case OrderDirection.Descending:
      return 'down'
    case OrderDirection.Ascending:
      return 'up'
    default:
      return undefined
  }
}

const Header = <T,>({
  classNameCell,
  column: { label, key, sortable = false },
  handleHeaderClick = noop,
  sort,
}: TableHeaderProps<T>) => {
  const sortDir = sortDirection(sort, key)

  return (
    <Cell className={css(styles.cell, styles.headerCells, classNameCell)}>
      <div
        className={css(styles.headerTextAndIconContainer(sortable))}
        onClick={sortable && key ? () => handleHeaderClick(key) : undefined}
      >
        {label}
        {sortable && (
          <UpDownArrow
            className={styles.sortIcon}
            activeFill={colors.appleGreen}
            title={sortDir ? `Sorted ${sortDir.toLowerCase()}` : 'Sortable column'}
            direction={orderDirectionToIcon(sortDir)}
          />
        )}
      </div>
    </Cell>
  )
}

const makeFetchingCellsByRow = (numRows: number, numColumns: number, classNameCell?: string) =>
  Array(numRows)
    .fill(0)
    .map((_, rowIndex) =>
      Array(numColumns)
        .fill(0)
        .map((__, columnIndex) => (
          <Cell className={css(styles.cell, classNameCell)} key={`${rowIndex}_${columnIndex}`}>
            <TableFetchingLoader />
          </Cell>
        ))
    )

const addCellWrapper = <T,>(
  cellsByRow: StaticTableProps<T>['cellsByRow'],
  classNameCell?: string
) => {
  if (!cellsByRow || cellsByRow.length === 0) {
    return [[]]
  }
  return cellsByRow!.map((rowCells, rowIndex) =>
    rowCells.map((cell, columnIndex) => (
      <Cell
        className={css(styles.cell, classNameCell)}
        data-testid="sticky-table-cell"
        key={`${rowIndex}${columnIndex}`}
        title={is(String, cell) ? cell : undefined}
      >
        {cell}
      </Cell>
    ))
  )
}

const StaticTable = <T,>({
  className,
  classNameCell,
  cellsByRow,
  columns,
  handleHeaderClick,
  fetching,
  sort,
  stickyHeader = false,
}: StaticTableProps<T>) => (
  <div className={css(stickyHeader && styles.tableContainerStickyHeader, className)}>
    <BaseTable
      className={css(stickyHeader && styles.tableStickyHeader)}
      classNameRow={stickyHeader ? styles.rowStickyHeader : styles.row}
      headerCells={columns.map((column) => (
        <Header key={column.label} {...{ column, handleHeaderClick, sort }} />
      ))}
      bodyCellsByRow={
        fetching
          ? makeFetchingCellsByRow(fetching, columns.length, classNameCell)
          : addCellWrapper(cellsByRow, classNameCell)
      }
      stickyHeader={stickyHeader}
    />
  </div>
)

export default StaticTable
