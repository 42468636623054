import { select, take } from 'redux-saga/effects'

import actions from '../../session/actions'
import { hasSession } from '../../session/selectors'

function* waitForSession() {
  if (!(yield select(hasSession))) {
    yield take(actions.set().type)
  }
}

export default waitForSession
