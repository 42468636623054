import * as CSV from 'comma-separated-values'
import { css } from 'emotion'
import { isEmpty } from 'ramda'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import FadeIn from '../../../components/FadeIn'
import FileInput from '../../../components/FileInput'
import { InputHandle } from '../../../components/Input'
import { ModalWithBackground, ModalWithTrigger } from '../../../components/Modal'
import TextInput from '../../../components/TextInput'
import { buttonStyles, textStyles } from '../../../styles'
import parseAppno from '../../../utils/parseAppno'
import { NotificationTypes, useNotification } from '../../notification'
import { useSearchVariables, useSetReport } from '../../search/hooks'
import { useBulkAlerts } from '../hooks'

type ImportAlertsModalProps = {
  trigger: (trigger: () => void) => JSX.Element
}

const styles = {
  error: css(textStyles.torchRedNormal13, {
    marginTop: 20,
  }),
  filePicker: css(buttonStyles.blueOutlineWithIcon, {
    cursor: 'pointer',
    justifyContent: 'center',
  }),
  label: css({
    marginBottom: 4,
  }),
}

const ImportAlertsModal = ({ trigger }: ImportAlertsModalProps) => {
  const [text, setText] = useState('')
  const { addErrorNotification, addNotification } = useNotification()
  const { subscribe } = useBulkAlerts([])
  const { filters } = useSearchVariables()
  const setReport = useSetReport()

  const appnos = text
    .trim()
    .split(/,?\s+/g)
    .filter((appno) => Boolean(appno.trim()))
    .map((appno) => Number(parseAppno(appno)))

  const disabled = isEmpty(appnos) || !appnos.every(Boolean) || appnos.length > 1000

  const handleFileChange = useCallback(({ 0: file }: FileList) => {
    if (file.type === 'text/csv' || file.name.endsWith('.csv')) {
      const reader = new FileReader()

      reader.onload = () => {
        if (typeof reader.result !== 'string') {
          throw new Error('Reader result was not a string')
        }

        const values = CSV.parse(reader.result)
          .map(([value]) => parseAppno(String(value)))
          .filter(Boolean)

        if (isEmpty(values)) {
          addNotification('Imported file contains no valid apps', {
            type: NotificationTypes.Warning,
          })
        } else {
          setText(values.join(', '))
        }
      }

      reader.onerror = () => {
        addErrorNotification('Error reading file')
      }

      reader.readAsText(file)
    } else {
      addErrorNotification('Please select a valid CSV file')
    }
  }, [])

  const textInputRef = useRef<InputHandle>(null)

  useEffect(() => {
    textInputRef.current?.focus()
  }, [])

  return (
    <ModalWithTrigger
      modal={(closeModal) => (
        <ModalWithBackground
          closeModal={closeModal}
          onClose={() => setText('')}
          primaryButtonProps={{
            accent: 'blue',
            action: () => {
              subscribe({ appnos })
              setReport({
                filters: {
                  ...filters,
                  appno: [...(filters.appno ?? []), ...appnos.map((appno) => String(appno))],
                },
              })
              closeModal()
            },
            disabled,
            text: 'Add apps to alerts',
          }}
          secondaryButtonProps={{ text: 'Cancel', action: () => closeModal() }}
          title="Import Alerts"
        >
          <label className={styles.label} htmlFor="appnos-input">
            Paste or enter a list of apps:
          </label>
          <TextInput
            accent="blue"
            id="appnos-input"
            handleOnTextChange={setText}
            placeholder="Enter application numbers..."
            ref={textInputRef}
            text={text}
          />
          <br />
          <label className={styles.label}>Upload a CSV of apps:</label>
          <FileInput containerClassName={styles.filePicker} handleFileChange={handleFileChange}>
            Upload file
          </FileInput>
          <FadeIn when={appnos.length > 1000}>
            <div className={styles.error}>
              Alert imports are limited to 1,000 applications. Please reduce the number of
              applications in your list.
            </div>
          </FadeIn>
        </ModalWithBackground>
      )}
      trigger={trigger}
    />
  )
}

export default ImportAlertsModal
