import { call, fork, put, race, select, take, takeLatest } from 'redux-saga/effects'

import moveToPosition from '../../../../../utils/moveToPosition'
import {
  NotificationTypes,
  makeNotification,
  actions as notificationActions,
} from '../../../../notification'
import sessionActions from '../../../../session/actions'
import { SettingTypes } from '../../../../session/types'
import actions from '../actions'
import { getSelectedColumnNames } from '../selectors'
import { MoveColumnAction, SetAllSelectedColumnsAction } from '../types'

function* saveColumns(oldColumns: string[], newColumns: string[]) {
  yield put(actions.setAllSelectedColumns(newColumns))

  yield put(
    sessionActions.saveSetting({
      dataKey: 'default',
      type: SettingTypes.ColumnLayout,
      value: newColumns,
    })
  )

  const { error } = yield race({
    error: take(sessionActions.saveSettingError().type),
    success: take(sessionActions.saveSetting().type),
  })

  if (error) {
    yield put(actions.setAllSelectedColumns(oldColumns))
    yield put(
      notificationActions.push(
        makeNotification({
          message: 'Error saving column state, please try again.',
          type: NotificationTypes.Error,
        })
      )
    )
  }
}

function* moveColumn(action: MoveColumnAction) {
  const currentColumnState = yield select(getSelectedColumnNames)
  const { column, position } = action.payload!
  const newState = moveToPosition(column, position, currentColumnState)

  yield call(saveColumns, currentColumnState, newState)
}

function* setColumns(action: SetAllSelectedColumnsAction) {
  const currentColumnState = yield select(getSelectedColumnNames)
  yield saveColumns(currentColumnState, action.payload!)
}

function* watchSaveColumns() {
  yield [
    fork(function* () {
      yield takeLatest(actions.moveColumn().type, moveColumn)
    }),
    fork(function* () {
      yield takeLatest(actions.moveColumns().type, setColumns)
    }),
  ]
}

export { moveColumn, saveColumns }
export default watchSaveColumns
