import { css } from 'emotion'
import * as React from 'react'

import { before, colors, textStyles } from '../styles'

type ToggleProps<T> = {
  accent?: 'green' | 'blue'
  className?: string
  count?: number
  handleToggle: (on: boolean, value: T) => void
  label: string
  on?: boolean
  title?: string
  value: T
}

const styles = {
  count: css(textStyles.paleGray2Normal14, {
    margin: 0,
    padding: 0,
  }),
  inner: css({
    alignItems: 'center',
    display: 'inline-flex',
  }),
  input: (accent: 'green' | 'blue') =>
    css({
      ['&:checked + span']: {
        '&:before': {
          transform: 'translateX(20px)',
        },
        backgroundImage:
          accent === 'green'
            ? `linear-gradient(to left, ${colors.appleGreen}, ${colors.greenyBlue2})`
            : undefined,
        background: accent === 'blue' ? colors.azure : undefined,
      },
    }),
  label: css(textStyles.paleGray2Semibold14),
  main: css({
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 28,
  }),
  slider: css(
    {
      backgroundColor: colors.dark3,
      borderRadius: 34,
      bottom: 0,
      cursor: 'pointer',
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      transition: '.4s',
    },
    before({
      backgroundColor: colors.white,
      borderRadius: 7,
      bottom: 3,
      content: '""',
      height: 14,
      left: 3,
      position: 'absolute',
      transition: '.4s',
      width: 14,
      willChange: 'transform',
    })
  ),
  toggle: (on: boolean) =>
    css({
      '& input': {
        display: 'none',
      },
      backgroundColor: colors.dark3,
      border: `1px solid ${on ? 'transparent' : colors.cloudyBlueAlpha50}`,
      borderRadius: 11,
      display: 'inline-block',
      height: 22,
      position: 'relative',
      width: 42,
    }),
}

const Toggle = <T,>({
  accent = 'green',
  className,
  count,
  handleToggle,
  label,
  on = false,
  title = label,
  value,
}: ToggleProps<T>) => (
  <div className={css(styles.main, className)} onClick={() => handleToggle(!on, value)}>
    <div>
      <h1 className={styles.label} title={label}>
        {label}
      </h1>
      {typeof count === 'number' && <div className={styles.count}>{count.toLocaleString()}</div>}
    </div>
    <div className={styles.inner} title={title}>
      <label className={styles.toggle(on)}>
        <input
          checked={on}
          className={styles.input(accent)}
          type="checkbox"
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
          }}
          readOnly
        />
        <span className={styles.slider} />
      </label>
    </div>
  </div>
)

export default Toggle
