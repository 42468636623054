import { css } from 'emotion'
import * as React from 'react'

import { IconProps } from '../../modules/icons'
import { colors, textStyles } from '../../styles'
import Checkbox from '../Checkbox'

type ItemSelectorProps = {
  description?: string
  icons?: Array<(props: IconProps & { key?: string }) => JSX.Element>
  key?: string
  label: string
  renderLabel?: (text: string) => JSX.Element
  selected: boolean
  handleClick: () => void
}

const styles = {
  checkboxLabelContainer: css({
    alignItems: 'center',
    display: 'flex',
  }),
  container: css({
    '& p': {
      margin: 0,
    },
    cursor: 'pointer',
  }),
  description: css(textStyles.charcoalGrayNormal10, {
    letterSpacing: 0.3,
    opacity: 0.6,
    paddingLeft: 30,
  }),
  iconContainer: css({
    alignItems: 'center',
    display: 'flex',
    fill: colors.charcoalGray,
    flexShrink: 0,
    marginLeft: 4,
  }),
  titleContainer: css({
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    marginBottom: 4,
  }),
  titleLabel: css(textStyles.charcoalGraySemibold12),
}

class ItemsSelector extends React.Component<ItemSelectorProps> {
  shouldComponentUpdate(nextProps: ItemSelectorProps) {
    return nextProps.selected !== this.props.selected
  }

  render() {
    const {
      description,
      handleClick,
      icons = [],
      selected,
      label,
      renderLabel = (text: string) => <div className={styles.titleLabel}>{text}</div>,
    } = this.props

    return (
      <div
        className={styles.container}
        onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()
          handleClick()
        }}
      >
        <div className={styles.titleContainer}>
          <Checkbox checked={selected} label={label} renderLabel={renderLabel} />
          <div className={styles.iconContainer}>
            {icons.map((Icon, index) => (
              <Icon key={String(index)} width={12} height={12} />
            ))}
          </div>
        </div>
        {description && <p className={styles.description}>{description}</p>}
      </div>
    )
  }
}

export default ItemsSelector
