import { SearchScope } from '@juristat/common/types'
import { select } from 'redux-saga/effects'

import { FilterMeta, FilterReportType } from '../../filter/types'
import { getPaginationVariables } from '../../pagination/selectors'
import { getColumnVariables } from '../modules/table/selectors'
import {
  getSearchOrderings,
  getSearchPhraseParsed,
  getSearchScopes,
  getSearchType,
} from '../selectors'
import getActiveFilters from '../selectors/getActiveFilters'
import { SearchType, SearchVariables, SortOrderVariables } from '../types'
import getSortOrders from '../utils/getSortOrders'

function* getSearchVariables(
  searchId: string,
  options: Partial<SearchVariables> = {},
  filterMeta: FilterMeta = { report: FilterReportType.Search }
) {
  const { pageNum, pageSize } = yield select(getPaginationVariables, { searchId })
  const columns = yield select(getColumnVariables)

  const filters = yield select(getActiveFilters, filterMeta)
  const phrase: string = yield select(getSearchPhraseParsed, { searchId })
  const scopes: SearchScope[] = (yield select(getSearchScopes, { searchId })) || [
    SearchScope.FullText,
  ]
  const sortOrders: SortOrderVariables[] = getSortOrders(
    phrase,
    yield select(getSearchOrderings, { searchId })
  )
  const type: SearchType = (yield select(getSearchType, { searchId })) || SearchType.Keyword
  const scopesAndPhrase = scopes.reduce((acc, scope) => ({ ...acc, [scope]: phrase }), {})

  const searches = type === SearchType.Keyword ? scopesAndPhrase : {}
  const similarTo = type === SearchType.SimilarTo ? scopesAndPhrase : {}

  return {
    columns,
    filters,
    pageNum,
    pageSize,
    searches,
    similarTo,
    sortOrders,
    withFilters: false,
    ...options,
  }
}

export default getSearchVariables
